import * as actionTypes from './action-types';
import PrismsServer from '../prisms-server';
import {
    extractGeoConstraints,
    extractTagConstraints,
    isAllData,
} from '../functions';

export function checkTagSupported(docId) {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.FETCHING_TAG_SUPPORTED,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            let dataTags = await prismsInstance.getEnumInstances('pdc#TagEnum', docId);
            dispatch({
                type: actionTypes.SUCCESS_GET_TAG_SUPPORTED,
                dataTags,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_TAG_SUPPORTED,
            });
        }
    }
}

// get the data
export function getCDMData() {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.FETCHING_JDS_DATA,
        });

        try {
            const {
                user,
            } = getState().prisms;
            const prismsInstance = await PrismsServer.getInstance();
            let graph = await prismsInstance.getCDMGraph(user.docId, {
                body: [],
            });
            let classTree = await prismsInstance.getCDMTree({
                rootName: '',
                filteredNamespaces: [],
            }, user.docId);
            let predicateValueMap = await prismsInstance.getPredicateValueMap();

            dispatch({
                type: actionTypes.SUCCESS_GET_JDS_DATA,
                nodes: graph.nodes,
                edges: graph.links,
                classTree,
                constraintsMap: predicateValueMap,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_JDS_DATA,
            });
        }
    }
}

export function getJDSList() {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.FETCHING_JDS_DATA,
        });

        try {
            // TODO: fetch from the server
            let list = [];

            dispatch({
                type: actionTypes.SUCCESS_GET_JDS_DATA,
                list,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_JDS_DATA,
            });
        }
    }
}

export function setExploringCDM(flag, customButtons) {
    return {
        type: actionTypes.EXPLORING_CDM,
        flag,
        customButtons,
    }
}

export function updateSensitivityLevel(value) {
    return {
        type: actionTypes.UPDATED_SENSITIVITY_LEVEL,
        value,
    };
}

export function initJDSdata(policy, dataTags) {
    let filterConstraints = policy.filterConstraints;
    let allData = isAllData(policy);
    let tagSelection = extractTagConstraints(filterConstraints, dataTags);
    let geoSelection = extractGeoConstraints(filterConstraints);
    return {
        type: actionTypes.INIT_JDS_DATA,
        policyData: policy.policyData,
        filterConstraints: filterConstraints,
        actionConstraints: policy.actionConstraints,
        allData,
        tagSelection,
        geoSelection,
    }
}

export function updateJDSdata(data) {
    let allData = isAllData(data);
    return {
        type: actionTypes.UPDATED_JDS_DATA,
        policyData: data.policyData,
        filterConstraints: data.filterConstraints,
        actionConstraints: data.actionConstraints,
        allData,
    }
}

export function updateTagSelection(newTagSelection) {
    return {
        type: actionTypes.UPDATE_TAG_SELECTION,
        tagSelection: newTagSelection,
    }
}

export function updateGeoSelection(newGeoSelection) {
    return {
        type: actionTypes.UPDATE_GEO_SELECTION,
        geoSelection: newGeoSelection,
    }
}
