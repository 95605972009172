import * as actionTypes from './action-types';
import PrismsServer from '../prisms-server';
import PrismsClient from '@brandeis/prisms-client-js';
import {
    stateGo,
} from 'redux-ui-router';
import {
    getPolicies,
} from './policies';
import {
    createId,
    isAllData,
} from '../functions';

function preparePolicyForServer(policyFromUI, docId) {
    const policy = new PrismsClient.Policy();

    if (policyFromUI.hasOwnProperty('id'))
        policy.id = policyFromUI.id;
    else
        policy.id = createId(policyFromUI.displayId);
    policy.requesters = policyFromUI.requesters;
    if (policyFromUI.duration) {
        policy.startTimeString = policyFromUI.duration.start ? policyFromUI.duration.start.toISOString() : null;
        policy.endTimeString = policyFromUI.duration.end ? policyFromUI.duration.end.toISOString() : null;
    } else {
        policy.startTimeString = null;
        policy.endTimeString = null;
    }
    policy.decision = policyFromUI.decision;
    policy.description = policyFromUI.description;
    policy.priority = policyFromUI.priority;
    policy.operations = ['READ'];
    policy.enabled = policyFromUI.hasOwnProperty('enabled') ? policyFromUI.enabled : true;
    policy.docId = docId;
    policy.policyAuthority = policyFromUI.policyAuthority;

    policy.policyData = policyFromUI.policyData;
    policy.filterConstraints = policyFromUI.filterConstraints;
    policy.actionConstraints = policyFromUI.actionConstraints;

    return policy;
}

export function addPolicy(policyToAdd) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.ADDING_POLICY,
            policyToAdd,
        });

        try {
            const {
                user,
            } = getState().prisms;

            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.addPolicy({
                body: preparePolicyForServer(policyToAdd, user.docId),
            });

            dispatch({
                type: actionTypes.SUCCESS_ADD_POLICY,
                policyToAdd,
            });

            dispatch(stateGo('pa.policy-manager'));
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_ADD_POLICY,
                errorMessage: error,
            });
        }
    };
}

export function deletePolicy(policy) {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.DELETING_POLICY,
            policy,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.deletePolicy(policy.id);

            dispatch({
                type: actionTypes.SUCCESS_DELETE_POLICY,
                policy,
            });

            dispatch(getPolicies());
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_DELETE_POLICY,
                errorMessage: error,
            });
        }
    }
}

export function editPolicy(policyId, tabSetId) {
    return async ({wizardManager}, dispatch, getState) => {
        let policies = getState().prisms.policies.policies,
            policyForm = policies.find(p => p.id === policyId);

        dispatch({
            type: actionTypes.RESETTING_POLICY,
        });

        wizardManager.setAllTabsVisited(tabSetId);

        dispatch({
            type: actionTypes.EDIT_POLICY,
            policyForm,
            policyId: policyForm.id,
        });
    };
}

export function newPolicy(tabSetId) {
    return async ({wizardManager}, dispatch, getState) => {
        let {
            policy,
        } = getState().prisms;

        // there's a current policy but it's no longer new because it was successfully
        // saved
        if (policy.isNew !== null && !policy.isNew) {
            wizardManager.resetTabsVisited(tabSetId);
        }

        dispatch({
            type: actionTypes.NEW_POLICY,
            policyForm: policy.isNew ? policy : null,
        });
    };
}

export function setupEditorWizard(policyId) {
    return {
        type: actionTypes.SETUP_WIZARD,
        policyId,
    };
}

export function setReviewButtons(customButtons) {
    return {
        type: actionTypes.SET_REVIEW_BUTTONS,
        customButtons,
    }
}

export function updatePolicy(policyToUpdate) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.UPDATING_POLICY,
            policyToUpdate,
        });

        try {
            const {
                user,
            } = getState().prisms;

            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.updatePolicy({
                body: preparePolicyForServer(policyToUpdate, user.docId),
            });

            dispatch({
                type: actionTypes.SUCCESS_UPDATE_POLICY,
                policyToUpdate,
            });

            dispatch(stateGo('pa.policy-manager'));
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_UPDATE_POLICY,
                errorMessage: error,
            });
        }
    };
}

export function updatePolicyLocal(tabId, policyForm, isValid) {
    let allData = isAllData(policyForm);
    return {
        type: actionTypes.LOCAL_UPDATE_POLICY,
        policyForm,
        tabId,
        isValid,
        allData,
    };
}
