import * as actionTypes from '../actions/action-types';


export function markers(state = [], action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_COP:
            for (let ship of state) {
                ship.setMap(null);
            }
            return action.ships;
        case actionTypes.RESET_TEST_RESULTS:
            for (let ship of state) {
                ship.setMap(null);
            }
            return [];
        default:
            return state;
    }
}

export function policyMapStatus(state = '', action) {
    switch (action.type) {
        case actionTypes.FAILED_COP:
            return 'error';
        case actionTypes.FETCHING_COP:
            return 'fetching';
        case actionTypes.SUCCESS_GET_COP:
            return 'success'
        default:
            return state;
    }
}
