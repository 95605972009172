import templateUrl from './precedence-picker.html';

class PrecedencePicker {
    constructor($scope, $element) {
        this.$onInit = () => {
            this.$element = $element;
            this.precedenceList = [];
            this.dot = {
                radius: this.dotRadius || 5,
            };

            for (let i = 1; i <= this.max; i++) {
                this.precedenceList.push({
                    id: i,
                    value: i <= this.precedence,
                });
            }

            $scope.$watch(() => this.precedence, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this.precedenceList = [];

                    for (let i = 1; i <= this.max; i++) {
                        this.precedenceList.push({
                            id: i,
                            value: i <= this.precedence,
                        });
                    }
                }
            });

            // debounced func
            /* globals _ */
            this.debounced = _.debounce((id) => {
                this.onChangePrecedence({ precedence: id });
            }, 1000);
        };
    }

    precedenceClicked(id) {
        if (this.isSettable) {
            this.precedence = id;
            this.debounced(id);
        }
    }

    precedenceHovered(id) {
        if (this.isSettable) {
            this.$element.find('.dot circle').removeClass('over');
            for (let i = 1; i <= id; i++) {
                this.$element.find('.dot.' + i + ' circle').addClass('over');
            }
        }
    }

    precedenceLeave() {
        if (this.isSettable) {
            this.$element.find('.dot circle').removeClass('over');
        }
    }
}

PrecedencePicker.$inject = ['$scope', '$element'];

const component = {
    templateUrl,
    controller: PrecedencePicker,
    bindings: {
        dotRadius: '<',
        isSettable: '<',
        max: '<',
        onChangePrecedence: '&',
        precedence: '=',
    },
};

export default component;
