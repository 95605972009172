// actions
import {
    getAccessMatrix,
} from '../../actions/policies';

// template
import templateUrl from './access-matrix.html';
import {PDC_POLICY_DATA} from '../../constants';

class AccessMatrixView {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                getAccessMatrix,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            this.getAccessMatrix(PDC_POLICY_DATA);
        }
    }

    mapStateToThis(state) {
        const {
            accessMatrix,
        }  = state.prisms;
        
        return {
            accessMatrix,
        };
    }
}

AccessMatrixView.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: AccessMatrixView,
};

export default component;
