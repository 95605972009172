import templateUrl from './node-property.html';
import { cleanupName } from '../../../containers/cdm/functions';

class NodeProperty {
	constructor() {
		this.propClass = {
			'node-property': true,
			'checkbox': true,
		};

		this.$onChanges = changesObj => {
			let isEditing = changesObj.isEditing;

			if (isEditing) {
				this.propClass.checkbox = isEditing.currentValue;
			}
		}
	}

	cleanup(name) {
		return cleanupName(name);
	}
}

const component = {
    bindings: {
		isEditing: '<',
        prop: '<',
    },
    controller: NodeProperty,
    templateUrl,
};

export default component;
