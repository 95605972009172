import templateUrl from './dr-filters.html';

// helper functions
import { constructTagString } from '../../../functions';

class DRFiltersComponent {
    constructor($scope) {
        this.$scope = $scope;

        this.editingIndex = 0;
        this.currentFilters = [];
        this.highlight = -1;
        this.isLocalChange = false;
        this.popups = {};

        this.$onChanges = changesObj => {
            let newValue;

            if (changesObj.filters) {
                newValue = this.currentFilters = changesObj.filters.currentValue;
                // console.log('WATCH filters: ', this.isLocalChange, this.editingIndex, newValue);
                if (newValue) {
                    this.popups = {};
                    if (newValue.length === 0 || !this.isLocalChange) {
                        this.editFilterAt(0);
                    }
                    for (let ndx = 0; ndx < newValue.length; ndx++) {
                        this.popups[ndx] = {
                            visible: false,
                        };
                    }
                } else {
                    this.currentFilters = [];
                    this.editingIndex = 0;
                }
                if (this.isLocalChange)
                    this.isLocalChange = false;
            } else if (changesObj.pills) {
                let needUpdate = false;
                newValue = changesObj.pills.currentValue;
                // console.log('WATCH pills: ', this.isLocalChange, this.editingIndex, newValue);
                if (this.isLocalChange) {
                    this.isLocalChange = false;  // reset for next time
                } else if (this.editingIndex >= 0) {
                    if (this.editingIndex < this.currentFilters.length) {
                        if (newValue.length === 0) {
                            // we are effectively removing a current filter:
                            this.removeFilterAt(this.editingIndex);
                            // note: no update needed as the above call will handle that
                        } else {
                            // we are updating a current filter:
                            this.currentFilters[this.editingIndex].pills = newValue;
                            needUpdate = true;
                        }
                    } else if (newValue.length > 0) {
                        // we are adding a new filter:
                        this.editingIndex = this.currentFilters.length;
                        this.currentFilters.push({
                            pills: newValue,
                            popup_visible: false,
                        });
                        this.popups[this.editingIndex] = { visible: false };
                        needUpdate = true;
                    }
                } else {
                    this.currentFilters = [];
                    this.editingIndex = 0;
                }
                if (needUpdate) {
                    this.isLocalChange = true;  // make watcher skip switching into editing mode:
                    this.onRemoteUpdate({filters: this.currentFilters, editingIndex: this.editingIndex});
                }
            }
        }
    }

    displayPill(tag) {
        return constructTagString(tag, ' | ');
    }

    getPillLabelClass(pill) {
        return {
            'pill-label': !pill.negated,
            'pill-label-not': pill.negated,
        };
    }

    shouldDisplayCreate() {
        // when editing a row with at least one pill:
        return this.editingIndex >= 0 && this.editingIndex < this.currentFilters.length &&
            this.currentFilters[this.editingIndex].pills.length > 0
    }

    // -- functions called when user edits:

    createFilter() {
        this.editFilterAt(this.currentFilters.length);
    }

    removeFilterAt(index) {
        // calculate the new index used for editing
        this.currentPopup.visible = false;
        this.editingIndex = index < this.currentFilters.length - 1 ? index : ( index > 0 ? index - 1 : 0);
        this.currentFilters.splice(index, 1);
        this.isLocalChange = true;  // make watcher skip switching into editing mode (unless the list becomes empty):
        this.onRemoteUpdate({filters: this.currentFilters, editingIndex: this.editingIndex});
    }

    removePillIn(pill, filter) {
        this.editingIndex = this.currentFilters.indexOf(filter);
        this.currentFilters[this.editingIndex].pills.splice(this.currentFilters[this.editingIndex].pills.indexOf(pill), 1);
        if (this.currentFilters[this.editingIndex].pills.length === 0) {
            // we have removed the last pill, so remove whole filter:
            this.removeFilterAt(this.editingIndex);  // will call remote update!
        } else {
            // make watcher skip any updates, except in 2 cases:
            //   (1) if we are removing the (last/only) positive pill of a category, for which at least one negative
            //   pill exists as the handling logic will add an All pill, which we don't want to miss
            //   (2) if we are removing the last negative pill of a category, for which no positive pill exists, as
            //   that will also result in removing the All pill, which we don't want to miss
            this.isLocalChange =
                // NOT (1) ~ if pill positive then no negative pills of the same type exists
                (pill.negated ||
                    this.currentFilters[this.editingIndex].pills
                        .findIndex(p => p.negated && p.type === pill.type) === -1)
                &&  // AND
                // NOT (2) ~ if pill negative, then at least one more negative or positive with the same type exists,
                //           that is not the All pill (checkList.length === 0)
                (!pill.negated ||
                    this.currentFilters[this.editingIndex].pills
                        .findIndex(p => p.type === pill.type && p.checkList.length > 0) >= 0);
            this.onRemoteUpdate({filters: this.currentFilters, editingIndex: this.editingIndex});
        }
    }

    editFilterAt(index) {
        this.editingIndex = index;
        this.onEditAt({filters: this.currentFilters, editingIndex: this.editingIndex});
    }

    showDeletePopup(filterIndex) {
        if (this.currentPopup) {
            this.currentPopup.visible = false;
        }
        this.currentPopup = this.popups[filterIndex];
        this.currentPopup.visible = true;

        /* global jQuery */
        jQuery(document).on('click.delete-popup', event => {
            let target = jQuery(event.target);

            if (target.parents('.delete-popup').length === 0 &&
                target.parents('.filter-actions').length === 0) {
                this.currentPopup.visible = false;
                jQuery(document).off('click.delete-popup');
                this.$scope.$apply();
            }
        });
    }
}

DRFiltersComponent.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: DRFiltersComponent,
    bindings: {
        pills: '<',
        filters: '<',
        highlight: '<',
        onRowCreated: '&',
        onEditAt: '&',
        onRemoteUpdate: '&',
    },
};

export default component;
