import templateUrl from './time-window.html';

const component = {
    templateUrl,
    controller: ['$scope', '$filter', function($scope, $filter) {
        $scope.$watch(() => this.times, (newValue) => {
            let dateFilter = $filter('date');

            const isExpired = newValue.isOngoing ? false : newValue.endTime.getTime() < this.systemTime.getTime();
            this.type = isExpired ? 'expired' : newValue.isOngoing ? 'ongoing' : 'default';
            this.startStr = newValue.startTime ? dateFilter(newValue.startTime, 'd MMM yyyy H:mm', 'UTC') + ' - ' : '';
            this.endStr = newValue.endTime ? dateFilter(newValue.endTime, 'd MMM yyyy H:mm', 'UTC') : '';
        })
    }],
    bindings: {
        times: '<',
        systemTime: '<',
    },
};

export default component;
