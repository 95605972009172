import * as actionTypes from '../actions/action-types';
import {calcNameOrAcronym} from '../functions';
import {ALL_DATA_REQUESTERS_ATTRIBUTE, POLICY_NONE} from '../constants';

export function drFilters(state = [], action) {
    switch (action.type) {
        case actionTypes.ADDED_DR_FILTER:
            let filters = [ ...state ];
            filters.push({
                ...action.filter,
            });
            return filters;
        case actionTypes.UPDATED_DR_FILTERS:
        case actionTypes.INIT_DR_FILTERS:
            return action.filters;
        // case actionTypes.NEW_POLICY:  // TODO: (Linda) I don't understand this clause... how would we get here without action.policyForm?
        //     if (action.policyForm) {
        //         return state;
        //     } else
        //         return [];
        case actionTypes.RESETTING_POLICY:
        case actionTypes.SUCCESS_ADD_POLICY:
            return [];
        default:
            return state;
    }
}

export function requesterAttributeInfos(state = { status: 'empty' }, action) {
    switch (action.type) {
        case actionTypes.FAILED_GET_REQUESTER_ATTRIBUTES:
            return {
                status: 'failed',
            };
        case actionTypes.FETCHING_REQUESTER_ATTRIBUTES:
            let newStatus = state.status === 'empty' ? 'fetching' : 'updating';
            return {
                ...state,
                status: newStatus,
            };
        case actionTypes.SUCCESS_GET_REQUESTER_ATTRIBUTES:
            for (let info of action.requesterAttributeInfos) {
                let newNamedArgs = {};
                Object.entries(info.namedArgs)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([ignored, value]) => {
                        // filter any attribute that matches ALL_DATA_REQUESTERS_ATTRIBUTE (type & argument)
                        return !(info.attribute === ALL_DATA_REQUESTERS_ATTRIBUTE.type &&
                            value === ALL_DATA_REQUESTERS_ATTRIBUTE.argument);
                    })
                    // eslint-disable-next-line no-unused-vars
                    .filter(([ignored, value]) => {
                        // filter pdc#ANY values
                        return value !== 'pdc#ANY';
                    })
                    .map(([key, value]) => {
                        let name = key;

                        // if key contains "#" then strip namespace and de-camel-case the rest
                        if (key.indexOf('#') >= 0) {
                            name = key.split('#')[1];
                            name = name.replace(/([a-z])([A-Z])/g, '$1 $2');
                        }

                        // parse key and if it matches /.* (.*)/ then use \1 as name and \2 as acronym,
                        // if name > 20 then displayName should be acronym otherwise name.
                        let nameAndAcronymRE = new RegExp('^(.*)\\s+\\((.*)\\)$');
                        let match = nameAndAcronymRE.exec(key);
                        if (match) {
                            name = calcNameOrAcronym(match[1], match[2]);
                        }
                        newNamedArgs[name] = value;
                    });
                info.namedArgs = newNamedArgs;
            }
            // filter empty categories, i.e., they only contain policy#NONE or nothing as a namedArg:
            let infos = action.requesterAttributeInfos
                .filter(info => {
                    return Object.entries(info.namedArgs).length > 1 ||
                        (Object.entries(info.namedArgs).length === 1 && Object.entries(info.namedArgs)[0][1] !== POLICY_NONE)
                });
            return {
                status: 'success',
                requesterAttributeInfos: infos,
            };
        default:
            return state;
    }
}

export function hasIndividuals(state = false, action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_INDIVIDUALS:
            if (action.individuals)
                return action.individuals.length > 0;
            return false;
        default:
            return state;
    }
}

export function previewRequesters(state = { status: 'empty' }, action) {
    switch (action.type) {
        case actionTypes.FAILED_GET_PREVIEW_REQUESTERS:
            return {
                status: 'failed',
            };
        case actionTypes.FETCHING_PREVIEW_REQUESTERS:
            let newStatus = state.status === 'empty' ? 'fetching' : 'updating';
            return {
                ...state,
                status: newStatus,
            };
        case actionTypes.SUCCESS_GET_PREVIEW_REQUESTERS:
            // go through list of pairs<list of attributes,list of indices>
            // and build up matching header/row data
            // (as the items in each inner-attribute list may not be sorted by header)
            // also keep the set of indices in the second value of pair!
            let headers = [];
            let rows = [];
            if (action.requesters && action.requesters.length > 0) {
                headers = action.requesters[0]['value0']
                    .map(e => e.argumentType)
                    .sort();
                // now sort the rows and map to displayName:
                rows = action.requesters
                    .map(pair => {
                        return {
                            row: pair['value0']
                                .sort((a, b) => a.argumentType.localeCompare(b.argumentType))
                                .map(e => {
                                    return {
                                        ...e,
                                        displayName: e.argument,
                                    }
                                }),
                            indices: new Set(pair['value1']),
                        }
                    });
            }
            return {
                status: 'success',
                requesters: {
                    headers,
                    rows,
                    isOnlyIndividuals: headers.length === 1 && headers[0] === 'Individual',
                    isAll: action.isAllDRs,
                },
            };
        default:
            return state;
    } 
}
