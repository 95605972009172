// libs
import angular from 'angular';

// components
import groupDetailsForm from '../../components/group-editor/basic-details';
import groupMembersForm from '../../components/group-editor/member-selection';

// container
import groupBasicDetails from './basic-details';
import groupMemberSelection from './member-selection';

export default angular.module('brandeis.ui.prisms.containers.groupEditor.basicInfo', [])
    .config(['$stateProvider', ($stateProvider) => {
        let basicInfoState = {
                name: 'pa.group-editor.basic-info',
                url: '/{id}/basic-info/{focusSection}',
                views: {
                    groupEditorMain: 'groupBasicDetails',
                },
            },
            memberSelectionState = {
                name: 'pa.group-editor.member-selection',
                url: '/{id}/member-selection/{focusSection}',
                views: {
                    groupEditorMain: 'groupMemberSelection',
                },
            };

        $stateProvider
            .state(basicInfoState)
            .state(memberSelectionState);
    }])
    .component('groupBasicDetails', groupBasicDetails)
    .component('groupDetailsForm', groupDetailsForm)
    .component('groupMembersForm', groupMembersForm)
    .component('groupMemberSelection', groupMemberSelection)
    .name;