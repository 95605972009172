import templateUrl from './loop-form.html';

class LoopForm {
    constructor() {
    }

    cancel() {
        this.dismiss({ $value: false });
    }

    goAhead() {
        this.close({ $value: true });
    }
}

const component = {
    templateUrl,
    controller: LoopForm,
    bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<',
    },
};

export default component;
