import templateUrl from './tree-selector.html';
import { cleanupName } from '../../../containers/cdm/functions';

const BASE_ICON = 'glyphicon';
const DOWN_CARET_ICON = 'glyphicon-triangle-bottom';
const SIDE_CARET_ICON = 'glyphicon-triangle-right';

class TreeSelector {
	constructor() {}

	cleanup(name) {
		return cleanupName(name);
	}

    getIcon(node) {
        return {
			[BASE_ICON]: node.children.length > 0 && true,
			[DOWN_CARET_ICON]: node.children.length > 0 && node.expanded,
			[SIDE_CARET_ICON]: node.children.length > 0 && !node.expanded,
		};
    }

	handleClick($event, action, node) {
		$event.preventDefault();
		action.callback($event, node);
	}

    toggleCollapse(node) {
		node.expanded = !node.expanded;
    }
}

const component = {
    bindings: {
		actions: '<',
		isPopup: '<',
        nodes: '<',
    },
    controller: TreeSelector,
    templateUrl,
};

export default component;
