/* global jQuery, mina */
import templateUrl from './sidebar.html';
import Snap from 'snapsvg';
import {
    PrismsLocalStorage,
} from '../../prisms-local-storage';

const baseClass = 'sidebar-top-container';
const pinClass = 'pinned';

class Sidebar {
    constructor($element) {
        this.$onInit = () => {
            this.isAnimating = false;
            this.$element = $element;

            let morphElem = jQuery($element).find('#morph-shape'),
                s = Snap(morphElem.find('svg')[0]);

            this.path = s.select('path');
            this.initialPath = this.path.attr('d');
            this.pathOpen = morphElem.attr('data-morph-open');

            let storedPinned = PrismsLocalStorage.get('pinnedMenu');
            this.pinned = storedPinned == null ? true : storedPinned === 'true';
            this.menuClass = {
                [baseClass]: true,
                [pinClass]: this.pinned,
                'show-menu': this.pinned,
            };
            this.pinnedClass = {
                'pe-fw': true,
                'icon-expand2': this.pinned,
                'icon-contract2': !this.pinned,
            };
            this.enableTooltips = this.isOpen = this.pinned;

            if (this.isOpen) {
                this.path.attr('d', this.pathOpen);
            }
        }
    }

    pinMenu($event) {
        this.pinned = !this.pinned;
        this.enableTooltips = this.pinned;
        this.menuClass[pinClass] = this.pinned;
        this.pinnedClass['icon-expand2'] = this.pinned;
        this.pinnedClass['icon-contract2'] = !this.pinned;

        PrismsLocalStorage.set('pinnedMenu', this.pinned);
        $event.preventDefault();
    }

    toggleMenu($event) {
        if (this.isAnimating || this.pinned) return false;

        this.isAnimating = true;

        if (this.isOpen) {
            // jQuery('.sidebar-top-container').removeClass('show-menu');
            this.menuClass['show-menu'] = false;
            jQuery(document).off('click.sidebar');

            setTimeout(() => {
                // reset path
                this.path.attr('d', this.initialPath);
                this.isAnimating = false;
            }, 300);
        } else {
            // jQuery('.sidebar-top-container').addClass('show-menu');
            this.menuClass['show-menu'] = true;
            jQuery(document).on('click.sidebar', evt => {
                let elem = jQuery(evt.target),
                    parent = elem.parents('.sidebar-menu-wrap');

                if (parent.length === 0 && !elem.hasClass('pe-7s-menu')) {
                    this.toggleMenu();
                    jQuery(document).off('click.sidebar');
                }
            })
            this.path.animate({
                'path': this.pathOpen,
            }, 400, mina.easeinout, () => {
                this.isAnimating = false;
            });
        }
        this.isOpen = !this.isOpen;

        if ($event) {
            $event.preventDefault();
        }
    }
}

Sidebar.$inject = ['$element'];

const component = {
    templateUrl,
    controller: Sidebar,
    transclude: true,
    bindings: {
        navItems: '<',
    },
};

export default component;