export const WIDTHS = {
    small: 'col-xs-12 col-sm-12 col-md-4',
    medium: 'col-xs-12 col-sm-12 col-md-8',
    large: 'col-xs-12 col-sm-12 col-md-12',
};

export const HEIGHTS = {
    small: 'card-halfh',
    medium: 'card-1h',
    large: 'card-2h',
};
