import * as actionTypes from './action-types';
import PrismsServer from '../prisms-server';
import PrismsClient from '@brandeis/prisms-client-js';

export function getEvaluationTime() {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.FETCHING_EVALUATION_TIME,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            const systemTime = await prismsInstance.getSystemTimeObject();

            dispatch({
                type: actionTypes.SUCCESS_GET_EVALUATION_TIME,
                systemTime: systemTime.dateTimeString,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_EVALUATION_TIME,
            });
        }
    }
}

export function getSystemTime() {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.FETCHING_SYSTEM_TIME,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            const systemTime = await prismsInstance.getSystemTimeObject();

            dispatch({
                type: actionTypes.SUCCESS_GET_SYSTEM_TIME,
                systemTime: systemTime.dateTimeString,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_SYSTEM_TIME,
            });
        }
    }
}

export function setSystemTime(date) {
    return async (dep, dispatch) => {
        const sysTimeBody = new PrismsClient.SystemTimeParameter();
        sysTimeBody.dateTimeString = date.toISOString();
        const prismsInstance = await PrismsServer.getInstance();
        await prismsInstance.setSystemTimeObject(sysTimeBody);

        // dispatch getting the system time to have it propagate throughout the system correctly:
        dispatch(getSystemTime());
    }
}

export function selectEvaluationTime(date) {
    return {
        type: actionTypes.SELECT_EVALUATION_TIME,
        date,
    };
}
