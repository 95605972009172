// actions
import {
    getGroupHierarchy,
    deleteGroup,
} from '../../actions/groups';
import {
    stateGo,
} from 'redux-ui-router';

// template
import templateUrl from './group-manager.html';

const SIZES = {
    normal: '',
    dashLarge: 'dash-large',
    dashSmall: 'dash-small',
};

const baseClass = 'group-manager';

class GroupManager {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                getGroupHierarchy,
                deleteGroup,
                stateGo,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            let displayClass = SIZES[this.displaySize] || SIZES.normal;
            this.containerClasses = [baseClass, displayClass, 'view']
        };

        $scope.$watch(() => this.forceUpdate, () => {
            if (this.forceUpdate === 'true') {
                this.getGroupHierarchy(true);
            } else {
                this.getGroupHierarchy();
            }
        })
    }

    mapStateToThis(state) {
        const {
            groups,
        } = state.prisms;

        const {
            router,
        } = state;

        let params = {
            ...router.currentParams,
        };

        return {
            forceUpdate: params.forceUpdate,
            groups,
        };
    }

    deleteGroupClicked(group) {
        this.deleteGroup(group);
    }
}

GroupManager.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: GroupManager,
    bindings: {
        displaySize: '@',
    },
};

export default component;