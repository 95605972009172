import angular from 'angular';
import templateUrl from './prisms.html';
import {
    getSystemTime,
    setSystemTime,
} from './actions/evaluation-time';
import {
    logout,
} from './actions/authentication';
import {
    setDocId,
} from './actions/policies';

class Prisms {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                getSystemTime,
                logout,
                setDocId,
                setSystemTime,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            this.getSystemTime();
            this.showLogout = false;
            this.logoutClasses = ['popup'];
            this.datePickerShown = false;
            this.pickerOptions = {
                showWeeks: false,
            };

            this.allNavItems = [{
                    to: 'pa.dashboard',
                    title: 'Dashboard',
                    icon: 'pe-bold pe-lg pe-fw pe-7s-display1',
                    isSelected: () => /dashboard/.test(this.router.currentState.name),
                    checkShow: () => !this.isPDC,
                },
                {
                    to: 'pa.group-manager',
                    params: {
                        forceUpdate: false,
                    },
                    title: 'Manage Groups',
                    icon: 'pe-lg pe-fw icon-users',
                    isSelected: () => /group-manager/.test(this.router.currentState.name),
                    checkShow: () => this.isGroupsSupported,
                },
                {
                    to: 'pa.group-editor.basic-info',
                    params: {
                        id: 'new',
                        focusSection: '',
                    },
                    title: 'Create Groups',
                    icon: 'pe-lg pe-fw icon-users-plus',
                    isSelected: () => /group-editor/.test(this.router.currentState.name),
                    checkShow: () => this.isGroupsSupported && this.user.canEditGroups,
                },
                {
                    to: 'pa.policy-manager',
                    title: 'Manage Policies',
                    icon: 'pe-bold pe-lg pe-fw pe-7s-note2',
                    isSelected: () => /policy-manager/.test(this.router.currentState.name),
                    checkShow: () => true,
                },
                {
                    to: 'pa.policy-tester',
                    title: 'Review Policies',
                    icon: 'pe-bold pe-lg pe-fw pe-7s-edit',
                    isSelected: () => /policy-tester/.test(this.router.currentState.name),
                    checkShow: () => false, // TODO: not yet implemented!
                },
                {
                    to: 'pa.policy-editor.basic-info',
                    params: {
                        id: 'new',
                        focusSection: '',
                    },
                    title: 'Create Policy',
                    icon: 'pe-lg pe-fw icon-register',
                    checkShow: () => true,
                    isSelected: () => /policy-editor/.test(this.router.currentState.name),
                },
                {
                    to: 'pa.policy-review',
                    title: 'Access Matrix',
                    icon: 'pe-bold pe-lg pe-fw pe-7s-keypad',
                    isSelected: () => /policy-review/.test(this.router.currentState.name),
                    checkShow: () => this.dataTags.length > 0,
                },
                {
                    to: 'pa.risk',
                    title: 'Risk Surface',
                    icon: 'pe-bold pe-lg pe-fw pe-7s-graph3',
                    isSelected: () => /risk/.test(this.router.currentState.name),
                    checkShow: () => false, // TODO: not yet implemented!
                },
                {
                    to: 'pa.leakage',
                    title: 'Leakage',
                    icon: 'pe-lg pe-fw icon-thermometer',
                    isSelected: () => /leakage/.test(this.router.currentState.name),
                    checkShow: () => false, // TODO: not yet implemented!
                },
                {
                    to: 'pa.timeline',
                    title: 'Timeline',
                    icon: 'pe-lg pe-fw icon-clock',
                    isSelected: () => /timeline/.test(this.router.currentState.name),
                    checkShow: () => false, // TODO: not yet implemented!
                },
                {
                    to: 'pa.settings',
                    title: 'Settings',
                    icon: 'pe-bold pe-lg pe-fw pe-7s-settings',
                    isSelected: () => /settings/.test(this.router.currentState.name),
                    checkShow: () => false, // TODO: not yet implemented!
                },
            ];

            this.filterNavItems();
        };

        $scope.$watch(() => this.isGroupsSupported, () => {
            this.filterNavItems();
        });
        $scope.$watch(() => this.dataTags, () => {
            this.filterNavItems();
        });
    }

    mapStateToThis(state) {
        const {
            isPDC,
            user,
            systemTime,
            docIds,
            isGroupsSupported,
            dataTags,
        } = state.prisms;

        const {
            router,
        } = state;

        return {
            isPDC,
            user,
            systemTime,
            docIds,
            router,
            isGroupsSupported,
            dataTags,
        };
    }

    filterNavItems() {
        this.navItems = this.allNavItems.filter(item => item.checkShow());
    }

    cleanupDocId(docId) {
        return docId
            .replace(/.flr|.FLR/, '')
            .replace(/_/, ' ')
            .replace(/pdc/, 'PDC')
            .replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase());
    }

    pickSytemTime($event) {
        $event.preventDefault();
    }

    selectDate(date) {
        this.setSystemTime(date);
        this.datePickerShown = false;
    }

    toggleDatePicker() {
        this.datePickerShown = !this.datePickerShown;
    }

    toggleLogoutPanel($event) {
        this.showLogout = !this.showLogout;
        $event.preventDefault();
    }
}

Prisms.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: Prisms,
};

export default angular.module('brandeis.ui.prisms.main', [])
    .component('prisms', component)
    .name;
