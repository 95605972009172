import * as actionTypes from '../actions/action-types';

export function authenticationTime(state = -1, action) {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return action.timestamp || state;
        case actionTypes.LOGOUT:
        case actionTypes.LOGIN_ERROR:
            return -1;
        default:
            return state;
    }
}

export function isAuthenticated(state = 'false', action) {
    switch (action.type) {
        case actionTypes.AUTHENTICATING:
            return 'authenticating';
        case actionTypes.LOGIN_SUCCESS:
            return 'true';
        case actionTypes.IS_AUTHENTICATED:
            return action.isAuthenticated;
        case actionTypes.LOGOUT:
        case actionTypes.LOGIN_ERROR:
            return 'false';
        default:
            return state;
    }
}

export function isPDC(state = false, action) {
    switch (action.type) {
        case 'IS_PDC':
            return action.isPDC;
        default:
            return state;
    }
}

export function user(state = {
    name: '',
    roles: '',
}, action) {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...action.user,
                ...action.docIds,
            };
        case actionTypes.LOGOUT:
            return {};
        case actionTypes.LOGIN_ERROR:
            return {
                error: {
                    text: 'Invalid username/password combination',
                },
            };
        case actionTypes.SETTING_DOC_ID:
            return {
                ...state,
                docId: action.docId,
            };
        default:
            return state;
    }
}