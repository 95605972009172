import PrismsClient from '@brandeis/prisms-client-js';

// Singleton API client
const apiClient = PrismsClient.ApiClient.instance;

let authUrl = '';

const AuthServer = {
    displayname: async (username) => {
        return new Promise(async (resolve, reject) => {
            if (apiClient.authentications['bearerAuth'].apiKey != null) {
                try {
                    let response = await fetch(authUrl+'/users/'+username+'/displayname', {
                        headers: {
                            'Authorization': apiClient.authentications['bearerAuth'].apiKey,
                            'Content-Type': 'text/plain',
                        },
                        method: 'GET',
                    });

                    if (response.status !== 200) {
                        reject('could not fetch displayname');
                    } else {
                        resolve(response.text());
                    }
                } catch (err) {
                    reject(err);
                }
            } else {
                reject('no authentication token found')
            }
        });
    },

    configure: (url) => {
        authUrl = url;
    },
};

export default AuthServer;
