// libs
import angular from 'angular';

// components
import allowDisallow from '../../../components/policy-editor/basic-info/allow-disallow';
import basicDetails from '../../../components/policy-editor/basic-info/basic-details';
import policyAuthority from '../../../components/policy-editor/basic-info/policy-authority';

// container
import basicInfo from './basic-info';

export default angular.module('brandeis.ui.prisms.containers.policyEditor.basicInfo', [])
	.config(['$stateProvider', ($stateProvider) => {
        let basicInfoState = {
                name: 'pa.policy-editor.basic-info',
                url: '/{id}/basic-info/{focusSection}',
                views: {
                    policyEditorTabContent: 'basicInfo',
                },
				data: {
					isWizardStep: true,
				},
            };

        $stateProvider
            .state(basicInfoState);
	}])
    .component('basicDetails', basicDetails)
    .component('basicInfo', basicInfo)
    .component('allowDisallow', allowDisallow)
    .component('policyAuthority', policyAuthority)
    .name;
