// libs
import angular from 'angular';

// components
import policyDetails from '../../components/policy-tester/policy-details';
import policySelector from '../../components/policy-tester/policy-selector';
import resultsCard from '../../components/policy-tester/results-card';

// view
import policyTester from './policy-tester';

export default angular
    .module('brandeis.ui.prisms.views.policyTester', [])
    .config([
        '$stateProvider',
        $stateProvider => {
            let testerState = {
                name: 'pa.policy-tester',
                url: '/policy/review',
                views: {
                    prismsMain: 'policyTester',
                },
            };

            $stateProvider.state(testerState);
        },
    ])
    .component('policyDetails', policyDetails)
    .component('policySelector', policySelector)
    .component('resultsCard', resultsCard)
    .component('policyTester', policyTester).name;
