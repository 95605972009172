// actions
import {
    editPolicy,
    newPolicy,
} from '../../actions/policy';
import {
    initJDSdata,
} from '../../actions/jds';

// template
import templateUrl from './policy-editor.html';

class PolicyEditor {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                editPolicy,
                newPolicy,
                initJDSdata,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            this.tabContentName = 'policyEditorTabContent';
            this.initEditor();
        };

        $scope.$watch(() => this.currentParams, (newValue, oldValue) => {
            if (newValue && newValue.focusSection && newValue.focusSection !== oldValue.focusSection) {
                setTimeout(() => {
                    /* globals jQuery */
                    jQuery('.prisms-main').animate({
                        scrollTop: jQuery('#' + newValue.focusSection).offset().top,
                    }, 100);
                    jQuery('#' + newValue.focusSection).addClass('edit-focus animated flash');
                }, 0);
            }
            if (newValue && newValue.id !== oldValue.id) {
                this.initEditor();
            }
        });
        $scope.$watch(() => this.dataTags, (newValue) => {
            if (newValue && this.policy) {
                this.initJDSdata(this.policy, newValue);
            }
        });
    }

    mapStateToThis(state) {
        const {
            policy,
            policyAuthorities,
            requesterAttributeInfos,
            groups,
            dataTags,
            wizardSteps,
        } = state.prisms;

        const {
            router,
        } = state;

        let tabId = router.currentState.name.split('.').pop();
        let params = {
            ...router.currentParams,
        };
        delete params['#'];

        return {
            currentParams: params,
            policy,
            policyAuthorities,
            requesterAttributeInfos,
            groups,
            dataTags,
            isReview: tabId === 'review',
            steps: wizardSteps,
        };
    }

    scrollToTop() {
        /* global jQuery */
        jQuery('.prisms-main').animate({
            scrollTop: 0,
        }, 'fast');
    }

    initEditor() {
        if (this.currentParams.id === 'new') {
            this.newPolicy(this.tabContentName);
        } else {
            this.editPolicy(this.currentParams.id, this.tabContentName);
        }
    }
}

PolicyEditor.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicyEditor,
    bindings: {
        displaySize: '@',
    },
};

export default component;
