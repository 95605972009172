import templateUrl from './pdc-selector.html';
import {buildConstraints} from '../../../functions';

class PDCSelector {

    constructor($scope) {
        this.$scope = $scope;

        this.isAll = true;
        this.tags = [];
        this.checkedTags = [];
        this.constraints = buildConstraints([], []);
        this.isAllowPolicy = true;

        this.hasGeoConstraints = true;  // TODO: decide more meaningfully!
        this.selectedRegion = 'bla';
        this.regions = ['a', 'bla', 'c'];

        $scope.$watch(() => this.tagSelection, (newValue) => {
            this.tags = [];
            if (newValue) {
                if (newValue.tagsNotNegated) {
                    this.tags = newValue.tags;
                    this.isAll = this.tags.every(a => !a.checked);
                    this.checkedTags = this.tags
                        .filter(t => t.checked)
                        .sort((t1, t2) => t1.displayName.localeCompare(t2.displayName));
                }
            }
        });

        $scope.$watch(() => this.jdsData, (newValue) => {
            if (newValue) {
                console.log('PDC JDS data: ', newValue);
                this.constraints = buildConstraints(newValue.filterConstraints, newValue.actionConstraints);
            }
        });

        $scope.$watch(() => this.policyDecision, (newValue) => {
            if (newValue)
                this.isAllowPolicy = newValue.toString() === 'ALLOWED';
        });
    }

    toggleAll() {
        // clicking the All checkbox really means to UNCHECK all tags:
        // note that the checkbox for All is disabled when no tag is checked (i.e., it is checked),
        // so toggling it only happens isAll === true and we are moving from
        // an unchecked All checkbox to a checked All checkbox
        if (this.isAll) {
            let needUpdate = false;
            this.tags.forEach(a => {
                if (a.checked) {
                    a.checked = false;
                    needUpdate = true;
                }
            });
            if (needUpdate) {
                this.updateTags();
            }
        }
    }

    updateTags() {
        let ts = this.tagSelection;
        ts.tags = this.tags;
        this.onTagsUpdated({newTagSelection: ts});
    }
}

PDCSelector.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: PDCSelector,
    bindings: {
        tagSelection: '<',
        jdsData: '<',
        policyDecision: '<',
        onTagsUpdated: '&',
    },
};

export default component;
