import {
    PrismsLocalStorage,
} from '../../prisms-local-storage';

import templateUrl from './dashboard.html';
import {
    getPolicies,
} from '../../actions/policies';
import {
    WIDTHS,
    HEIGHTS,
} from '../../components/card/constants';


class Dashboard {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                getPolicies,
            })(this);

        $scope.$on('$destroy', unsubscribe);
        this.$scope = $scope;

        this.$onInit = () => {
            this.hasPolicyWidget = this.hasRiskSurfaceWidget = this.hasTimelineWidget = false;
            this.showingCardMenu = false;
            this.cards = JSON.parse(PrismsLocalStorage.get('dashboard-cards') || '[]');

            if (this.cards.length === 0) {
                this.cards = [{
                    widget: 'policiesTableCard',
                    id: 'policies',
                    link: 'pa.policy-manager',
                    title: 'Policies',
                    subTitle: 'All policies including defaults',
                    width: WIDTHS.medium,
                    height: HEIGHTS.medium,
                }];
            }

            for (let card of this.cards) {
                this.checkCardType(card, true);
            }

            if (this.policies.length === 0) {
                this.getPolicies();
            }
        }
    }

    mapStateToThis(state) {
        const {
            policies,
            systemTime,
        } = state.prisms;

        return {
            policies,
            systemTime,
        };
    }

    addCard(type) {
        let card = {
            width: WIDTHS.large,
            height: HEIGHTS.small,
        };

        switch (type) {
            case 'Policies':
                card = {
                    ...card,
                    widget: 'policiesTableCard',
                    id: 'policies',
                    link: 'pa.policy-manager',
                    title: 'Policies',
                    subTitle: 'All policies including defaults',
                };
                this.hasPolicyWidget = true;
                break;
            case 'Risk Surface':
                card = {
                    ...card,
                    widget: 'riskSurfaceCard',
                    id: 'risk-surface',
                    link: 'pa.risk-surface',
                    title: 'Risk Surface',
                    subTitle: 'Policy risk surfaces',
                };
                this.hasRiskSurfaceWidget = true;
                break;
            case 'Timeline':
                card = {
                    ...card,
                    widget: 'timelineCard',
                    id: 'timeline',
                    link: 'pa.timeline',
                    title: 'Timeline',
                    subTitle: '',
                };
                this.hasTimelineWidget = true;
                break;
        }

        this.cards.push(card);

        PrismsLocalStorage.set('dashboard-cards', JSON.stringify(this.cards));
        this.showingCardMenu = false;
    }

    cardDimensionUpdated(id, dimensions) {
        this.cards = this.cards.map(c => {
            if (c.id === id) {
                return {
                    ...c,
                    ...dimensions,
                };
            }
            return c;
        });

        PrismsLocalStorage.set('dashboard-cards', JSON.stringify(this.cards));
    }

    checkCardType(card, flag) {
        switch (card.widget) {
            case 'policiesTableCard':
                this.hasPolicyWidget = flag;
                break;
            case 'riskSurfaceCard':
                this.hasRiskSurfaceWidget = flag;
                break;
            case 'timelineCard':
                this.hasTimelineWidget = flag;
                break;
        }
    }

    remove(id) {
        let removed = this.cards.splice(this.cards.findIndex(c => c.id === id), 1);

        this.checkCardType(removed[0], false);

        PrismsLocalStorage.set('dashboard-cards', JSON.stringify(this.cards));
    }

    toggleAddCardMenu() {
        this.showingCardMenu = !this.showingCardMenu;

        if (this.showingCardMenu) {
            /* global jQuery */
            jQuery(document).on('click.add-card-menu', (evt) => {
                let target = jQuery(evt.target),
                    parent = target.parents('.add-card-menu, .dashboard-header .actions');

                if (parent.length === 0) {
                    this.showingCardMenu = false;
                    this.$scope.$apply();
                    jQuery(document).off('click.add-card-menu');
                }
            })
        } else {
            jQuery(document).off('click.add-card-menu');
        }
    }
}

Dashboard.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: Dashboard,
};

export default component;