import templateUrl from './review-precedence.html';

class ReviewPrecedence {
    constructor() {
        this.$onChanges = changesObj => {
            let currentPrecedence = changesObj.currentPrecedence;

            if (currentPrecedence) {
                this.priority = currentPrecedence.currentValue;
            }
        }
    }

    changedPrecedence(precedence) {
        this.onChangedPrecedence({ precedence });
    }
}

const component = {
    templateUrl,
    controller: ReviewPrecedence,
    bindings: {
        currentPrecedence: '<',
        editId: '<',
        isPdc: '<',
        isSummary: '<',
        onChangedPrecedence: '&',
        policies: '<',
    },
};

export default component;
