import PrismsClient from '@brandeis/prisms-client-js';
import {
    KJUR,
    b64utoutf8,
} from 'jsrsasign';

// Singleton API client
const apiClient = PrismsClient.ApiClient.instance;

let authOptions = {
    loginUrl: '',
    refreshUrl: '',
};

const PrismsServer = {
    authenticate: async (credentials) => {
        return new Promise(async (resolve, reject) => {
            if (apiClient.authentications['bearerAuth'].apiKey == null) {
                try {
                    let response = await fetch(authOptions.loginUrl, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            username: credentials.username,
                            password: credentials.password,
                        }),
                        method: 'POST',
                    });
                    if (response.status !== 200) {
                        reject('Invalid Login');
                    } else {
                        let apiKey = await response.text();
                        apiClient.authentications['bearerAuth'].apiKey = 'Bearer ' + apiKey;
                        let payloadJSON = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(apiKey.split('.')[1]));
                        let filteredAndSortedRoles = payloadJSON['roles']
                            .filter(function(role) {
                                return role.startsWith('Prisms_');
                            })
                            .map(function(role) {
                                return role.substring(7);
                            }) // remove "Prisms_" prefix
                            .map(function(role) {
                                return role.replace(/([a-z])([A-Z])/g, '$1 $2')
                            }) // space between lower and upper case characters
                            .sort()
                            .join(' | ');
                        let canEditGroups = payloadJSON['roles'].indexOf('Prisms_Admin') > -1;
                        let canEditConflictDefault = payloadJSON['roles'].indexOf('Prisms_Admin') > -1;
                        let canChoosePAs = filteredAndSortedRoles.indexOf('Admin') > -1;
                        resolve({
                            roles: filteredAndSortedRoles,
                            username: payloadJSON['floraname'],
                            canEditGroups,
                            canEditConflictDefault,
                            canChoosePAs,
                        });
                    }
                } catch (err) {
                    reject(err);
                }
            } else {
                resolve({});
            }
        });
    },

    configureServer: (serverUrl, authUrl) => {
        apiClient.basePath = serverUrl;
        authOptions.loginUrl = authUrl + '/login';
        authOptions.refreshUrl = authUrl + '/refresh';
    },

    checkAuthenticated: function() {
        return apiClient.authentications['bearerAuth'].apiKey != null;
    },

    logout: function() {
        apiClient.authentications['bearerAuth'].apiKey = null;
    },

    getInstance: async () => {
        return new Promise((resolve) => {
            let checkAuthenticated = () => {
                if (apiClient.authentications['bearerAuth'].apiKey != null) {
                    resolve(new PrismsClient.PRISMSServiceApi());
                } else {
                    setTimeout(checkAuthenticated, 100);
                }
            }

            checkAuthenticated();
        });
    },

    refresh: async () => {
        let response = await fetch(authOptions.refreshUrl, {
            headers: {
                'Authorization': apiClient.authentications['bearerAuth'].apiKey,
            },
            method: 'POST',
        });

        async function success(resolve) {
            let apiKey = await response.text();
            apiClient.authentications['bearerAuth'].apiKey = 'Bearer ' + apiKey;
            resolve(true);
        }

        return new Promise((resolve, reject) => {
            if (response.status === 200) {
                success(resolve);
            } else {
                // logout
                reject(false);
            }
        });
    },

    setUsername: function(username) {
        this.username = username;
    },
};

//apiClient.basePath = '/ipoet/rest';
apiClient.timeout = 600000; // allow up to 10min for queries
apiClient.enableCookies = true;

export default PrismsServer;
