import * as actionTypes from '../actions/action-types';

export function evaluationTime(state = null, action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_EVALUATION_TIME:
            return new Date(action.systemTime);
        default:
            return state;
    }
}

export function selectedEvaluationTime(state = null, action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_EVALUATION_TIME:
            return new Date(action.systemTime);
        case actionTypes.SELECT_EVALUATION_TIME:
            return action.date;
        default:
            return state;
    }
}

export function systemTime(state = null, action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_SYSTEM_TIME:
            return new Date(action.systemTime);
        default:
            return state;
    }
}
