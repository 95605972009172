import templateUrl from './allow-disallow.html';

class AllowDisallow {
    constructor($scope) {
        this.$scope = $scope;

        $scope.$watch(() => this.policy.decision, (oldValue, newValue) => {
            if (oldValue !== newValue) {
                this.onFormChanged();
            }
        });
    }
}

AllowDisallow.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: AllowDisallow,
    bindings: {
        onFormChanged: '&',
        policy: '<',
    },
};

export default component;
