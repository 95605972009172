// actions
import {
    addPolicy,
    setReviewButtons,
    updatePolicy,
    updatePolicyLocal,
} from '../../../actions/policy';

import {buildRequestersFromDRFilters} from '../../../functions';

// template
import templateUrl from './review.html';

class PolicyReview {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                addPolicy,
                setReviewButtons,
                updatePolicy,
                updatePolicyLocal,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            // console.log('policy reviewed: ', this.policy);
            let customButtons = [{
                name: () => {
                    return 'Submit'
                },
                callback: () => {
                    console.log('policy submitted: ', this.policy);
                    this.policy.isSubmitted = true;
                    if (this.policy.isNew) {
                        this.addPolicy(this.policy);
                    } else {
                        this.updatePolicy(this.policy);
                    }
                },
                isDisabled: () => {
                    return this.policy.isSubmitted;
                },
                btnClasses: () => {
                    return {
                        'btn-primary': true,
                    };
                },
            }];
            this.setReviewButtons(customButtons);
        };

        $scope.$watch(() => this.drFilters, (newValue) => {
            if (newValue) {
                this.policy.requesters = buildRequestersFromDRFilters(newValue);
                this.updatePolicyLocal(this.tabId, this.policy, this.policy.requesters.length > 0);
            }
        });
    }

    mapStateToThis(state) {
        const {
            drFilters,
            jdsNodes,
            jdsEdges,
            jdsClassTree,
            jdsConstraintsMap,
            policies,
            policy,
            allData,
            tagSelection,
            geoSelection,
            jdsData,
        } = state.prisms;

        const {
            router,
        } = state;

        let tabId = router.currentState.name.split('.').pop();
        let params = {
            ...router.currentParams,
        };

        return {
            classTree: jdsClassTree,
            constraintsMap: jdsConstraintsMap,
            editId: params.id,
            pathTree: {
                nodes: jdsNodes,
                edges: jdsEdges,
            },
            drFilters,
            policies,
            policy,
            allData,
            tagSelection,
            geoSelection,
            jdsData,
            tabId,
        };
    }

    changedPrecedence(precedence) {
        this.policy.priority = precedence;
        this.updatePolicyLocal(this.tabId, this.policy, true);
    }
}

PolicyReview.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicyReview,
};

export default component;
