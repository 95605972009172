// actions
import {
    getCDMData,
    setExploringCDM,
    updateJDSdata,
    updateTagSelection,
    updateGeoSelection,
} from '../../../actions/jds';
import {
    updatePolicyLocal,
} from '../../../actions/policy';

// template
import templateUrl from './data-and-constraints.html';

import {PDC_POLICY_DATA} from '../../../constants';
import {
    buildFilterConstraintsFromSelections,
    constraintListToFormula,
} from '../../../functions';

class PolicySelectData {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                getCDMData,
                setExploringCDM,
                updateJDSdata,
                updateTagSelection,
                updateGeoSelection,
                updatePolicyLocal,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.showCDMExplorer = false;

        this.$onInit = () => {
            this.isEditingPath = true;
            this.isReset = false;
            this.showCDMExplorer = false;
            this.showSaveDialog = false;
            this.awaitingUpdate = false;
            this.getCDMData();
        };

        $scope.$watch(() => this.jdsData, (newValue) => {
            if (newValue) {
                this.updatePolicyFromJDS(null);
            }
        });
        $scope.$watch(() => this.tagSelection, (newValue) => {
            if (newValue) {
                this.updateSelections();
            }
        });
        $scope.$watch(() => this.geoSelection, (newValue) => {
            if (newValue) {
                this.updateSelections();
            }
        });
    }

    mapStateToThis(state) {
        const {
            isPDC,
            jdsNodes,
            jdsEdges,
            jdsClassTree,
            jdsConstraintsMap,
            jdsList,
            policy,
            jdsData,
            allData,
            predicateNames,
            dataTags,
            tagSelection,
            geoSelection,
        } = state.prisms;

        const {
            router,
        } = state;

        let tabId = router.currentState.name.split('.').pop();

        return {
            classTree: jdsClassTree,
            constraintsMap: jdsConstraintsMap,
            isPDC,
            jdsList,
            pathTree: {
                nodes: jdsNodes,
                edges: jdsEdges,
            },
            policy,
            jdsData,
            allData,
            predicateNames,
            dataTags,
            tagSelection,
            geoSelection,
            tabId,
        };
    }

    updateSelections() {
        if (!this.isPDCPolicyData(this.policy.policyData)) {
            this.policy.policyData = PDC_POLICY_DATA;
            this.updatePolicyLocal(this.tabId, this.policy, true);
        }
        if (this.awaitingUpdate) {
            this.awaitingUpdate = false;
            this.policy.filterConstraints = buildFilterConstraintsFromSelections(this.tagSelection, this.geoSelection);
            this.updatePolicyLocal(this.tabId, this.policy, true);
        }
    }

    updatePolicyFromJDS(data) {
        if (data) {
            this.policy.policyData = data.policyData;
            this.policy.filterConstraints = data.filterConstraints;
            this.policy.actionConstraints = data.actionConstraints;
            this.updatePolicyLocal(this.tabId, this.policy, this.policy.policyData.length > 0);
        }
    }

    isPDCPolicyData(policyData) {
        if (!policyData || policyData.length !== 1 || policyData[0].length !== 1)
            return false;
        // now compare singleton policy data element against PDC_POLICY_DATA:
        for (let propName of Object.getOwnPropertyNames(PDC_POLICY_DATA[0][0])) {
            if (PDC_POLICY_DATA[0][0][propName] !== policyData[0][0][propName]) {
                return false;
            }
        }
        return true;
    }

    updatePDCTags(newTagSelection) {
        this.awaitingUpdate = true;
        this.updateTagSelection(newTagSelection);
    }

    updatePDCRegions(newRegionSelected) {
        this.awaitingUpdate = true;
        this.updateGeoSelection(newRegionSelected);
    }

    onExploreCdm(flag = true) {
        let customButtons = null;

        if (flag) {
            customButtons = [{
                name: () => {
                    return 'Start Over'
                },
                callback: () => {
                    this.isReset = true;
                },
                isDisabled: () => {
                    return false;
                },
                btnClasses: () => {
                    return {
                        'btn-primary': true,
                    };
                },
            }, {
                name: () => {
                    if (this.isEditingPath) {
                        return 'Save and Return';
                    } else {
                        return 'Edit Path';
                    }
                },
                callback: () => {
                    if (this.isEditingPath) {
                        this.showSaveDialog = true;
                    } else {
                        this.isEditingPath = true;
                    }
                },
                isDisabled: () => {
                    return false;
                },
                btnClasses: () => {
                    return {
                        'btn-primary': true,
                    };
                },
            }, {
                name: () => {
                    return this.currentJDS == null ? 'Cancel and Return' : 'Select and Return'
                },
                callback: () => {
                    this.onExploreCdm(false);
                    if (this.currentJDS) {
                    }
                    this.currentJDS = null;
                },
                isDisabled: () => {
                    return false;
                },
                btnClasses: () => {
                    return {
                        'btn-danger': this.currentJDS == null,
                        'btn-success': this.currentJDS != null,
                    };
                },
            }];
        }
        this.isEditingPath = true;
        this.isReset = false;
        this.showCDMExplorer = flag;
        this.showSaveDialog = false;
        this.setExploringCDM(flag, customButtons);
    }

    jdsSaved(jds) {
        this.onExploreCdm(false);
        // first, divide constraint list by action and filter predicates:
        let data = {
            policyData: jds.policyData,
            filterConstraints: constraintListToFormula(jds.constraintData.constraintList
                .filter(cd => this.predicateNames.filterPredicates.indexOf(cd.predicate) >= 0),
                jds.constraintData.root_class),
            actionConstraints: constraintListToFormula(jds.constraintData.constraintList
                .filter(cd => this.predicateNames.actionPredicates.indexOf(cd.predicate) >= 0),
                jds.constraintData.root_class),
        };
        this.updateJDSdata(data);
        this.updatePolicyFromJDS(data);  // to validate whether we can go to next tab
    }
}

PolicySelectData.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicySelectData,
};

export default component;
