// libs
import angular from 'angular';

// components
import cdmGraphDialog from '../../../components/policy-editor/review/cdm-graph-dialog';
import reviewBasicInfo from '../../../components/policy-editor/review/review-basic-info';
import reviewDataRequesters from '../../../components/policy-editor/review/review-data-requesters';
import reviewDataAndConstraints from '../../../components/policy-editor/review/review-data-and-constraints';
import reviewPrecedence from '../../../components/policy-editor/review/review-precedence';

// container
import review from './review';

export default angular.module('brandeis.ui.prisms.containers.policyEditor.review', [])
	.config(['$stateProvider', ($stateProvider) => {
        let reviewState = {
                name: 'pa.policy-editor.review',
                url: '/{id}/review',
                views: {
                    policyEditorTabContent: 'review',
                },
				data: {
					isWizardStep: true,
				},
            };

        $stateProvider
            .state(reviewState);
	}])
    .component('cdmGraphDialog', cdmGraphDialog)
    .component('review', review)
    .component('reviewBasicInfo', reviewBasicInfo)
    .component('reviewDataAndConstraints', reviewDataAndConstraints)
    .component('reviewDataRequesters', reviewDataRequesters)
    .component('reviewPrecedence', reviewPrecedence)
    .name;
