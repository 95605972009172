// actions
import {
    updatePolicyLocal,
} from '../../../actions/policy';

// template
import templateUrl from './basic-info.html';

class PolicyBasicInfo {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                updatePolicyLocal,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        $scope.$watch(() => this.user, () => {
            this.initSelectedPA();
        });
        $scope.$watch(() => this.policy, () => {
            this.initSelectedPA();
        });
    }

    mapStateToThis(state) {
        const {
            policy,
            user,
            policyAuthorities,
        } = state.prisms;

        const {
            router,
        } = state;

        let tabId = router.currentState.name.split('.').pop();
        let params = {
            ...router.currentParams,
        };

        return {
            focusSection: params.focusSection,
            policy,
            user,
            policyAuthorities,
            tabId,
        };
    }

    formChanged(form, isValid) {
        this.updatePolicyLocal(this.tabId, form, isValid);
    }

    allowDisallowSelected() {
        this.updatePolicyLocal(this.tabId, this.policy, true);
    }

    initSelectedPA() {
        if (this.user && this.policy) {
            if (!this.user.canChoosePAs &&
                this.user.roles.indexOf('Policy Authority') >= 0 &&
                !this.policy.hasOwnProperty('policyAuthority')) {
                this.paSelected(this.user.username, true);
            }
        }
    }

    paSelected(pa, isValid) {
        this.policy.policyAuthority = pa;
        this.updatePolicyLocal(this.tabId, this.policy, isValid);
    }
}

PolicyBasicInfo.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicyBasicInfo,
};

export default component;
