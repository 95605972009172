// actions
import {
    stateGo,
} from 'redux-ui-router';
import {
    clearCurrentGroup,
    createGroup,
} from '../../actions/groups';

// template
import templateUrl from './basic-details.html';

class GroupBasicDetails {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                clearCurrentGroup,
                createGroup,
                stateGo,
            })(this);

        $scope.$on('$destroy', () => {
            if (!this.proceedToMembership) {
                this.clearCurrentGroup();
            }
            unsubscribe();
        });

        $scope.$watch(() => this.currentGroup, (newValue) => {
            if (newValue.status === 'success') {
                if (this.proceedToMembership) {
                    this.stateGo('pa.group-editor.member-selection', {
                        id: this.currentGroup.id,
                        focusSection: '',
                    });
                } else {
                    this.clearCurrentGroup();
                    this.stateGo('pa.group-manager', {
                        forceUpdate: true,
                    });
                }
            } else if (newValue.errorMessage) {
                if (newValue.errorMessage.match(/group with name/) != null) {
                    // set the error state of the form
                    this.basicGroupInfo.name.$setValidity('alreadyExists', false);
                } else if (newValue.errorMessage.match(/group with acronym/) != null) {
                    // set the error state of the form
                    this.basicGroupInfo.acronym.$setValidity('alreadyExists', false);
                }
            }
        });
    }

    mapStateToThis(state) {
        const {
            currentGroup,
        } = state.prisms;

        const {
            router,
        } = state;

        let params = {
            ...router.currentParams,
        };

        return {
            currentGroup,
            focusSection: params.focusSection,
        };
    }

    onCancel() {
        this.stateGo('pa.group-manager', {
            forceUpdate: false,
        });
    }

    onCreateGroup(group, proceedToMembership) {
        this.createGroup(group);
        this.proceedToMembership = proceedToMembership;
    }

    onUpdateGroup(group, proceedToMembership) {
        this.updateGroup(group);
        this.proceedToMembership = proceedToMembership;
    }
}

GroupBasicDetails.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: GroupBasicDetails,
};

export default component;