import jsPlumbLib from 'jsplumb';
import dagre from 'dagre';
import templateUrl from './cdm-graph.html';
import {
    cleanupName,
    convertFromJds,
    convertPathToPolicyData,
    convertPathToConstraints,
    existsAsAncestor,
    extendProperties,
    isChild,
    findNode,
    traverseClassTree,
} from './functions';
import {isAllData, sortConstraints} from '../../functions';

/* global jQuery */
const MAX_LEVEL = 1;
const DEBOUNCE = 200;

class CdmGraph {
    constructor($scope, $element, $uibModal) {
        // ensure jquery and not jqlite
        this.$element = jQuery($element[0]);
        this.jsPlumb = jsPlumbLib.jsPlumb;

        this.jsPlumb.ready(() => {
            this.plumbInstance = this.jsPlumb.getInstance({
                Connector: [ 'Bezier', { curviness: 30 } ],
                Container: 'path-elements',
                Anchors: [ [ 'Right' ], [ 'Left', 'Right' ] ],
            });
        });

        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.constraints = {};
        this.isRedrawing = false;
        this.jds = {
            name: '',
            description: '',
            editing: true,
        };
        this.lastClassTreePopupTime = Date.now();
        this.lastConstraintPopupTime = Date.now();
        this.pathSet = false;
        this.selectTree = null;
        this.showClassTreePopup = false;
        this.showConstraintPopup = false;
        this.hover = {
            edges: [],
            nodes: [],
        };

        $scope.$watch(() => this.selectedJds, newValue => {
            if (newValue != null) {
                this.reset();
                if (isAllData(newValue)) {
                    this.jds.policyData = [];
                    this.jds.constraintData = [];
                } else {
                    this.jds.policyData = newValue.policyData;
                    this.jds.constraintData =
                        sortConstraints([...newValue.filterConstraints, ...newValue.actionConstraints]);
                }
                this.selectTree = this.currentNode = convertFromJds(this.jds.policyData, this.jds.constraintData,
                    this.pathTree.nodes, this.pathTree.edges, this.classTree);
                this.constructGraph(!this.jds.editing);
            }
        });

        this.$onChanges = changesObj => {
            let showSaveDlg = changesObj.showSaveDialog,
                editPath = changesObj.isEditingPath,
                reset = changesObj.isReset;

            if (showSaveDlg && !showSaveDlg.isFirstChange()) {
                if (showSaveDlg.currentValue) {
                    this.showDialog();
                }
            }
            if (editPath && !editPath.isFirstChange()) {
                if (editPath.currentValue) {
                    this.editGraph();
                }
            }
            if (reset && !reset.isFirstChange()) {
                if (reset.currentValue) {
                    this.reset();
                }
            }
        }

        this.createDigestWatcher();
    }

    constructGraph(noTraverse) {
        this.isRedrawing = true;
        this.plumbInstance.deleteEveryEndpoint();
        this.ndx = 0;

        let buildNodesArray = (topNode, nodes, edges) => {
            topNode.trackId = `${topNode.id}-${this.ndx++}`;
            topNode.noTraverse = noTraverse;
            nodes.push(topNode);
            for (let child of topNode.selectedChildren) {
                child.edge.selected = true;
                edges.push(child.edge);
                buildNodesArray(child, nodes, edges);
            }
        };

        this.graphNodes = [];
        this.graphEdges = [];

        buildNodesArray(this.selectTree, this.graphNodes, this.graphEdges);

        if (!noTraverse) {
            this.traverse(this.currentNode, this.currentNode, 1);
        }
    }

    createDigestWatcher() {
        let digestObserverValue = 0, digestObserverHandledValue  = -1;

        let onBeforePaint = () => {
            // Called before painting
            digestObserverValue++; // Prepare for the next digest
            if (this.isRedrawing || this.isSimpleRedraw) {
                jQuery(document.body).trigger('paint.ng');
                setTimeout(function() {
                    // Called almost right after painting
                    jQuery(document.body).trigger('painted.ng');
                    this.isRedrawing = false;
                }, 0);
            }
        };

        this.$scope.$watch(() => {
            // Called on every digest.
            if( digestObserverHandledValue  !== digestObserverValue ) {
                // Executed only once per multiple sequential digest cycles (until $evalAsync and later
                // timeout/animation handlers are called).
                digestObserverHandledValue = digestObserverValue;
                this.$scope.$evalAsync(function() {
                    // Called after at least single digest cycle is finished, but before rendering.
                    if( 'requestAnimationFrame' in window ) {
                        // Get onBeforePaint() called right before DOM styles are calculated and page is
                        // painted.
                        requestAnimationFrame(onBeforePaint);
                    }
                    else {
                        // For older browser compatibility: get onBeforePaint() called before painting
                        // when javascript engine finishes executing the current thread code and starts
                        // handling expired timers (0ms timeout).
                        setTimeout(onBeforePaint, 0);
                    }
                });
            }
            return digestObserverValue;
        }, function() {});

        jQuery(document.body).on('painted.ng', () => {
            if (this.isSimpleRedraw) {
                this.layoutGraph();
                this.isSimpleRedraw = false;
            } else {
                this.plumbInstance.batch(() => {
                    // now show the paths container
                    jQuery('.path-elements').css({ height: 'auto', display: 'block' });
                    this.dg = new dagre.graphlib.Graph();

                    for (let edge of this.graphEdges) {
                        let edgeId = `${edge.vizsource.trackId}-${edge.viztarget.trackId}`;
                        let connectObj = {
                            source: edge.vizsource.trackId,
                            target: edge.viztarget.trackId,
                            connector: [
                                'Flowchart',
                                { cornerRadius: 6, stub: 16 },
                            ],
                            endpoints: ['Blank', 'Blank'],
                            overlays: [
                                [ 'Arrow', { location: 1, width: 10, length: 10 } ],
                                [
                                    'Label',
                                    {
                                        label: cleanupName(edge.name),
                                        id: edgeId,
                                        location: -50,
                                        cssClass: `edge-label ${edge.selected ? 'selected' : ''}`,
                                    },
                                ],
                            ],
                            cssClass: `path-edge ${edgeId}`,
                            detachable: false,
                        };

                        this.plumbInstance.connect(connectObj);
                    }

                    this.layoutGraph();
                    this.$scope.$apply();
                });


                this.jsPlumb.fire('jsPlumbDemoLoaded', this.plumbInstance);
            }
        })
    }

    editGraph() {
        this.jds.editing = true;
        this.constructGraph(false);
    }

    getSubTree(node) {
        node.classTree = [traverseClassTree(this.classTree, node.name, 1)];
    }

    handleAddNode(nodeToAdd, isShared) {
        let nodes = [],
            currentEdge = nodeToAdd.edge,
            currentNode = nodeToAdd,
            prevNode = nodeToAdd,
            isLoop = existsAsAncestor(currentEdge.vizsource, nodeToAdd.name);

        let moveAlong = () => {
            this.isRedrawing = true;
            currentNode.constraints = [];
            currentNode.selectedChildren = [];
            currentNode.selected = true;
            currentNode.isLoop = isLoop;
            currentNode.isShared = isShared;
            nodes.push(currentNode);

            extendProperties(currentNode);

            while (currentEdge.vizsource !== this.currentNode) {
                prevNode = currentNode;
                currentNode = currentEdge.vizsource;
                currentEdge = currentNode.edge;

                currentNode.constraints = [];
                currentNode.selectedChildren = [prevNode];
                currentNode.selected = true;
                currentNode.isShared = isShared;

                extendProperties(currentNode);
                nodes.unshift(currentNode);
            }

            let topNode = nodes[0];
            let parentLeaf = topNode.edge.vizsource;
            topNode.level = parentLeaf.level + 1;
            parentLeaf.selectedChildren.push(topNode);

            // reset hover state
            this.hover.edges = [];
            this.hover.nodes = [];
            for (let node of nodes) {
                jQuery('#' + node.trackId).removeClass('hover');
            }

            this.setCurrentNode(nodeToAdd);
            this.constructGraph();
        }

        if (isLoop) {
            // show modal
            let modal = this.$uibModal.open({
                component: 'loopForm',
            });

            modal.result.then(() => {
                moveAlong();
            }, () => {

            });
        } else {
            moveAlong();
        }
    }

    handleNodeMouseover(hoverNode) {
        if (!hoverNode.selected) {
            // we want to highlight not only the moused over node, but also the previous nodes and edges in the paths
            let edges = [],
                nodes = [],
                currentEdge = hoverNode.edge,
                currentNode = hoverNode;

            // so first, lets traverse
            edges.push(currentEdge);
            nodes.push(currentNode);

            while (currentEdge.vizsource.trackId !== this.currentNode.trackId) {
                currentNode = currentEdge.vizsource;
                currentEdge = currentNode.edge;
                nodes.push(currentNode);
                edges.push(currentEdge);
            }

            // now that we've collected the edges and nodes, let's get their elements and
            // style accordingly
            for (let node of nodes) {
                node.hover = true;
                jQuery('#' + node.trackId).addClass('hover');
            }
            for (let edge of edges) {
                let edgeId = `${edge.vizsource.trackId}-${edge.viztarget.trackId}`;
                jQuery('.' + edgeId).addClass('hover');
                this.plumbInstance.select({ source: edge.vizsource.trackId, target: edge.viztarget.trackId }).showOverlay(edgeId);
            }

            this.hover.edges = edges;
            this.hover.nodes = nodes;
        }
    }

    handleNodeMouseout() {
        for (let node of this.hover.nodes) {
            node.hover = false;
            jQuery('#' + node.trackId).removeClass('hover');
        }
        for (let edge of this.hover.edges) {
            let edgeId = `${edge.vizsource.trackId}-${edge.viztarget.trackId}`;
            jQuery('.' + edgeId).removeClass('hover');
            this.plumbInstance.select({ source: edge.vizsource.trackId, target: edge.viztarget.trackId }).hideOverlay(edgeId);
        }

        this.hover.edges = [];
        this.hover.nodes = [];
    }

    handleRemoveSelectedNode(nodeToRemove) {
        let parentNode = nodeToRemove.edge.vizsource,
            childrenArray = nodeToRemove.edge.vizsource.selectedChildren,
            ndx = childrenArray.indexOf(nodeToRemove);
        childrenArray.splice(ndx, 1);

        if (findNode(nodeToRemove, this.currentNode.name)) {
            this.setCurrentNode(parentNode);
        }

        for (let prop of nodeToRemove.dataProperties) {
            prop.selected = false;
        }

        this.constructGraph();
    }

    handleSubclassSelected(node, subclass) {
        // save place in tree
        let edge = node.edge,
            selectedChildren = [...node.selectedChildren],
            constraints = [...node.constraints];

        node.selectedChildren = [];
        node.constraints = [];

        let foundNode = this.pathTree.nodes.find(n => n.name === subclass.id),
            newNode = {
            ...foundNode,
            constraints,
            displayName: cleanupName(foundNode.name),
            edge,
            selectedChildren,
            selected: true,
            isShared: node.isShared,
        };
        // this.getSubTree(newNode);
        // using the old class tree instead of the subclass tree
        newNode.classTree = node.classTree;
        extendProperties(newNode);

        for (let prop of node.dataProperties) {
            let subProp = foundNode.dataProperties.find(p => p.name === prop.name);
            if (subProp) {
                subProp.selected = prop.selected;
            }
            prop.selected = false;
        }

        if (this.selectTree === node) {
            this.selectTree = newNode;
        } else {
            let parent = edge.vizsource,
                parentSelChildren = parent.selectedChildren;

            edge.viztarget = newNode;
            parentSelChildren.splice(parentSelChildren.indexOf(node), 1);
            parentSelChildren.push(newNode);
        }

        for (let child of selectedChildren) {
            child.edge.vizsource = newNode;
        }

        if (this.currentNode === node) {
            this.currentNode = newNode;
        }

        this.constructGraph();
    }

    layoutGraph() {
        let elements = this.$element.find('.path-node');
        this.dg = new dagre.graphlib.Graph()

        for (let element of elements) {
            let e = jQuery(element);
            let box = {
                width  : Math.round(e.outerWidth()),
                height : Math.round(e.outerHeight()),
            };
            this.dg.setNode(e.attr('id'), box);
        }

        let margin = 20;
        this.dg.setGraph({ nodesep: 30, ranksep: 150, marginx: margin, marginy: margin, rankdir: 'LR', align: 'UL' });
        this.dg.setDefaultEdgeLabel(() => { return {} });

        for (let connection of this.plumbInstance.getAllConnections()) {
            this.dg.setEdge(connection.sourceId, connection.targetId);
        }

        dagre.layout(this.dg);
        this.dg.nodes().forEach(n => {
            let node = this.dg.node(n);
            let top = Math.round(node.y-node.height/2) + 'px';
            let left = Math.round(node.x-node.width/2) + 'px';
            jQuery('#' + n).css({ left: left, top: top });
        });
        this.plumbInstance.repaintEverything();
        this.isRedrawing = false;

        // resize the container
        let height = jQuery('.path-elements')[0].scrollHeight + margin;
        jQuery('.path-elements').css({ height });
    }

    reset() {
        this.selectTree = this.currentNode = null;
        this.pathSet = false;
        this.jds = {
            name: '',
            description: '',
            editing: true,
        };
        this.hover.edges = [];
        this.hover.nodes = [];
        this.graphNodes = [];
        this.graphEdges = [];

        this.plumbInstance.deleteEveryEndpoint();
        // using this instead of ng-if because the angular draw cycle interferes with proper edge display
        jQuery('.path-elements').css({ height: 'auto', display: 'none' });
    }

    setCurrentNode(node) {
        this.currentNode.isCurrentlySelected = false;
        this.currentNode = node;
        this.currentNode.isCurrentlySelected = true;
        this.constructGraph();
    }

    setRootNode(node, isShared) {
        let n = this.pathTree.nodes.find(n => n.name === node.id);

        this.selectTree = {
            ...n,
            constraints: [],
            displayName: cleanupName(n.name),
            isShared,
            selected: true,
            selectedChildren: [],
        };
        this.currentNode = this.selectTree;
        this.currentNode.isCurrentlySelected = true;
        this.getSubTree(this.selectTree);
        extendProperties(this.selectTree);
        this.ndx = 0;

        this.constructGraph();
    }

    toggleClassTreePopup(flag, node, selectActions) {
        // debouncing
        let curTime = Date.now();
        if (curTime - this.lastClassTreePopupTime > DEBOUNCE) {
            if (node !== this.selectedClassTreeNode) {
                this.selectedClassTreeNode = node;
            }
            this.showClassTreePopup = flag;
            this.lastClassTreePopupTime = curTime;

            if (flag) {
                this.selectActions = selectActions;
            } else {
                this.selectActions = null;
            }
        }
    }

    toggleConstraintPopup(flag, node) {
        // debouncing
        let curTime = Date.now();
        if (curTime - this.lastConstraintPopupTime > DEBOUNCE) {
            if (node !== this.selectedConstraintNode) {
                this.selectedConstraintNode = node;
            }
            this.showConstraintPopup = flag;
            this.lastConstraintPopupTime = curTime;
        }
    }

    traverse(currentNode, topNode, level) {
        if (level <= MAX_LEVEL) {
            for (let objProperty of currentNode.objProperties) {
                let n = this.pathTree.nodes.find(n => n.name === objProperty.value),
                    newNode = {
                        ...n,
                        displayName: cleanupName(n.name),
                    };

                if (n && !isChild(currentNode, n.name)) {
                    let e = {
                        ...this.pathTree.edges.find(e => e.source === currentNode.name && e.target === n.name && e.name === objProperty.name),
                        selected: false,
                        vizsource: currentNode,
                        viztarget: newNode,
                    };

                    // new stuff
                    newNode.edge = e;
                    newNode.level = currentNode.level+1;
                    newNode.trackId = `${newNode.id}-${this.ndx++}`;
                    this.getSubTree(newNode);
                    this.graphEdges.push(e);
                    this.graphNodes.push(newNode);

                    this.traverse(newNode, topNode, level + 1);
                }
            }
        }
    }

    updateNode(node) {
        console.log('update node: ', node)
        if (node.isShared) {
            // // uncheck all data properties
            // for (let prop of node.dataProperties) {
            //     prop.selected = false;
            // }
        }
        this.isSimpleRedraw = true;
    }

    showDialog() {
        this.jds.policyData = [];
        this.jds.constraintData = {
            root_class: '',
            constraintList: [],
        };
        convertPathToPolicyData(this.selectTree, this.jds.policyData, []);
        convertPathToConstraints(this.selectTree, this.jds.constraintData.constraintList);
        if (this.selectTree !== null)
            this.jds.constraintData.root_class = this.selectTree.name

        // skip Save JDS dialog for now:
        this.onJdsSaved({ jds: this.jds });
        // let modal = this.$uibModal.open({
        //     component: 'saveForm',
        //     resolve: {
        //         jds: () => {
        //             return this.jds;
        //         },
        //     },
        // });
        //
        // modal.result.then(value => {
        //     this.jds.name = value.name;
        //     this.jds.description = value.description;
        //     this.jds.editing = false;
        //
        //     this.constructGraph(true);
        //     this.onJdsSaved({ jds: this.jds });
        // }, () => {
        //     this.jds.editing = true;
        //     this.constructGraph(false);
        //     this.onSaveCancelled();
        // });
    }
}

CdmGraph.$inject = ['$scope', '$element', '$uibModal'];

const component = {
    bindings: {
        classTree: '<',
        constraintsMap: '<',
        hideActions: '<',
        isEditingPath: '<',
        isReset: '<',
        onCancel: '&',
        onJdsSaved: '&',
        onSaveCancelled: '&',
        pathTree: '<',
        selectedJds: '<',
        showSaveDialog: '<',
    },
    controller: CdmGraph,
    templateUrl,
};

export default component;
