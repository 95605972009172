import * as authoritiesReducers from './authorities';
import * as dataRequesterReducers from './data-requesters';
import * as decisionReducers from './decisions';
import * as docIdsReducers from './docIds';
import * as evaluationTimeReducers from './evaluation-time';
import * as groupReducers from './groups';
import * as jdsReducers from './jds';
import * as mapReducers from './map';
import * as policyManagementReducers from './policies';
import * as policyReducers from './policy';

const reducers = {
    ...authoritiesReducers,
    ...dataRequesterReducers,
    ...decisionReducers,
    ...docIdsReducers,
    ...evaluationTimeReducers,
    ...groupReducers,
    ...jdsReducers,
    ...mapReducers,
    ...policyManagementReducers,
    ...policyReducers,
};

export default reducers;
