// libs
import angular from 'angular';
import routableTabs from '@brandeis/routable-tabs-ng';
// import { cdmComponents } from '@brandeis/prisms-cdm-explorer-ng';

// containers
import addConstraints from '../../containers/policy-editor/add-constraints';
import basicInfo from '../../containers/policy-editor/basic-info';
import cdmComponents from '../../containers/cdm';
import dataAndConstraints from '../../containers/policy-editor/data-and-constraints';
import review from '../../containers/policy-editor/review';
import selectDR from '../../containers/policy-editor/select-dr';
import summary from '../../containers/policy-editor/summary';

// view
import policyEditor from './policy-editor';

// run hook
import { wizardHook } from '@brandeis/routable-tabs-ng';

// tab steps
import { BASE_STEPS } from '../../constants';

export default angular
    .module('brandeis.ui.prisms.views.policyEditor', [
        routableTabs,
        cdmComponents,
        addConstraints,
        basicInfo,
        review,
        dataAndConstraints,
        selectDR,
        summary,
    ])
    .config([
        '$stateProvider',
        'wizardManagerProvider',
        ($stateProvider, wizardManagerProvider) => {
            let managerState = {
                name: 'pa.policy-editor',
                url: '/policy/editor',
                views: {
                    prismsMain: 'policyEditor',
                },
            };

            $stateProvider.state(managerState);

            wizardManagerProvider.addTabSet(
                'policyEditorTabContent',
                BASE_STEPS
            );
            wizardManagerProvider.setDefaultState({
                to: 'pa.policy-editor.basic-info',
                params: {
                    id: 'new',
                    focusSection: '',
                },
            });
        },
    ])
    .component('policyEditor', policyEditor)
    .run(wizardHook).name;
