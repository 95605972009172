import PrismsServer from './prisms-server';

function checkUserId() {
    return PrismsServer.username != null;
}

function NoUserException() {
    this.message = 'User not logged in';
    this.name = 'NoUserException';
}

export const PrismsLocalStorage = {
    set: (key, data) => {
        if (checkUserId()) {
            window.localStorage.setItem(`${PrismsServer.username}.${key}`, data);
        } else {
            throw new NoUserException();
        }
    },

    get: (key) => {
        if (checkUserId()) {
            return window.localStorage.getItem(`${PrismsServer.username}.${key}`);
        } else {
            throw new NoUserException();
        }
    },

    remove: (key) => {
        if (checkUserId()) {
            window.localStorage.removeItem(`${PrismsServer.username}.${key}`)
        } else {
            throw new NoUserException();
        }
    },

    clear: () => {
        if (checkUserId()) {
            window.localStorage.clear();
        } else {
            throw new NoUserException();
        }
    },
};