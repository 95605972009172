// actions

// template
import templateUrl from './summary.html';

class PolicySummary {
    constructor($ngRedux, $scope, wizardManager) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {})(this);

        $scope.$on('$destroy', unsubscribe);

        this.summaryClasses = {
            'visible': false,
            'policy-summary': true,
        };
        this.wizardManager = wizardManager;
    }

    mapStateToThis(state) {
        const {
            drFilters,
            allData,
            tagSelection,
            geoSelection,
            jdsData,
            policies,
            policy,
        } = state.prisms;

        const {
            router,
        } = state;

        let params = {
            ...router.currentParams,
        };

        return {
            editId: params.id,
            allData,
            tagSelection,
            jdsData,
            geoSelection,
            drFilters,
            policies,
            policy,
        };
    }

    toggleSummaryView() {
        this.summaryClasses.visible = !this.summaryClasses.visible;
    }
}

PolicySummary.$inject = ['$ngRedux', '$scope', 'wizardManager'];

const component = {
    templateUrl,
    controller: PolicySummary,
};

export default component;
