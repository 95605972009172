import templateUrl from './geo-selector.html';
import { GEO_REGIONS } from '../../../constants.js';
import regionMap from '../../../assets/images/regions.png';

class GeoSelector {

    constructor($scope) {
        this.$scope = $scope;

        this.regions = Array.from(GEO_REGIONS);
        this.regions.unshift({
            id: '0',
            displayName: 'All (Anywhere in the world; Unconstrained)',
            corners: [[0, 0], [0, 0]],
            checked: true,
            map_file: regionMap,
        })
        this.selectedRegion = this.regions[0];

        $scope.$watch(() => this.geoSelection, (newValue) => {
            console.log('WATCH geo selection: ', newValue)
            let currentRegion = this.regions[0];
            if (newValue)
                currentRegion = newValue;
            this.selectedRegion = currentRegion;
        });
    }

    updateRegion(region) {
        // using radio buttons from: https://stackoverflow.com/a/25457904/3816489
        this.selectedRegion = region;
        this.onRegionUpdated({newRegionSelected: region.id === '0' ? null : region});
    }
}

GeoSelector.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: GeoSelector,
    bindings: {
        geoSelection: '<',
        onRegionUpdated: '&',
    },
};

export default component;
