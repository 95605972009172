import * as actionTypes from '../actions/action-types';

export function currentGroup(state = {}, action) {
    switch (action.type) {
        case actionTypes.CLEAR_CURRENT_GROUP:
        case actionTypes.NEW_GROUP:
            return {};
        case actionTypes.EDIT_GROUP:
            return {
                ...action.group,
                groups: action.groups,
            };
        case actionTypes.CREATING_GROUP:
            delete action.group.errorMessage;
            delete action.group.status;
            return {
                ...action.group,
            };
        case actionTypes.FAILED_CREATE_GROUP:
            return {
                ...action.group,
                errorMessage: action.errorMessage,
                status: 'failed',
            };
        case actionTypes.SUCCESS_CREATE_GROUP:
            return {
                ...action.group,
                id: action.groupId,
                memberOf: [],
                parents: [],
                children: [],
                status: 'success',
            };
        default:
            return state;
    }
}

export function groups(state = {}, action) {
    switch (action.type) {
        case actionTypes.DELETING_GROUP:
            return {
                ...state,
                status: 'deleting',
                groupToDelete: action.group,
            };
        case actionTypes.FETCHING_GROUPS:
            return {
                ...state,
                status: 'fetching',
            };
        case actionTypes.LOGOUT:
            return {};
        case actionTypes.SUCCESS_GET_GROUPS:
            // turn map of groups into list for table:
            let groups = [],
                group;
            Object.keys(action.groupMap).forEach(function(key) {
                group = action.groupMap[key];
                group = {
                    ...group,
                    memberOfNames: group.memberOf.map(sID => action.groupMap[sID].nameOrAcronym).sort(),
                    childrenNames: group.children.map(cID => action.groupMap[cID].nameOrAcronym).sort(),
                };
                groups.push(group);
            });
            return {
                groups,
                userCanEdit: action.user.canEditGroups,
                status: 'success',
                timestamp: action.timestamp,
            };
        default:
            return state;
    }
}

export function isGroupsSupported(state = {}, action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_GROUPS_SUPPORTED:
            return action.isGroupsSupported;
        default:
            return state;
    }
}
