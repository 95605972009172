import templateUrl from './node.html';
import { cleanupName } from '../../../containers/cdm/functions';

function positionPopup(componentElement, parentElement, popupElement) {
	setTimeout(() => {
		let parentElem = componentElement.find(parentElement),
			popupElem = jQuery(popupElement),
			dim = parentElem.offset();

		popupElem.offset({
			top: dim.top,
			left: dim.left,
		});
	}, 0);
}

function setupDocumentOffClick(popupElement, node, callback) {
	let clickName = `click${popupElement}.${node.trackId}`;
	jQuery(document).on(clickName, event => {
		let target = jQuery(event.target);

		if (target.parents('#' + node.trackId).length === 0
			&& target.parents(popupElement).length === 0
			&& target.parents('[class*=" uib-"]').length === 0
			&& target.parents('[class^="uib-"]').length === 0) {
			callback(node);
			jQuery(document).off(clickName);
		}
	});
}

/* global jQuery */
class Node {
	constructor($scope, $element) {
		this.$scope = $scope;
		this.$element = $element;
		this.showAddPopup = false;
		this.showConstraintPopup = false;
		this.showClassTreePopup = false;

		this.classSelectorBtn = {
			'class-selector': true,
			'glyphicon': true,
			'glyphicon-triangle-right': true,
			'glyphicon-triangle-bottom': false,
		};

		this.isSharedBtn = {
			'constraint-toggle': true,
			'icon-eye-crossed': false,
			'icon-eye': true,
		};

		$scope.$watch(() => this.node.isShared, () => {
			this.isSharedBtn['icon-eye-crossed'] = !this.node.isShared;
			this.isSharedBtn['icon-eye'] = this.node.isShared;
		});

		this.selectActions = [{
				name: 'select class',
				callback: ($event, subclass) => {
					this.toggleClassTreePopup($event);
					this.subclassSelected({ node: this.node, subclass });
				},
			},
		];
	}

	cleanup(name) {
		return cleanupName(name);
	}

	getClass() {
		return {
			'path-node': true,
			'selected': this.node.selected,
			'is-shared': this.node.isShared,
			'is-currently-selected': this.node.isCurrentlySelected,
			'is-loop': this.node.isLoop,
		};
	}

	handleAddNode() {
		if (!this.node.selected) {
			this.addNode({ node: this.node, isShared: true });
			this.showAddPopup = false;
		}
	}

	handleMouseOver($event) {
		let target = jQuery($event.target);

		if (target.parents('.path-node').length > 0) {
			$event.preventDefault();
			this.onMouseover({ node: this.node });
		}
	}

	handleMouseOut($event) {
		if (!this.showAddPopup) {
			$event.preventDefault();
			this.onMouseout();
		}
	}

	handleNodeRemove($event) {
		$event.preventDefault();
		this.removeNode({ node: this.node });

		this.showClassTreePopup = false;
		this.showAddPopup = false;
		this.showConstraintPopup = false;
		this.resetClassSelectorBtn();
		this.onToggleConstraintPopup({ flag: false, node: this.node });
	}

	handleNodeSelect($event) {
		let target = jQuery($event.target);

		if (!this.node.noTraverse
			&& !target.hasClass('class-selector')
			&& target.parents('.select-actions, .nonselect-actions').length === 0
			&& target.parents('.node-properties').length === 0) {

			if (this.node.selected) {
				this.setCurrentNode({ node: this.node });
			} else {
				this.handleAddNode();
			}

			this.showClassTreePopup = false;
			this.showAddPopup = false;
			this.showConstraintPopup = false;
			this.resetClassSelectorBtn();
			this.onToggleConstraintPopup({ flag: false, node: this.node });
		}
	}

	handleSubclassSelection(subclass) {
		this.togglePopup();
		this.subclassSelected({ node: this.node, subclass });
	}

	hasConstraints() {
		let canShowConstraints = this.node.selected || this.node.isShared;

		return canShowConstraints
			&& (this.node.constraints.length > 0 || this.node.dataProperties.find(p => p.constraints.length > 0) != null);
	}

	resetClassSelectorBtn() {
		this.classSelectorBtn['glyphicon-triangle-right'] = !this.showClassTreePopup;
		this.classSelectorBtn['glyphicon-triangle-bottom'] = this.showClassTreePopup;
	}

	toggleAddPopup() {
		this.showAddPopup = !this.showAddPopup;
		this.showConstraintPopup = this.showClassTreePopup = false;
		this.resetClassSelectorBtn();
		this.onToggleClassTreePopup({ flag: false, node: this.node });
		this.onToggleConstraintPopup({ flag: false, node: this.node });

		if (this.showAddPopup) {
			setupDocumentOffClick('.add-popup', this.node, () => {
				this.showAddPopup = false;
				this.$scope.$apply();
			});
		} else {
			jQuery(document).off('click.add-popup.' + this.node.trackId);
		}
	}

	toggleClassTreePopup($event) {
		$event.preventDefault();
		if (this.node.classTree[0].children.length > 0) {
			this.showClassTreePopup = !this.showClassTreePopup;
			this.showAddPopup = this.showConstraintPopup = false;
			this.onToggleClassTreePopup({ flag: this.showClassTreePopup, node: this.node, selectActions: this.selectActions });
			this.onToggleConstraintPopup({ flag: false, node: this.node });
			this.resetClassSelectorBtn();

			if (this.showClassTreePopup) {
				// positioning, outside of the main thread
				positionPopup(this.$element, '.class-tree-btn', '.class-tree-popup');

				setupDocumentOffClick('.class-tree-popup', this.node, node => {
					this.showClassTreePopup = false;
					this.onToggleClassTreePopup({ flag: false, node });
					this.resetClassSelectorBtn();
					this.$scope.$apply();
				});
			} else {
				jQuery(document).off('click.class-tree-popup.' + this.node.trackId);
			}
		}
	}

	toggleConstraintPopup($event) {
		$event.preventDefault();
		this.showConstraintPopup = !this.showConstraintPopup;
		this.showAddPopup = this.showClassTreePopup = false;
		this.resetClassSelectorBtn();
		this.onToggleClassTreePopup({ flag: false, node: this.node });
		this.onToggleConstraintPopup({ flag: this.showConstraintPopup, node: this.node });

		if (this.showConstraintPopup) {
			// positioning, outside of the main thread
			positionPopup(this.$element, '.constraint-btn', '.constraint-popup');

			setupDocumentOffClick('.constraint-popup', this.node, node => {
				this.showConstraintPopup = false;
				this.onToggleConstraintPopup({ flag: false, node });
				this.resetClassSelectorBtn();
				this.$scope.$apply();
			});
		} else {
			jQuery(document).off('click.constraint-popup.' + this.node.trackId);
		}
	}

	toggleIsShared($event) {
		$event.preventDefault();
		this.node.isShared = !this.node.isShared;
		this.onToggleIsShared({ node: this.node });
	}
}

Node.$inject = ['$scope', '$element'];

const component = {
    bindings: {
		addNode: '&',
		classTree: '<',
		constraintsMap: '<',
        node: '<',
		onMouseover: '&',
		onMouseout: '&',
		onToggleClassTreePopup: '&',
		onToggleConstraintPopup: '&',
		onToggleIsShared: '&',
		removeNode: '&',
		setCurrentNode: '&',
		subclassSelected: '&',
    },
    controller: Node,
    templateUrl,
};

export default component;
