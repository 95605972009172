import * as actionTypes from './action-types';
import {
    stateGo,
} from 'redux-ui-router';
import PrismsServer from '../prisms-server';
import AuthServer from '../auth-server';
// import PrismsClient from '@brandeis/prisms-client-js';
import {
    setDocId,
} from './policies';

let datePoll;
let timeout = 299999;

function prismsRefresh() {
    return async (dep, dispatch) => {
        if (datePoll) {
            clearTimeout(datePoll);
        }
        let success = await PrismsServer.refresh();
        if (!success) {
            dispatch(stateGo('loggedOut'));
        } else {
            datePoll = setTimeout(() => dispatch(prismsRefresh()), timeout);
        }
    }
}

export function beginPolling() {
    return (dep, dispatch) => {
        dispatch(prismsRefresh());
    };
}

export function checkAuthenticated() {
    return async (dep, dispatch) => {
        if (PrismsServer.checkAuthenticated()) {
            dispatch({
                type: actionTypes.IS_AUTHENTICATED,
                isAuthenticated: true,
            });
        } else {
            dispatch({
                type: actionTypes.IS_AUTHENTICATED,
                isAuthenticated: false,
            });
            dispatch(stateGo('loggedOut'));
        }
    }
}

export function login(credentials) {
    return async ({
        wizardManager,
    }, dispatch) => {
        dispatch({
            type: actionTypes.AUTHENTICATING,
        });
        try {
            let user = await PrismsServer.authenticate(credentials); // this fills 'roles' and 'username' entries upon success!
            user.name = await AuthServer.displayname(credentials.username);

            PrismsServer.setUsername(user.username);
            const isPDC = user.username.startsWith('pdc#');
            const prismsInstance = await PrismsServer.getInstance();
            let docIdsFromServer = await prismsInstance.getDocIds();
            let docIds = docIdsFromServer.sort();
            user.docId = docIds.length > 0 ? docIds[0] : null; // TODO: chose first one as default
            if (docIds.indexOf('rimpac.flr') > -1 && user.username.startsWith('pdc#'))  //'pdc_ASEAN.flr'
                user.docId = 'rimpac.flr';  //'pdc_ASEAN.flr'; // hard-coding default use case for PDC users

            // obtain list of filter and action constraints:
            let filterPredicates = await prismsInstance.getFilterPredicates();
            let actionPredicates = await prismsInstance.getActionPredicates();

            // TODO: after demo: DO NOT set the system time here:
            // const sysTimeBody = new PrismsClient.SystemTimeParameter();
            // sysTimeBody.dateTimeString = (new Date()).toISOString();
            // await prismsInstance.setSystemTimeObject(sysTimeBody);

            dispatch({
                type: actionTypes.LOGIN_SUCCESS,
                isAuthenticated: true,
                timestamp: (new Date()).getTime(),
                user,
                docIds,
                filterPredicates,
                actionPredicates,
            });
            dispatch(setDocId(user.docId));
            wizardManager.resetTabsVisited('policyEditorTabContent');
            dispatch(beginPolling());
            dispatch({
                type: 'IS_PDC',
                isPDC,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.LOGIN_ERROR,
                error,
                isAuthenticated: false,
            });
        }
    };
}

export function logout() {
    return async (dep, dispatch) => {
        await PrismsServer.logout();

        dispatch({
            type: 'sri.brandeis.LOGOUT',
        });
        dispatch({
            type: actionTypes.LOGOUT,
        });
        // dispatch(stopPolling());
        dispatch(stateGo('loggedOut'));
    };
}

export function stopPolling() {
    return () => {
        clearTimeout(datePoll);
    };
}
