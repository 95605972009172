// actions
import {
    disablePolicy,
    enablePolicy,
    getPolicies,
    resetPolicyUpdated,
    setConflictDefault,
    setPrecedence,
} from '../../actions/policies';
import {
    deletePolicy,
} from '../../actions/policy';
import {
    stateGo,
} from 'redux-ui-router';

// template
import templateUrl from './policy-manager.html';

const SIZES = {
    normal: '',
    dashLarge: 'dash-large',
    dashSmall: 'dash-small',
};

const baseClass = 'policy-manager';

class PolicyManager {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                deletePolicy,
                disablePolicy,
                enablePolicy,
                getPolicies,
                resetPolicyUpdated,
                setConflictDefault,
                setPrecedence,
                stateGo,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            this.getPolicies();
            let displayClass = SIZES[this.displaySize] || SIZES.normal;
            this.containerClasses = [baseClass, displayClass, 'view']
        };

        $scope.$watch(() => this.policyUpdated, newValue => {
            /* global jQuery */
            if (newValue != null && newValue !== '') {
                jQuery(`#${newValue}`).addClass('highlight')
                    .one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', () => {
                        jQuery(`#${newValue}`).removeClass('highlight');
                        this.resetPolicyUpdated();
                    });
            }
        });
    }

    mapStateToThis(state) {
        const {
            policies,
            policyUpdated,
            systemTime,
            user,
        } = state.prisms;

        return {
            policies,
            policyUpdated,
            systemTime,
            user,
        };
    }

    addPolicyClicked() {
        this.stateGo('pa.policy-editor.basic-info', {
            id: 'new',
            focusSection: '',
        });
    }

    changePrecedence(policy, precedence) {
        this.setPrecedence(policy.id, precedence);
    }

    copyPolicyClicked(policy) {
        console.log('trying to copy policy: ', policy);
        this.stateGo('pa.policy-editor.basic-info', {
            id: 'new', //policy.id + '_COPY',
            focusSection: 'policy-name',
        });
    }

    deletePolicyClicked(policy) {
        this.deletePolicy(policy);
    }

    enableToggled(policy, enabled) {
        if (enabled) {
            this.enablePolicy(policy.id);
        } else {
            this.disablePolicy(policy.id);
        }
    }

    toggleConflictDefault(allowed) {
        this.setConflictDefault(allowed);
    }
}

PolicyManager.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicyManager,
    bindings: {
        displaySize: '@',
    },
};

export default component;
