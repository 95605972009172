import templateUrl from './basic-details.html';

class GroupDetails {
    constructor($scope) {
        this.$onInit = () => {
            this.modelOptions = {
                debounce: 200,
            };
            this.isNew = true; // TODO: set to false when opening this from the Edit Membership page!
        };

        $scope.onlyLetters = /^[a-zA-Z]+$/;

        this.$scope = $scope;
    }

    cancel() {
        this.onCancel();
    }

    createGroup(proceedToMembership) {
        if (this.group.id == null) {
            this.onCreateGroup({
                group: this.group,
                proceedToMembership,
            });
        } else {
            this.onUpdateGroup({
                group: this.group,
                proceedToMembership,
            })
        }
    }
}

GroupDetails.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: GroupDetails,
    bindings: {
        focusSection: '<',
        form: '<',
        group: '<',
        onCancel: '&',
        onCreateGroup: '&',
        onUpdateGroup: '&',
    },
};

export default component;
