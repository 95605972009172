import templateUrl from './filter-popup.html';

class FilterPopup {
    constructor() {
        this.allChecked = false;
    }

    cancel() {
        this.onCancel();
    }

    checkAllSelected() {
        let allSelected = true;

        for (let ndx = 0; ndx < this.popupData.checkList.length && allSelected; ndx++) {
            allSelected = this.popupData.checkList[ndx].checked;
        }

        this.allChecked = allSelected;
    }

    save() {
        let checkList = this.popupData.checkList;

        this.onSave({
            type: this.type,
            // inclusionType: inclusionType,
            checkList,
        });
    }

    selectAll() {
        for (let item of this.popupData.checkList) {
            item.checked = true;
        }
    }
}

const component = {
    bindings: {
        onCancel: '&',
        onSave: '&',
        popupData: '<',
        type: '<',
    },
    controller: FilterPopup,
    templateUrl,
};

export default component;