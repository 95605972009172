// libs
import angular from 'angular';

// components
import accessMatrix from '../../components/access-matrix/access-matrix';

// view
import accessMatrixView from './access-matrix';

export default angular.module('brandeis.ui.prisms.views.accessMatrix', [])
	.config(['$stateProvider', ($stateProvider) => {
        let testerState = {
                name: 'pa.policy-review',
                url: '/policy-review',
                views: {
                	prismsMain: 'accessMatrixView',
                },
            };

        $stateProvider
            .state(testerState);
	}])
    .component('accessMatrixView', accessMatrixView)
    .component('accessMatrix', accessMatrix)
    .name;
