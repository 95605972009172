import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import uiBSDatePicker from 'bootstrap-ui-datetime-picker';
import uiRouter from '@uirouter/angularjs';
import uiSlider from 'angularjs-slider';
import logoutPanel from '@brandeis/logout-panel-ng';
import datetimePicker from '@brandeis/datetime-picker-ng';
import 'angular-animate';
import 'angular-messages';
import 'animate.css/animate.min.css';
import 'lodash';
import 'angularjs-slider/dist/rzslider.css';

// components
import filterPopup from './components/filter-popup/filter-popup';
import headerBar from '@brandeis/header-bar-ng';
import alreadyExistsValidator from './components/already-exists-validator/already-exists-validator';
import sidebar from './components/sidebar';
import spinner from '@brandeis/spinner-ng';
import tagList from './components/tag-list/tag-list';

// generic components
// import datetimePicker from './components/datetime-picker/datetime-picker';
import tagSelector from './components/tag-selector/tag-selector';
import whiteSpaceValidator from './components/white-space-validator/white-space-validator';
import threeWayCheckbox from './components/three-way-checkbox/three-way-checkbox';

// views
import accessMatrixView from './views/access-matrix';
import dashboardView from './views/dashboard';
import groupEditorView from './views/group-editor';
import groupManagerView from './views/group-manager';
import policyEditorView from './views/policy-editor';
import policyManagerView from './views/policy-manager';
import policyTesterView from './views/policy-tester';

// main
import prismsMain from './prisms';

// service api's
import prismsApi from './prisms-server';
import authApi from './auth-server';

// reducers
import reducers from './reducers';

export const PrismsServer = prismsApi;
export const AuthServer = authApi;

export const prismsReducers = {
    ...reducers,
};

export const prismsComponents = angular.module('brandeis.ui.prisms', [
        'ngAnimate',
        'ngMessages',
        accessMatrixView,
        dashboardView,
        datetimePicker,
        groupEditorView,
        groupManagerView,
        headerBar,
        logoutPanel,
        policyEditorView,
        policyManagerView,
        policyTesterView,
        prismsMain,
        sidebar,
        spinner,
        uiBootstrap,
        uiBSDatePicker,
        uiRouter,
        uiSlider,
    ])
    .config(['$stateProvider', ($stateProvider) => {
        let dashboardState = {
                name: 'pa.dashboard',
                url: '/dashboard',
                views: {
                    prismsMain: 'dashboard',
                },
            },
            groupState = {
                name: 'pa.group',
                url: '/group',
                abstract: true,
            },
            policyState = {
                name: 'pa.policy',
                url: '/policy',
                abstract: true,
            },
            prismsState = {
                name: 'pa',
                url: '/port-authority',
                component: 'prisms',
            };

        $stateProvider
            .state(dashboardState)
            .state(groupState)
            .state(policyState)
            .state(prismsState);
    }])
    .component('filterPopup', filterPopup)
    .component('tagList', tagList)
    .component('tagSelector', tagSelector)
    .component('threeWayCheckbox', threeWayCheckbox)
    .directive('alreadyExists', alreadyExistsValidator)
    .directive('whiteSpace', whiteSpaceValidator)
    .name;
