import * as actionTypes from './action-types';
import PrismsServer from '../prisms-server';
import {
    buildDRFiltersFromRequesters,
    isAllDataRequesters,
} from '../functions';

export function getRequesterAttributeInfos() {
    return async (dep, dispatch, getState) => {
        const {
            user,
        } = getState().prisms;

        dispatch({
            type: actionTypes.FETCHING_REQUESTER_ATTRIBUTES,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            const infos = await prismsInstance.getDRattributeInfos(user.docId);

            dispatch({
                type: actionTypes.SUCCESS_GET_REQUESTER_ATTRIBUTES,
                requesterAttributeInfos: infos,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_REQUESTER_ATTRIBUTES,
            });
        }
    }
}

export function addDRFilter(filter) {
    return {
        type: actionTypes.ADDED_DR_FILTER,
        filter,
    }
}

export function updateDRFilters(filters) {
    return {
        type: actionTypes.UPDATED_DR_FILTERS,
        filters,
    }
}

export function initDRFilters(requesters, infos) {
    let filters = buildDRFiltersFromRequesters(requesters, infos);
    return {
        type: actionTypes.INIT_DR_FILTERS,
        filters,
    }
}

export function checkIndividualsSupported(docId) {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.FETCHING_INDIVIDUALS,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            let individuals = await prismsInstance.getDataRequesters(docId);
            dispatch({
                type: actionTypes.SUCCESS_GET_INDIVIDUALS,
                individuals,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_INDIVIDUALS,
            });
        }
    }
}

export function getPreviewRequesters(requesterList) {
    return async (dep, dispatch, getState) => {
        const {
            user,
            hasIndividuals,
        } = getState().prisms;

        dispatch({
            type: actionTypes.FETCHING_PREVIEW_REQUESTERS,
        });

        if (!requesterList || requesterList.length === 0) {
            dispatch({
                type: actionTypes.FAILED_GET_PREVIEW_REQUESTERS,
                errorMessage: 'Cannot get preview for empty requester formula',
            });
        }

        try {
            const prismsInstance = await PrismsServer.getInstance();
            let requesters;
            if (hasIndividuals) {
                requesters = await prismsInstance.getRequestersFromAttributes(
                    user.docId, {body: requesterList});
                // turn individual names in pairs into structure for the attribute:
                requesters = requesters.map(p => {
                    return {
                        ...p,
                        value0: [{
                            type: 'po#isDR_individual',
                            argumentType: 'Individual',
                            argument: p.value0,
                            negated: false }],
                    }
                });
            } else {
                requesters = await prismsInstance.getBaseAttributeCombinations(
                    user.docId, {body: requesterList});
            }

            dispatch({
                type: actionTypes.SUCCESS_GET_PREVIEW_REQUESTERS,
                requesters,
                isAllDRs: isAllDataRequesters(requesterList),
            });
        } catch (err) {
            dispatch({
                type: actionTypes.FAILED_GET_PREVIEW_REQUESTERS,
            });
        }
    }

}
