import angular from 'angular';

import cdmGraph from './cdm-graph';
import components from '../../components/cdm';

export default angular.module('brandeis.ui.prisms.containers.policyEditor.cdm', [
        components,
    ])
    .component('cdmGraph', cdmGraph)
    .name;
