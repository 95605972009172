import * as actionTypes from '../actions/action-types';
import {
    cleanupName, displayCDMProperty,
} from '../functions';
import {
    ALL_DATA_REQUESTERS,
    ALL_DATA,
    BASE_STEPS,
} from '../constants';

export function policy(state = {}, action) {
    switch (action.type) {
        case actionTypes.COPY_POLICY:
            console.log('COPY policy: ', action);
        case actionTypes.EDIT_POLICY:
            if (action.policyForm && action.policyForm.policyData)
                // sort policy data by how it is displayed
                action.policyForm.policyData.sort((path1, path2) =>
                    path1.map(e => displayCDMProperty(e)).join(',')
                        .localeCompare(path2.map(e => displayCDMProperty(e)).join(',')));
            return {
                ...action.policyForm,
                isNew: action.type === actionTypes.COPY_POLICY,
                displayId: cleanupName(action.policyForm.id),
                duration: {
                    start: action.policyForm.startTimeString == null ? null : new Date(action.policyForm.startTimeString),
                    end: action.policyForm.endTimeString == null ? null : new Date(action.policyForm.endTimeString),
                },
            };
        case actionTypes.FAILED_ADD_POLICY:
            return {
                ...state,
                failed: {
                    message: action.errorMessage,
                },
            };
        case actionTypes.LOGOUT:
        case actionTypes.NEW_POLICY:
            let defaultPolicy = {
                requesters: ALL_DATA_REQUESTERS,
                decision: 'ALLOWED',
                isNew: true,
                policyData: ALL_DATA,
                filterConstraints: [],
                actionConstraints: [],
            };
            if (action.policyForm) {
                return {
                    ...action.policyForm,
                    ...defaultPolicy,
                };
            } else {
                return {
                    ...defaultPolicy,
                };
            }
        case actionTypes.SUCCESS_ADD_POLICY:
        case actionTypes.SUCCESS_UPDATE_POLICY:
            return {
                ...state,
                isNew: false,
            };
        case actionTypes.LOCAL_UPDATE_POLICY:
            return {
                ...state,
                ...action.policyForm,
            };
        default:
            return state;
    }
}

export function wizardSteps(state = [], action) {
    let newState, tab;

    switch (action.type) {
        case actionTypes.EDIT_POLICY:
            return BASE_STEPS.map(step => {
                return {
                    ...step,
                    canMoveToNext: true,
                    state: {
                        ...step.state,
                        params: {
                            ...step.state.params,
                            id: action.policyId,
                        },
                    },
                };
            });
        case actionTypes.EXPLORING_CDM:
            newState = [...state];
            tab = newState.find(t => t.id === 'data-and-constraints');
            tab.hideNavBtns = action.flag;
            tab.customButtons = action.customButtons;

            return newState;
        case actionTypes.LOGOUT:
        case actionTypes.NEW_POLICY:
            return BASE_STEPS.map((step, ndx) => {
                return {
                    ...step,
                    canMoveToNext: action.policyForm ? state[ndx].canMoveToNext : false,
                };
            });
        case actionTypes.SET_REVIEW_BUTTONS:
            newState = [...state];
            tab = newState.find(t => t.id === 'review');
            tab.customButtons = action.customButtons;

            return newState;
        case actionTypes.LOCAL_UPDATE_POLICY:
            newState = [...state];
            tab = newState.find(t => t.id === action.tabId);

            tab.canMoveToNext = action.isValid;

            return newState;
        default:
            return state;
    }
}
