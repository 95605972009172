import templateUrl from './review-basic-info.html';

class ReviewBasicInfo {
    constructor($filter) {
        this.dateFilter = $filter('date');
    }

    printDates() {
        let startStr = this.policy.duration.start ?
            `${this.dateFilter(this.policy.duration.start, 'd MMM yyyy HH:mm', 'UTC')} to ` :
            '';
        let endStr = this.policy.duration.end ?
            this.dateFilter(this.policy.duration.end, 'd MMM yyyy HH:mm', 'UTC') :
            'ongoing';

        return startStr + endStr;
    }
}

ReviewBasicInfo.$inject = ['$filter'];

const component = {
    templateUrl,
    controller: ReviewBasicInfo,
    bindings: {
        editId: '<',
        isSummary: '<',
        policy: '<',
    },
};

export default component;
