import templateUrl from './review-data-and-constraints.html';

import { BASE_STEPS } from '../../../constants';

class ReviewDataAndConstraints {
    constructor($uibModal) {
        this.$uibModal = $uibModal;

        this.sliderOptions = {
            showTicksValues: false,
            floor: 1,
            ceil: 9,
            disabled: true,
            translate: (value) => {
                switch (value) {
                    case 1:
                        return 'None';
                    case 3:
                        return 'Low';
                    case 5:
                        return 'Moderate';
                    case 7:
                        return 'High';
                    case 9:
                        return 'Very High';
                    default:
                        return '';
                }
            },
        };

        this.isAll = true;
        this.checkedTags = [];

        this.$onChanges = changesObj => {
            if (changesObj.tagSelection && changesObj.tagSelection.currentValue) {
                console.log('Tag selection: (changes): ', changesObj);
                let newValue = changesObj.tagSelection.currentValue;
                if (newValue.tagsNotNegated) {
                    this.isAll = newValue.tags.every(a => !a.checked);
                    this.checkedTags = newValue.tags
                        .filter(t => t.checked)
                        .sort((t1, t2) => t1.displayName.localeCompare(t2.displayName));
                }
            }
        };
    }

    checkVisited() {
        return this.wizardManager.isTabVisited(BASE_STEPS[2].state.to);
    }

    openJDSDialog($event) {
        $event.preventDefault();

        let modal = this.$uibModal.open({
            component: 'cdmGraphDialog',
            windowClass: 'cdm-graph-dialog',
            resolve: {
                classTree: () => {
                    return this.jdsClassTree;
                },
                constraintsMap: () => {
                    return this.jdsConstraintsMap;
                },
                jds: () => {
                    return 'selectedJds';
                },
                pathTree: () => {
                    return this.jdsPathTree;
                },
            },
        });

        modal.result.then(() => {
            // TODO: what here?
        }, () => {});
    }

}

ReviewDataAndConstraints.$inject = ['$uibModal'];

const component = {
    templateUrl,
    controller: ReviewDataAndConstraints,
    bindings: {
        allData: '<',
        jdsData: '<',
        tagSelection: '<',
        geoSelection: '<',
        editId: '<',
        isSummary: '<',
        jdsClassTree: '<',
        jdsConstraintsMap: '<',
        jdsPathTree: '<',
        policy: '<',
        selectedJds: '<',
        wizardManager: '<',
    },
};

export default component;
