import * as actionTypes from './action-types';
import PrismsServer from '../prisms-server';

export function getPolicyAuthorities() {
    return async (dep, dispatch, getState) => {
        const {
            user,
        } = getState().prisms;

        dispatch({
            type: actionTypes.FETCHING_AUTHORITIES,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            const policyAuthorities = await prismsInstance.getPolicyAuthorities(user.docId);

            dispatch({
                type: actionTypes.SUCCESS_GET_AUTHORITIES,
                policyAuthorities,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_AUTHORITIES,
            });
        }
    }
}
