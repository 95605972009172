import {
    ALL_DATA_REQUESTERS,
    ALL_DATA_REQUESTERS_ATTRIBUTE,
    ALL_DATA,
    MAX_NAME_LENGTH,
    PDC_PRODUCT_SUBJECT_DATA,
    STATUS_ENUM,
    GEO_REGIONS,
} from './constants';
import PrismsClient from '@brandeis/prisms-client-js';

export function isAllDataRequesters(requesters) {
    // requesters must be an array of an array of and array of length 1:
    if (!requesters || requesters.length !== 1) return false;
    let outerRequestersElement = requesters[0];
    if (!outerRequestersElement || outerRequestersElement.length !== 1) return false;

    let middleRequestersElement = requesters[0][0];
    if (!middleRequestersElement || middleRequestersElement.length !== 1) return false;

    // now compare inner elements:
    // only the properties of the constant one have to be present in the argument one!
    let constProps = Object.getOwnPropertyNames(ALL_DATA_REQUESTERS_ATTRIBUTE);
    for (let i = 0; i < constProps.length; i++) {
        let propName = constProps[i];
        if (ALL_DATA_REQUESTERS_ATTRIBUTE[propName] !== requesters[0][0][0][propName]) {
            return false;
        }
    }
    // If we made it this far, objects are considered equivalent
    return true;
}

export function isAllData(jdsObject) {
    if (!jdsObject ||
        !jdsObject.hasOwnProperty('policyData') ||
        !jdsObject.hasOwnProperty('filterConstraints') ||
        !jdsObject.hasOwnProperty('actionConstraints'))
        return false;

    if (jdsObject.filterConstraints.length > 0 || jdsObject.actionConstraints.length > 0)
        return false;

    // policyData must be one object inside two nested lists, inner object must contain:
    //   {
    //     class_name: 'ALL',
    //     name: 'ALL',
    //   };

    if (!jdsObject.policyData || jdsObject.policyData.length !== 1) return false;
    let innerList = jdsObject.policyData[0];
    if (!innerList || innerList.length !== 1) return false;

    // now compare inner elements of given against ALL_DATA:
    for (let propName of Object.getOwnPropertyNames(ALL_DATA[0][0])) {
        if (ALL_DATA[0][0][propName] !== innerList[0][propName]) {
            return false;
        }
    }
    return true;
}

export function buildRequestersFromDRFilters(filterData) {
    let requesterAttributes = [];
    if (filterData)
        for (let filter of filterData) {
            let row = [];
            for (let pill of filter.pills) {
                // map pill's list to inner list of requesters
                // NOTE: if pill is negated, need to unfold in inner lists of length 1!
                if (pill.negated) {
                    pill.checkList.forEach(a => {
                        let attribute = new PrismsClient.RequesterAttribute();
                        attribute.type = pill.attribute;
                        attribute.negated = true;
                        attribute.argument = a.actual;
                        row.push([attribute]);
                    });
                } else {
                    // ignore All pill == checkList is empty
                    if (pill.checkList.length > 0)
                        row.push(pill.checkList.map(a => {
                            let attribute = new PrismsClient.RequesterAttribute();
                            attribute.type = pill.attribute;
                            attribute.negated = false;
                            attribute.argument = a.actual;
                            return attribute
                        }));
                }
            }
            if (row.length > 0)
                requesterAttributes.push(row);
        }
    if (requesterAttributes.length === 0) {
        requesterAttributes = ALL_DATA_REQUESTERS;
    }
    return requesterAttributes;
}

export function sortPills(pills) {
    if (!pills)
        return null;
    return pills.sort((p1, p2) => {
        // sort first by category/type, then whether negated or not, finally checklist displayNames
        let result = p1.type.localeCompare(p2.type);
        if (result !== 0)
            return result;
        result = p1.negated - p2.negated;  // show positives before negated
        if (result !== 0)
            return result;
        return p1.checkList.map(i => i.displayName).join('').localeCompare(
            p2.checkList.map(i => i.displayName).join(''));
    });
}

export function buildDRFiltersFromRequesters(requesters, infos) {
    // NOTE: since we are filtering out the special attribute <isDR_type, policy#DataRequester, false>
    //       for the attribute selection display -- see action/reducer getRequesterAttributeInfos() --
    //       we should filter out any occurrence in a conjunction (middle list) and replace any
    //       disjunction (inner or outer list) with just ALL = empty DR filter

    if (!infos || infos.length === 0 || !requesters)
        return [];

    if (isAllDataRequesters(requesters))
        return [];

    // requester is of form [ [ [ {type:, argument:, negated:} ] ] ]
    // DR filters are of form [ {pills: [ pill ]} ]
    //           with each pill: {checkList: [ {actual: <requester.argument> === <info.actual>,
    //                                          displayName: <info.displayName>,
    //                                          checked: true,
    //                                          negated: <requester.negated>,
    //                                          status: POSITIVE or NEGATIVE depending on <requester.negated>} ],
    //                            attribute: <requester.type>,
    //                            type: <info.category>,
    //                            negated: <requester.negated> }
    // console.log('build DR filters: ', requesters, infos);
    let filters = [];
    for (let orElementR of requesters) {
        // if we encounter one OR-element in the outer list that is equal to ALL, then simply return empty list
        if (isAllDataRequesters([orElementR]))
            return [];
        let filterPills = [];
        for (let andElementR of orElementR) {
            if (isAllDataRequesters([[andElementR]])) {
                console.warn('drop AND-element of requesters as it matches ALL: ', andElementR);
                continue;
            }
            let foundPills = findPillsInInfos(andElementR, infos);
            switch (foundPills.length) {
                case 0:
                    console.error('could not find attributes ', andElementR);
                    continue;
                case 1:
                    filterPills.push(...foundPills);
                    break;
                default:
                    // TODO: implement spreading multiple OR pills with different <type,neg,category>
                    //   to the outer OR filters per distributive law...
                    console.warn('not yet implemented: spreading OR across all outer...', foundPills);
            }
        }
        filters.push({
            pills: sortPills(filterPills),
        });
    }
    // console.log('DR filters built: ', filters);
    return filters;
}

// attribute list: [ {type:, argument:, negated:} ]
// need to find each <type,argument> pair in infos
// then group all by the same <type,negated,category> as pills
// each pill: {checkList: [ {actual: <requester.argument> === <info.actual>,
//                           displayName: <info.displayName>,
//                           negated: <requester.negated>,
//                           status: POSITIVE or NEGATIVE depending on <requester.negated>} ],
//                           checked: true} ],
//             attribute: <requester.type>,
//             type: <info.category>,
//             negated: <requester.negated> }
// TODO: if any list element matches ALL attribute then remove because we cannot match to infos?
function findPillsInInfos(drAttributeList, infos) {
    if (!drAttributeList || drAttributeList.length === 0)
        return [];

    // go through attributes and find each in infos,
    // grouping into map: <type,neg,category> => pill
    let mapOfPills = new Map();
    for (let drAttribute of drAttributeList) {
        let [key, pill] = findAttributeInInfos(drAttribute, infos);
        if (!key) {
            console.error('could not find attribute in infos: ', drAttribute, infos);
            continue
        }
        if (!mapOfPills.has(key))
            mapOfPills.set(key, pill);
        else  // collate pills under each key:
            mapOfPills.get(key).checkList.push(...pill.checkList);
    }

    return Array.from(mapOfPills.values());
}

// helper function to match DR attribute to meta data in infos
// returns [key = <type,negated,category> triplet as string, value = pill] pair
function findAttributeInInfos(drAttribute, infos) {
    for (let info of infos.filter(i => i.attribute === drAttribute.type)) {
        // found possible match in type, now look through named arguments:
        for (const key in info.namedArgs) {
            let value = info.namedArgs[key];
            if (value === drAttribute.argument) {
                return [
                    [drAttribute.type, drAttribute.negated, info.category].join(','),  // key
                    {
                        checkList: new Array({
                            actual: drAttribute.argument,
                            checked: true,
                            status: drAttribute.negated ? STATUS_ENUM.NEGATIVE : STATUS_ENUM.POSITIVE,
                            negated: drAttribute.negated,
                            displayName: key,
                        }),
                        negated: drAttribute.negated,
                        attribute: drAttribute.type,
                        type: info.category,
                    }];  // value
            }
        }
    }
    return [];
}

// helper function for extracting tag and geo constraints:
// check that we have no more than 1 filter constraints, and that the subject path is Product.ID
function checkFilterConstraintsForPDCProduct(filterConstraints) {
    if (!filterConstraints || filterConstraints.length > 1)
        return false;

    if (filterConstraints.length === 1) {
        let subjectPathLists = filterConstraints[0].subjectPathLists;
        if (subjectPathLists.length !== 1 || subjectPathLists[0].length !== 1)
            return false;
        let subject = subjectPathLists[0][0];
        // now compare subject against PDC_PRODUCT_SUBJECT_DATA:
        for (let propName of Object.getOwnPropertyNames(PDC_PRODUCT_SUBJECT_DATA)) {
            if (PDC_PRODUCT_SUBJECT_DATA[propName] !== subject[propName])
                return false;
        }
    }
    return true;  // all checks passed
}

export function extractTagConstraints(filterConstraints, dataTags) {
    // this function tries to create a tagSelection object from the given filter constraints and data tags

    // signal ERROR if no data tags to match or other checks fail:
    if (!dataTags || dataTags.length === 0)
        return null;
    if (!checkFilterConstraintsForPDCProduct(filterConstraints))
        return null;

    let emptySelection = {
        tagsNotNegated: true,
        tags: dataTags.map(t => {
            return {
                tagName: t,
                displayName: cleanupName(t),
                checked: false,
            }}),
    };
    if (!filterConstraints || filterConstraints.length === 0)  // no constraints to test: done
        return emptySelection;

    // we have exactly one filter constraint: now extract tags (if any)
    if (filterConstraints[0].constraintFormula.length === 0) {
        console.warn('WARNING: could constraint formula (tag) be EMPTY here???', filterConstraints[0]);
        return null;  // TODO: can this happen?
    }

    // extract all inner lists that have object paths to Product.tag:
    let filteredFormula = filterConstraints[0].constraintFormula.map(innerList => {
        return innerList.filter(c => isTagConstraint(c, dataTags));
    })
    if (filteredFormula.length === 0 || filteredFormula.every(f => f.length === 0))
        return emptySelection;

    let tagsNotNegated = true;
    if (filteredFormula.length > 1) {
        // check that all inner are length === 1 and positive
        if (!filteredFormula.every(e => e.length === 1) || !filteredFormula.every(e => !e[0].negated))
            return null;
    } else {
        if (filteredFormula[0].length > 1) {
            // check all inner elements negated
            if (!filteredFormula[0].every(e => e.negated))
                return null;
            tagsNotNegated = false;
        } else {  // use inverse of negated value of first element:
            tagsNotNegated = !filteredFormula[0][0].negated;
        }
    }

    // return tag selection data structure:
    let tagsPresent = new Set(filteredFormula.flat().map(c => c.values[0].name));
    return  {
        tagsNotNegated,
        tags: dataTags.map(t => {
            return {
                tagName: t,
                displayName: cleanupName(t),
                checked: tagsPresent.has(t),
            }}),
    }
}

// helper function to check if constraint is a tag constraint:
function isTagConstraint(constraint, dataTags) {
    if (!constraint || !constraint.objectPathList || constraint.objectPathList.length !== 1)
        return false;
    let object = constraint.objectPathList[0];
    if (object.class_name !== 'pdc#Product' || object.name !== 'pdc#tag')
        return false;

    if (!constraint.predicate || constraint.predicate !== 'Equal')
        return false;

    if (!constraint.values || constraint.values.length !== 1)
        return false;

    if (constraint.values[0].valueType !== 'IndividualValue')
        return false;
    return !(!dataTags || dataTags.indexOf(constraint.values[0].name) === -1);
}

function matchPoint(corner, value) {
    if (corner.length !== 2 || !value.hasOwnProperty('latitude') || !value.hasOwnProperty('longitude'))
        return false;
    return corner[0] === value.latitude && corner[1] === value.longitude;
}
function matchCorners(corners, values) {
    if (corners.length !== 2 || values.length !== 2)
        return false;
    return matchPoint(corners[0], values[0]) && matchPoint(corners[1], values[1]);
}

export function extractGeoConstraints(filterConstraints) {
    // this function tries to create a region selection object from the given filter constraints and known regions

    if (!checkFilterConstraintsForPDCProduct(filterConstraints))
        return null;

    if (!filterConstraints || filterConstraints.length === 0)  // no constraints to test: done
        return null;

    let filteredFlatFormula = filterConstraints[0].constraintFormula
        .flat()
        .filter(c => isGeoConstraint(c));
    if (filteredFlatFormula.length === 0)
        return null;

    // go through each geo constraint and try to match to known REGIONS:
    for (let c of filteredFlatFormula) {
        for (let region of GEO_REGIONS) {
            if (matchCorners(region.corners, c.values)) {
                return region;
            }
        }
    }
    return null;
}

//helper function to check if constraint is a geo constraint:
function isGeoConstraint(constraint) {
    if (!constraint || !constraint.objectPathList || constraint.objectPathList.length !== 2)
        return false;
    let object1 = constraint.objectPathList[0];
    let object2 = constraint.objectPathList[1];
    if (object1.class_name !== 'pdc#Product' || object1.name !== 'pdc#hazard')
        return false;
    if (object2.class_name !== 'pdc#Hazard' || object2.name !== 'pdc#location')
        return false;

    if (!constraint.predicate || constraint.predicate !== 'WithinRectangularRegion')
        return false;

    if (!constraint.values || constraint.values.length !== 2)
        return false;

    return constraint.values.every(v => v.valueType === 'Geodetic2DValue');
}

function cornerToValue(corner) {
    return {
        valueType: 'Geodetic2DValue',
        latitude: corner[0],
        longitude: corner[1],
    }
}

export function buildFilterConstraintsFromSelections(tagSelection, geoSelection) {
    // console.log('build filter constraints: ', tagSelection, geoSelection)
    let formula = [[]];

    // (1) parse tags
    if (tagSelection) {
        if (!tagSelection.hasOwnProperty('tagsNotNegated') || !tagSelection.hasOwnProperty('tags')) {
            console.warn('Cannot parse given tag selection object: ', tagSelection);
            return [];
        }
        let checkedTags = tagSelection.tags.filter(t => t.checked);
        if (checkedTags.length > 0) {
            let constraintTemplate = {
                objectPathList: [{
                    class_name: 'pdc#Product',
                    name: 'pdc#tag',
                    value: '\\pdc#TagEnum',
                }],
                predicate: 'Equal',
            };
            if (tagSelection.tagsNotNegated) {
                // all checked tags are singleton inner lists
                formula = checkedTags.map(t => {
                    return [{
                        ...constraintTemplate,
                        negated: false,
                        values: [{
                            valueType: 'IndividualValue',
                            name: t.tagName,
                        }],
                    }];
                });
            } else {
                // one inner list with all checked tags
                formula = [checkedTags.map(t => {
                    return {
                        ...constraintTemplate,
                        negated: true,
                        values: [{
                            valueType: 'IndividualValue',
                            name: t.tagName,
                        }],
                    };
                })];
            }
        }
    }

    // (2) parse geo constraints
    if (geoSelection) {
        let geo_constraint = {
            objectPathList: [{
                class_name: 'pdc#Product',
                name: 'pdc#hazard',
                value: 'pdc#Hazard',
            }, {
                class_name: 'pdc#Hazard',
                name: 'pdc#location',
                value: 'pdc#Geodetic2DLocation',
            }],
            predicate: 'WithinRectangularRegion',
            negated: false,
            values: geoSelection.corners.map(corner => cornerToValue(corner)),
        };
        formula = formula.map(innerList => {
            innerList.push(geo_constraint);
            return innerList;
        })
    }

    if (formula.length === 0)
        return [];
    // console.log('built formula: ', formula)
    return [{
        constraintFormula: formula,
        subjectPathLists: [[PDC_PRODUCT_SUBJECT_DATA]],
    }];
}

export function displayPath(path) {
    return '['+
        path.map(pathElement => displayCDMProperty(pathElement))
            .join(' > ')+
        ']';
}

export function displayPathElement(property) {
    return displayCDMProperty(property);
}

export function constraintListToFormula(constraintList, rootClassName) {
    if (constraintList.length > 0)
        return [{
            subjectPathLists: [[{
                class_name: rootClassName,
                name: 'ID',
                value: 'ID',
            }]],
            constraintFormula: [constraintList]}];
    return [];
}

export function constraintFormulaToList(constraintData, rootClassName) {
    // console.log('formulas to list: ', constraintData, rootClassName)
    let result = [];
    for (let constraintDataItem of constraintData) {
        // check that subjectPathLists matches root class name etc.:
        if (constraintDataItem.hasOwnProperty('subjectPathLists') &&
            constraintDataItem.subjectPathLists.length === 1 &&
            constraintDataItem.subjectPathLists[0].length === 1 &&
            constraintDataItem.subjectPathLists[0][0].class_name === rootClassName &&
            constraintDataItem.hasOwnProperty('constraintFormula') &&
            constraintDataItem.constraintFormula.length === 1) {
            result = result.concat(constraintDataItem.constraintFormula[0]);
        } else {
            console.error('Could not convert constraintData element to list (using root): ',
                constraintDataItem,
                rootClassName);
        }
    }
    return result;
}

export function buildConstraints(filterConstraints, actionConstraints) {
    return {
        filters: {
            constraints: filterConstraints,
            displayList: createConstraintsListForDisplay(filterConstraints),
        },
        actions: {
            constraints: actionConstraints,
            displayList: createConstraintsListForDisplay(actionConstraints),
        },
    };
}

export function createConstraintsListForDisplay(constraints) {
    let constraintsList = [];
    if (constraints.length > 0) {
        constraintsList = constraints.map(element => {
            return displayConstraintElement(element)
        });
    }
    return constraintsList;
}

export function displayConstraintElement(element) {
    let subjects = element.subjectPathLists
        .map(path => displayPath(path))
        .join(' or ');
    let displayFormula = element.constraintFormula
        .map(constraintList => constraintList
            .map(constraint => displayConstraint(constraint))
            .join(' and '))
        .join(' or ');
    return 'Requests for any data from these paths: '+subjects+
        ' must satisfy this constraint formula: '+displayFormula
}

export function displayConstraint(constraint) {
    let negated = constraint.negated ? 'NOT ' : '';
    let objectPath = displayPath(constraint.objectPathList);
    return '('+negated+objectPath+' '+constraint.predicate+' '+
        constraint.values.map(v => displayValue(v)).join(', ')+')';
}

export function displayValue(value) {
    let result = '{';
    let properties = Object.getOwnPropertyNames(value);
    result += properties.filter(p => p !== 'valueType')
        .map(propName => propName + ': ' + cleanupName('' + value[propName]))
        .join(', ');
    result += '}';
    return result;
}

export function calcNameOrAcronym(name, acronym) {
    return name.length > MAX_NAME_LENGTH ? acronym : name;
}

export function cleanupName(name) {
    let result = name;
    if (name.indexOf('#') > -1)
        result = name.split('#').pop();
    return result
        .replace(/([a-z])([A-Z])/g, '$1 $2')  // de-CamelCase
        .replace(/__/g, '-')
        .replace(/_/g, ' ');                  // replace underscore with space
}
export function createId(name) {
    // try to reverse `cleanupName` above:
    return name
        .replace(/ /g, '_')
        .replace(/-/g, '__')
}

export function displayPA(PA) {
    if (!PA) return '';
    if (PA.indexOf('#') >= 0)
        PA = PA.split('#')[1]; // remove namespace;
    return PA
        .replace(new RegExp('PA$'), '') // remove "PA" postfix, if any
        .replace(new RegExp('_$'), ''); // remove trailing "_", if any
    // TODO: convert acronym into full name using async call (see getPolicies())
}

export function displayCDMProperty(property) {
    let result = cleanupName(property.class_name);
    if (property.name !== 'ID')
        result += '.' + cleanupName(property.name);
    return result;
}

export function sortPolicyData(policyData) {
    return policyData.sort(comparePaths);
}

function comparePaths(path1, path2) {
    return path1.map(p => displayCDMProperty(p)).join('')
        .localeCompare(path2.map(p => displayCDMProperty(p)).join(''));
}

function compareConstraint(c1, c2) {
    // sort in this order:
    // - Constraint.negated -> ignore for now (we are not dealing with negated constraints in policies)
    // - Constraint.objectPathList: [CDMProperty]
    // - Constraint.predicate: <str>
    // - Constraint.values: [<abstract ConstraintValue>]
    let result = comparePaths(c1.objectPathList, c2.objectPathList);
    if (result !== 0)
        return result;
    result = c1.predicate.localeCompare(c2.predicate)
    if (result !== 0)
        return result;
    return c1.values.map(v => displayValue(v)).join('')
        .localeCompare(c2.values.map(v => displayValue(v)).join(''));
}

function sortConstraintFormula(constraintFormula) {
    return constraintFormula.sort((constraintList1, constraintList2) => {
        return constraintList1.sort(compareConstraint) < constraintList2.sort(compareConstraint);
    });
}

export function sortConstraints(constraintData) {
    return constraintData.sort((cd1, cd2) => {
        // first, compare subjectPathLists: [[CDMProperty]]
        let sortedSPL1 = sortPolicyData(cd1.subjectPathLists);
        let sortedSPL2 = sortPolicyData(cd2.subjectPathLists);
        for (let i = 0; i < sortedSPL1.length && i < sortedSPL2; i++) {
            let result = comparePaths(sortedSPL1[i], sortedSPL2[i]);
            if (result !== 0)
                return result;
        }
        // if we got here then the first part of the lists are the same:
        if (sortedSPL1.length !== sortedSPL2.length) {
            // but one list is longer:
            return sortedSPL1.length - sortedSPL2.length;
        }
        // second, sort by constraintFormula: [[Constraint]]
        let sortedCF1 = sortConstraintFormula(cd1.constraintFormula);
        let sortedCF2 = sortConstraintFormula(cd2.constraintFormula);
        for (let i = 0; i < sortedCF1.length && i < sortedCF2; i++) {
            let result = compareConstraint(sortedCF1[i], sortedCF2[i]);
            if (result !== 0)
                return result;
        }
        // if we got here then the first part of the lists are the same:
        if (sortedCF1.length !== sortedCF2.length) {
            // but one list is longer:
            return sortedCF1.length - sortedCF2.length;
        }
        return 0;
    });
}

export function constructTagString(tag, delimiter = ', ') {
    return tag.type + ': ' +
        (tag.checkList.length > 0 ?
            tag.checkList.map(c => c.displayName).join(delimiter) :
            'All');
}

export function dashify(string) {
    return string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace('#', '-')
        .toLowerCase();
}

export function splitCamelCase(string) {
    return string
        .replace(/^([A-Z]+)([A-Z])([a-z])/g, '$1 $2$3')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, (str) => str.toUpperCase()).split(' ');
}

// more ugly hard-coding of geo regions:
export function getRegionForCoordinates(values) {
    // values must be an array of length 2 of objects like {v.latitude, v.longitude}
    // and refer to NW and SE corners of region (in that order)

    // try to match given corners against known (hard-coded) regions:
    for (let region of GEO_REGIONS) {
        if (matchCorners(region.corners, values)) {
            return region;
        }
    }
    return {};
}
