// actions
import {
    getGroupHierarchy,
    updateGroup,
} from '../../actions/groups';
import {
    stateGo,
} from 'redux-ui-router';

// template
import templateUrl from './member-selection.html';

class GroupMembers {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                getGroupHierarchy,
                updateGroup,
                stateGo,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            this.getGroupHierarchy();
        };
    }

    mapStateToThis(state) {
        const {
            currentGroup,
            groups,
        } = state.prisms;

        const {
            router,
        } = state;

        let tabId = router.currentState.name.split('.').pop();
        let params = {
            ...router.currentParams,
        };

        return {
            currentGroup,
            focusSection: params.focusSection,
            groups,
            tabId,
        };
    }

    onCancel() {
        this.stateGo('pa.group-manager', {
            forceUpdate: true,
        });
    }

    onSaveMembers(groupID, childIDs, parentIDs) {
        this.updateGroup(groupID, childIDs, parentIDs);
    }
}

GroupMembers.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: GroupMembers,
};

export default component;