import templateUrl from './constraint-list.html';
import { cleanupName } from '../../../containers/cdm/functions';

class ConstraintList {
	constructor() {}

	cleanup(name) {
		return cleanupName(name);
	}

    constraintClass(constraint) {
        return {
            selected: constraint === this.selectedConstraint,
        };
    }

	removeConstraint(nodeOrProperty, constraint) {
		let constraints = nodeOrProperty.constraints;

		constraints.splice(constraints.indexOf(constraint), 1);
	}

    selectConstraint($event, constraint) {
        $event.preventDefault();
        this.selectedConstraint = constraint;
		if (this.onSelectConstraint) {
        	this.onSelectConstraint({ constraint });
		}
    }
}

const component = {
    bindings: {
        constraints: '<',
        node: '<',
        selectable: '<',
        onSelectConstraint: '&',
    },
    controller: ConstraintList,
    templateUrl,
};

export default component;
