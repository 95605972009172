// libs
import angular from 'angular';

// components
import jdsSelector from '../../../components/policy-editor/data-and-constraints/jds-selector';
import pdcSelector from '../../../components/policy-editor/data-and-constraints/pdc-selector';
import geoSelector from '../../../components/policy-editor/data-and-constraints/geo-selector';

// container
import dataAndConstraints from './data-and-constraints';

export default angular.module('brandeis.ui.prisms.containers.policyEditor.dataAndConstraints', [])
	.config(['$stateProvider', ($stateProvider) => {
        let dataAndConstraintsState = {
                name: 'pa.policy-editor.data-and-constraints',
                url: '/{id}/data-and-constraints/{focusSection}',
                views: {
                    policyEditorTabContent: 'dataAndConstraints',
                },
				data: {
					isWizardStep: true,
				},
            };

        $stateProvider
            .state(dataAndConstraintsState);
	}])
    .component('dataAndConstraints', dataAndConstraints)
    .component('jdsSelector', jdsSelector)
    .component('pdcSelector', pdcSelector)
    .component('geoSelector', geoSelector)
    .name;
