import templateUrl from './results-card.html';

const component = {
    templateUrl,
    bindings: {
        decisions: '<',
    },
};

export default component;
