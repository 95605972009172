import templateUrl from './save-form.html';

class SaveForm {
    constructor() {
        this.$onInit = () => {
            this.jds = {
                // ...this.resolve.jds,
                name: this.resolve.jds.name,
                description: this.resolve.jds.description,
            };

            this.isNew = this.jds.name === '' && this.jds.description === '';
        };
    }

    cancel() {
        this.dismiss({ $value: 'cancel' });
    }

    save() {
        this.close({ $value: this.jds });
    }
}

const component = {
    templateUrl,
    controller: SaveForm,
    bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<',
    },
};

export default component;
