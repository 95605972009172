import { checkAuthenticated } from '../src/actions/authentication';
import { stateGo } from 'redux-ui-router';
import templateUrl from './demo.html';

class PDC {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                checkAuthenticated,
                stateGo,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$scope = $scope;
        this.prevState = $ngRedux.getState();

        this.checkAuthenticated();
    }

    mapStateToThis(state) {
        const {
            isAuthenticated,
            router,
            user,
        } = state;

        return {
            isAuthenticated,
            router,
            user,
        };
    }
}

PDC.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PDC,
};

export default component;
