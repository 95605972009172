import * as actionTypes from './action-types';
import PrismsServer from '../prisms-server';
import PrismsClient from '@brandeis/prisms-client-js';
import {getPolicyAuthorities} from './authorities';
import {
    checkIndividualsSupported,
    getRequesterAttributeInfos,
} from './data-requesters';
import {
    checkGroupsSupported,
    getGroupHierarchy,
} from './groups';
import {
    checkTagSupported,
} from './jds';
import {stateGo} from 'redux-ui-router';

export function disablePolicy(policyId) {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.DISABLING_POLICY,
            policyId,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.disablePolicy(policyId);

            dispatch({
                type: actionTypes.SUCCESS_DISABLE_POLICY,
                policyId,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_DISABLE_POLICY,
                policyId,
            });
        }
    };
}

export function enablePolicy(policyId) {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.ENABLING_POLICY,
            policyId,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.enablePolicy(policyId);

            dispatch({
                type: actionTypes.SUCCESS_ENABLE_POLICY,
                policyId,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_ENABLE_POLICY,
                policyId,
            });
        }
    };
}

export function getAccessMatrix(formula) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.FETCHING_ACCESS_MATRIX,
        });

        try {
            // first set the date
            const {
                user,
            } = getState().prisms;
            const prismsInstance = await PrismsServer.getInstance();

            const body = new PrismsClient.PolicyRequest();
            // if user has role 'Policy Authority' use Flora name here, otherwise empty list:
            body.policyAuthorities = user.roles.indexOf('Policy Authority') === -1 ? [] : [user.username];
            body.dataRequesters = [];
            body.operations = ['READ'];
            body.docId = user.docId;
            body.requestFormula = formula;
            body.solutionType = 'ATTRIBUTES';

            let response_text = await prismsInstance.getAccessMatrixWithHttpInfo(body)
                .then(function(response_and_data) {
                    return response_and_data.response.text;
                });
            const accessMatrix = JSON.parse(response_text);

            dispatch({
                type: actionTypes.SUCCESS_GET_ACCESS_MATRIX,
                accessMatrix: accessMatrix.matrix,
                conflictDefault: accessMatrix.conflict_default,
            });
        } catch (err) {
            dispatch({
                type: actionTypes.FAILED_GET_ACCESS_MATRIX,
            });
        }
    }
}

export function getPolicies() {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.FETCHING_POLICIES,
        });

        try {
            const {
                user,
            } = getState().prisms;
            const prismsInstance = await PrismsServer.getInstance();
            // policy constraints contain polymorphic class instances, so need to get JSON from HTTP response:
            let response_text = await prismsInstance.getPoliciesFromWithHttpInfo(user.docId)
                .then(function(response_and_data) {
                    return response_and_data.response.text;
                });
            const policies = JSON.parse(response_text);

            let conflictDefault = await prismsInstance.getConflictDefault(user.docId);

            // TODO: (maybe) need to get PA name for each (filtered) policy asynchronously and then add to result
            //   for dispatching success in getting policies...
            // let name;
            // for (let i = 0; i < filteredPolicies.length; i++) {
            //     // wait for the promise to resolve before advancing the for loop
            //     name = await prismsInstance.getNameFor(filteredPolicies[i].policyAuthority, filteredPolicies[i].docId);
            //     console.log('got '+name+' for PA='+filteredPolicies[i].policyAuthority);
            // }

            dispatch({
                type: actionTypes.SUCCESS_GET_POLICIES,
                policies: policies,
                ifConflictThenDisallow: (conflictDefault === 'DISALLOWED'),
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_POLICIES,
            });
        }
    }
}

export function resetPolicyUpdated() {
    return {
        type: actionTypes.RESET_POLICY_UPDATED,
    };
}

export function resetResults() {
    return {
        type: actionTypes.RESET_TEST_RESULTS,
    }
}

export function selectPolicy(policy) {
    return {
        type: actionTypes.SELECT_POLICY,
        policy,
    };
}

export function setConflictDefault(allowed) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.SETTING_CONFLICT_DEFAULT,
        });

        try {
            const {
                user,
            } = getState().prisms;
            const prismsInstance = await PrismsServer.getInstance();
            prismsInstance.setConflictDefault(
                allowed ? 'ALLOWED' : 'DISALLOWED',
                user.docId);
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_SET_CONFLICT_DEFAULT,
            });
        }
    }
}

export function setDocId(docId) {
    return (dep, dispatch) => {
        dispatch({
            type: actionTypes.SETTING_DOC_ID,
            docId,
        });

        dispatch(checkGroupsSupported(docId));
        dispatch(checkTagSupported(docId));
        dispatch(checkIndividualsSupported(docId));
        dispatch(getPolicies());  // TODO: is this called automatically when switching state to policy manager (below)?
        dispatch(getGroupHierarchy(true));
        dispatch(getPolicyAuthorities());
        dispatch(getRequesterAttributeInfos());

        dispatch(stateGo('pa.policy-manager'));
    }
}

export function setPrecedence(policyId, precedence) {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.SETTING_PRECEDENCE,
            policyId,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.setPriority(policyId, {
                'body': `${precedence}`,
            });

            dispatch({
                type: actionTypes.SUCCESS_SET_PRECEDENCE,
                policyId,
                priority: precedence,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_SET_PRECEDENCE,
                policyId,
            });
        }
    };
}

export function testCombinedPolicies(date, formula) {
    return async (obj, dispatch, getState) => {
        dispatch({
            type: actionTypes.FETCHING_COMBINED_POLICY_TEST,
        });

        try {
            // first set the date
            const { user } = getState().prisms;
            const originalDate = getState().prisms.systemTime;
            const sysTimeBody = new PrismsClient.SystemTimeParameter();
            sysTimeBody.dateTimeString = date.toISOString();
            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.setSystemTimeObject(sysTimeBody);

            // then make the request
            const body = new PrismsClient.PolicyRequest();
            body.operations = ['READ'];
            // body.dataRequester = perspective;
            body.docId = user.docId;
            body.requestFormula = formula;
            // if user has role 'Policy Authority' use Flora name here, otherwise TODO: is empty list the right choice?
            body.policyAuthorities = user.roles.indexOf('Policy Authority') === -1 ? [] : [user.username];

            const results = await prismsInstance.policyRequest(body);

            dispatch({
                type: actionTypes.SUCCESS_GET_COMBINED_POLICY_TEST,
                results,
            });

            // now reset the date
            sysTimeBody.dateTimeString = originalDate.toISOString();
            await prismsInstance.setSystemTimeObject(sysTimeBody);
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_COMBINED_POLICY_TEST,
            });
        }
    }
}

export function testIndividualPolicy(policy, date) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.FETCHING_INDIVIDUAL_POLICY_TEST,
        });

        try {
            // first set the date
            const {
                user,
            } = getState().prisms;
            const originalDate = getState().prisms.systemTime;
            const sysTimeBody = new PrismsClient.SystemTimeParameter();
            sysTimeBody.dateTimeString = date.toISOString();
            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.setSystemTimeObject(sysTimeBody);

            // then make the request
            const body = new PrismsClient.PolicyRequest();
            body.operations = ['READ'];
            // body.dataRequester = perspective;
            body.docId = user.docId;
            body.requestFormula = policy.policyData;
            // if user has role 'Policy Authority' use Flora name here, otherwise TODO: is empty list the right choice?
            body.policyAuthorities = user.roles.indexOf('Policy Authority') === -1 ? [] : [user.username];

            const results = await prismsInstance.isolatedSinglePolicyRequest(body, policy.id);

            dispatch({
                type: actionTypes.SUCCESS_GET_INDIVIDUAL_POLICY_TEST,
                results,
            });

            // now reset the date
            sysTimeBody.dateTimeString = originalDate.toISOString();
            await prismsInstance.setSystemTimeObject(sysTimeBody);
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_INDIVIDUAL_POLICY_TEST,
            });
        }
    }
}
