import * as actionTypes from './action-types';
import PrismsServer from '../prisms-server';
import PrismsClient from '@brandeis/prisms-client-js';
import {
    stateGo,
} from 'redux-ui-router';
import {
    calcNameOrAcronym,
} from '../functions';

const GROUP_TIMEOUT = 180000; // 3 minutes

export function clearCurrentGroup() {
    return {
        type: actionTypes.CLEAR_CURRENT_GROUP,
    };
}

export function createGroup(group) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.CREATING_GROUP,
            group,
        });

        try {
            const {
                user,
            } = getState().prisms;

            const prismsInstance = await PrismsServer.getInstance();
            const body = new PrismsClient.PDCGroupData();
            body.name = group.name;
            body.acronym = group.acronym;
            body.description = group.description;
            body.baseGroup = group.baseGroup === true; // force it to have a boolean value

            const groupId = await prismsInstance.addGroup(body, user.docId);
            group = {
                ...group,
                nameOrAcronym: calcNameOrAcronym(group.name, group.acronym),
            };

            dispatch({
                type: actionTypes.SUCCESS_CREATE_GROUP,
                group,
                groupId, // this is important to keep if user edits new group by adding parents and/or children in next step!
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_CREATE_GROUP,
                group,
                errorMessage: error.response.text,
            });
        }
    }
}

export function deleteGroup(group) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.DELETING_GROUP,
            group,
        });

        try {
            const {
                user,
            } = getState().prisms;

            const body = new PrismsClient.PDCGroupData();
            body.id = group.id;

            const prismsInstance = await PrismsServer.getInstance();
            await prismsInstance.deleteGroup(body, user.docId);

            dispatch({
                type: actionTypes.SUCCESS_DELETE_GROUP,
                group,
            });

            dispatch(getGroupHierarchy(true));
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_DELETE_GROUP,
                errorMessage: error.response.text,
            });
        }
    }
}

export function updateGroup(groupID, childIDs, parentIDs) {
    return async (dep, dispatch, getState) => {
        dispatch({
            type: actionTypes.UPDATING_GROUP,
            groupID,
        });

        try {
            const {
                user,
            } = getState().prisms;

            const body = new PrismsClient.PDCGroupData();
            body.id = groupID;
            body.children = childIDs;
            body.parents = parentIDs;

            const prismsInstance = await PrismsServer.getInstance();
            let updatedGroupID = await prismsInstance.editGroup(body, user.docId);

            dispatch({
                type: actionTypes.SUCCESS_UPDATE_GROUP,
                updatedGroupID,
            });

            dispatch(stateGo('pa.group-manager', {
                forceUpdate: true,
            }));
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_UPDATE_GROUP,
                errorMessage: error.response.text,
            });
        }
    }
}

export function editGroup(groupId) {
    return async (dep, dispatch, getState) => {
        let {
            groups,
        } = getState().prisms;

        let group = groups.groups.find(g => g.id === parseInt(groupId));

        dispatch({
            type: actionTypes.EDIT_GROUP,
            group,
            groups,
        });
    }
}

export function newGroup() {
    return {
        type: actionTypes.NEW_GROUP,
    };
}

export function checkGroupsSupported(docId) {
    return async (dep, dispatch) => {
        dispatch({
            type: actionTypes.FETCHING_GROUPS_SUPPORTED,
        });

        try {
            const prismsInstance = await PrismsServer.getInstance();
            let result = await prismsInstance.searchCDM('pdc#Group', docId);
            let isGroupsSupported = false;
            if (result && result.length > 0)
                isGroupsSupported = true;
            dispatch({
                type: actionTypes.SUCCESS_GET_GROUPS_SUPPORTED,
                isGroupsSupported,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.FAILED_GET_GROUPS_SUPPORTED,
            });
        }
    }
}

export function getGroupHierarchy(forceUpdate = false) {
    return async (dep, dispatch, getState) => {
        const {
            user,
            groups,
        } = getState().prisms;

        const currentTime = Date.now();

        if (forceUpdate || !groups.hasOwnProperty('timestamp') || currentTime - groups.timestamp > GROUP_TIMEOUT) {
            dispatch({
                type: actionTypes.FETCHING_GROUPS,
            });

            try {
                const prismsInstance = await PrismsServer.getInstance();
                let groupMap = await prismsInstance.getGroups(user.docId);
                // add nameOrAcronym field to each group:
                let group;
                Object.keys(groupMap).forEach(function(key) {
                    group = groupMap[key];
                    group = {
                        ...group,
                        nameOrAcronym: calcNameOrAcronym(group.name, group.acronym),
                    };
                    groupMap[key] = group;
                });

                dispatch({
                    type: actionTypes.SUCCESS_GET_GROUPS,
                    groupMap,
                    user,
                    timestamp: currentTime,
                });
            } catch (error) {
                dispatch({
                    type: actionTypes.FAILED_GET_GROUPS,
                });
            }
        }
    }
}