// libs
import angular from 'angular';

// components
// import basicDetails from '../../../components/policy-editor/basic-info/basic-details';

// container
import addConstraints from './add-constraints';

export default angular.module('brandeis.ui.prisms.containers.policyEditor.addConstraints', [])
	.config(['$stateProvider', ($stateProvider) => {
        let addConstraintsState = {
                name: 'pa.policy-editor.add-constraints',
                url: '/{id}/add-constraints',
                views: {
                    policyEditorTabContent: 'addConstraints',
                },
				data: {
					isWizardStep: true,
				},
            };

        $stateProvider
            .state(addConstraintsState);
	}])
    .component('addConstraints', addConstraints)
    .name;
