import templateUrl from './constraint-parameter.html';

function getRandomId() {
    let randomNumbers = new Uint32Array(10);
    return window.crypto.getRandomValues(randomNumbers).join('');
}

class ConstraintParameter {
    constructor() {
        if (this.value == null) {
            switch (this.type) {
                case 'Geodetic2DValue':
                    this.value = {
                        latitude: null,
                        longitude: null,
                    };
                    this.latitudeId = getRandomId();
                    this.longitudeId = getRandomId();
                    break;
                case 'PathValue':
                    this.value = {
                        cdmPath: null,
                        propertyList: null,
                    };
                    break;
                default:
                    this.value = null;
                    break;
            }
        }
    }

    onDateSelected(date) {
        this.onChange({ value: date });
    }

    onDefaultChanged(value) {
        this.onChange({ value });
    }

    onGeoChanged() {
        if (this.value.latitude != null && this.value.longitude != null
                && this.value.latitude !== ''
                && this.value.longitude !== '') {
            this.onChange({ value: this.value });
        }
    }

    onPathChanged() {
        this.onChange({ value: this.value });
    }
}

const component = {
    templateUrl,
    controller: ConstraintParameter,
    bindings: {
        onChange: '&',
        type: '<',
        value: '<',
    },
};

export default component;
