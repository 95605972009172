import templateUrl from './dr-shared-list.html';
import {POLICY_NONE, POLICY_NONE_DISPLAY, sortArgument} from '../../../constants';

class DRSharedList {
    constructor($uibModal, $scope) {
        this.$uibModal = $uibModal;
        this.$scope = $scope;

        this.status = 'empty';
        this.tableClasses = {
            'preview-requester-content': true,
            'updating': false,
        };
        this.headers = [];
        this.rows = [];
        // local copies of input values:
        this.rawRows = [];
        this.attributeInfos = [];
        this.editedRow = -1;

        this.$onChanges = changesObj => {
            if (changesObj.previewRequesters) {
                let status = changesObj.previewRequesters.currentValue.status;
                this.tableClasses.updating = /fetching|updating/.test(status);

                if (changesObj.previewRequesters.currentValue.requesters) {
                    this.headers = changesObj.previewRequesters.currentValue.requesters.headers;
                    this.rawRows = changesObj.previewRequesters.currentValue.requesters.rows;
                }
            }

            if (changesObj.infos && changesObj.infos.currentValue && changesObj.infos.currentValue.status &&
                changesObj.infos.currentValue.status === 'success') {
                this.attributeInfos = changesObj.infos.currentValue.requesterAttributeInfos;
            }

            if (changesObj.highlight) {
                // only update display if not already done:
                this.editedRow = changesObj.highlight.currentValue;
            }

            this.updateDisplay()
        }
    }

    lookup(argument, listOfMappings) {
        if (argument === POLICY_NONE)
            return POLICY_NONE_DISPLAY;
        if (!listOfMappings)
            return null;
        for (let mapping of listOfMappings) {
            for (let [displayName, arg] of Object.entries(mapping))
                if (arg === argument)
                    return displayName;  // found first occurrence so return
        }
        return argument;  // nothing found so simply return search string
    }

    updateDisplay() {
        // match headers (if any) to infos
        let mask = new Array(this.headers.length).fill(true);
        if (this.headers.length > this.attributeInfos.length) {
            // must create different mask to filter columns:
            let categories = this.attributeInfos.map(i => i.category);
            mask = this.headers.map(h => categories.indexOf(h) >= 0);
            this.headers = this.headers.filter((h, i) => mask[i]);
        }
        // match entries in rows to the ones in attributes...
        let tempRows = [];
        if (this.attributeInfos.length > 0 && this.rawRows.length > 0) {
            tempRows = this.rawRows
                .map(row => {
                    return {
                        row: row.row
                            .map(cell => {
                                return {
                                    ...cell,
                                    displayName: this.lookup(
                                        cell.argument,
                                        this.attributeInfos
                                            .filter(i => i.category === cell.argumentType)
                                            .map(i => i.namedArgs)),
                                }
                            })
                            .filter((cell, index) => mask[index]),
                        highlight: row.indices.has(this.editedRow),
                        displayIndices: '[' + Array.from(row.indices).map(i => i + 1).join(', ') + ']',
                    }
                });
        }
        this.rows = tempRows
            .sort((r1, r2) => {
                // shouldn't happen, but just in case lengths differ:
                if (r1.row.length !== r2.row.length) {
                    console.warn('Trying to compare rows with different lengths! ', r1.row, r2.row);
                    return r1.row.length < r2.row.length;
                }
                // go through elements and compare each column:
                for (let i = 0; i < r1.row.length; i++) {
                    let result = sortArgument(
                        r1.row[i].argument, r1.row[i].displayName,
                        r2.row[i].argument, r2.row[i].displayName);
                    if (result !== 0)
                        return result;
                }
                return 0;
            });
    }
}

DRSharedList.$inject = ['$uibModal', '$scope'];

const component = {
    templateUrl,
    controller: DRSharedList,
    bindings: {
        previewRequesters: '<',
        infos: '<',
        highlight: '<',
        isEditing: '<',
    },
};

export default component;
