// libs
import angular from 'angular';

// components

// container
import summary from './summary';

export default angular.module('brandeis.ui.prisms.containers.policyEditor.summary', [])
    .component('summary', summary)
    .name;
