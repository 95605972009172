// libs
import angular from 'angular';

// containers
import groupEditorContainer from '../../containers/group-editor';

// view
import groupEditor from './group-editor';

export default angular.module('brandeis.ui.prisms.views.groupEditor', [
        groupEditorContainer,
    ])
    .config(['$stateProvider', ($stateProvider) => {
        let editorState = {
            name: 'pa.group-editor',
            url: '/group/editor',
            views: {
                prismsMain: 'groupEditor',
            },
        };

        $stateProvider
            .state(editorState);
    }])
    .component('groupEditor', groupEditor)
    .name;
