import templateUrl from './dr-filter-attrs.html';
import { STATUS_ENUM } from '../../../constants';
import {POLICY_NONE, POLICY_NONE_DISPLAY, sortArgument} from '../../../constants';


class DRFilterAttrs {
    constructor($scope) {
        this.$scope = $scope;

        this.accordionData = [];

        this.$onChanges = changesObj => {
            // console.log('DRFilterAttrs changes obj: ', changesObj);

            let shouldReset = false;
            if (changesObj.infos && changesObj.infos.currentValue &&
                changesObj.infos.currentValue.status === 'success') {
                shouldReset = true;
            }
            if (changesObj.groups && changesObj.groups.currentValue &&
                changesObj.groups.currentValue.userCanEdit && changesObj.groups.currentValue.status === 'success') {
                shouldReset = true;
            }
            if (changesObj.pills && changesObj.pills.currentValue) {
                this.setAccordion(changesObj.pills.currentValue);
                shouldReset = false; // because setting accordion above implies a prior reset
            }
            if (shouldReset)
                this.resetAccordion();
        };
    }

    resetAccordion() {
        let infos = [];
        if (this.infos && this.infos.status === 'success')
            infos = this.infos.requesterAttributeInfos;
        let groups = [];
        if (this.groups && this.groups.userCanEdit && this.groups.status === 'success')
            groups = this.groups.groups;
        this.accordionData = infos
            .filter(i => i.category !== 'Individual')  // for now, suppressing Individual category
            .map(i => {
                return {
                    ...i,
                    checkList: this.getList(i.namedArgs, i.category === 'Group' ? groups : []),
                    isAll: true,
                    btnClass: {
                        'btn': true,
                        'btn-primary': true,
                        'accordion': true,
                        'is-open': true,
                    },
                }
            })
            .sort((first, second) => first.category > second.category);
    }

    getList(namedArgs, groups) {
        let supergroups = groups
            .filter(g => !g.baseGroup)
            .map(g => 'pdc#' + g.acronym);

        return Object.entries(namedArgs).map(([key, value]) => {
            let display = value === POLICY_NONE ? POLICY_NONE_DISPLAY : key;
            return {
                status: STATUS_ENUM.UNCHECKED,
                displayName: display + (supergroups.indexOf(value) > -1 ? '*' : ''),
                actual: value,
            }
        }).sort((first, second) => sortArgument(
                first.actual, first.displayName,
                second.actual, second.displayName));
    }

    simulateLabelFor($event, accordionItem, attribute) {
        $event.preventDefault();
        let status = attribute.status === STATUS_ENUM.UNCHECKED ?
            STATUS_ENUM.POSITIVE : attribute.status === STATUS_ENUM.POSITIVE ?
            STATUS_ENUM.NEGATIVE : STATUS_ENUM.UNCHECKED;

        this.toggleAttribute(status, accordionItem, attribute);
    }

    toggleItem(accordionItem) {
        accordionItem.btnClass['is-open'] = !accordionItem.btnClass['is-open'];
    }

    toggleAll(accordionItem) {
        // clicking the All checkbox really means to UNCHECK all POSITIVE attributes under this item:
        // note that the checkbox for All is disabled when none is POSITIVE (i.e., it is checked),
        // so toggling it only happens accordionItem.isAll === true and we are moving from
        // an unchecked All checkbox to a checked All checkbox
        if (accordionItem.isAll) {
            let needUpdatePill = false;
            accordionItem.checkList.forEach(a => {
                if (a.status === STATUS_ENUM.POSITIVE) {
                    a.status = STATUS_ENUM.UNCHECKED;
                    needUpdatePill = true;
                }
            });
            if (needUpdatePill) {
                this.onRemovePositivePills({category: accordionItem.category});
            }
        }
    }

    toggleAttribute(status, accordionItem, attribute) {
        if (status === attribute.status)
            return;  // no change: we are done  TODO: remove this call from HTML?
        attribute.status = status;
        // noinspection FallThroughInSwitchStatementJS
        switch (status) {
            case STATUS_ENUM.UNCHECKED:
                this.onRemoveNegativePill({
                    category: accordionItem.category,
                    actual: attribute.actual,
                });
                break;
            case STATUS_ENUM.NEGATIVE:
                this.onAddNegativePill({
                    category: accordionItem.category,
                    pill: {
                        attribute: accordionItem.attribute,
                        checkList: [attribute],  // NEG pills only have one attribute in this list
                        negated: true,
                        type: accordionItem.category,
                    },
                });
                // NOTE: falling through the case statements as a newly toggled negative attribute previously was
                //  positive and thus needs updating (or removal) of the corresponding positive pill
            case STATUS_ENUM.POSITIVE:
                let positives = accordionItem.checkList
                    .filter(i => i.status === STATUS_ENUM.POSITIVE);
                this.onUpdatePositivePill({
                    category: accordionItem.category,
                    checkedList: positives,
                    attribute: accordionItem.attribute,
                });
                break;
            default:
                // should not happen!
                console.error('ERROR: Did not expect status of attribute = ', attribute.status);
                attribute.status = STATUS_ENUM.UNCHECKED;
        }
    }

    setAccordion(pills) {
        this.resetAccordion();
        // translate each pill (if any) into accordion settings:
        for (let pill of pills) {
            let accordionItem = this.accordionData.find(i => i.category === pill.type);
            if (!accordionItem) {
                console.log('ERROR: could not find category '+pill.type+' in current accordion: ', this.accordionData);
                continue
            }
            // go through accordion and check according to pills
            accordionItem.checkList.forEach(a => {
                let item = pill.checkList.find(i => i.actual === a.actual);
                if (item)   // pill is present:
                    a.status = item.status;
            });
            this.updateIsAll(accordionItem)
        }
    }

    updateIsAll(accordionItem) {
        // update whether all are unchecked or negative:
        accordionItem.isAll = accordionItem.checkList
            .every(a => a.status === STATUS_ENUM.UNCHECKED || a.status === STATUS_ENUM.NEGATIVE);
    }
}

DRFilterAttrs.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: DRFilterAttrs,
    bindings: {
        infos: '<',
        pills: '<',
        onRemovePositivePills: '&',
        onAddNegativePill: '&',
        onRemoveNegativePill: '&',
        onUpdatePositivePill: '&',
        groups: '<',
    },
};

export default component;
