// /* globals jQuery */
import templateUrl from './member-selection.html';

class GroupMemberSelection {
    constructor($scope) {
        this.childrenTable = []; // top list (if editing super-group)
        this.parentsTable = []; // bottom list
        this.membersFilter = '';
        this.superGroupFilter = '';

        this.$onInit = () => {
            this.modelOptions = {
                debounce: 200,
            };
        };

        this.$onChanges = changesObj => {

            if (changesObj['fullMemberList'] &&
                changesObj['fullMemberList'].currentValue &&
                changesObj['fullMemberList'].currentValue.status === 'success' &&
                changesObj['fullMemberList'].currentValue.groups) {

                if (this.editedGroup !== {})
                    this.calculateTables(changesObj['fullMemberList'].currentValue.groups);
            }
        };

        this.$scope = $scope;
    }

    calculateTables(groups) {

        // build children and parents tables for this group:
        this.childrenTable = [];
        this.parentsTable = [];

        let editedGroup = this.group;
        let row;
        groups.forEach((group) => {
            if (group.id !== editedGroup.id) { // skip currently edited group
                // add children's table entry:
                if (!(editedGroup.baseGroup)) { // leave children's table empty if editing a base group
                    row = {
                        ...group,
                        enabled: !(editedGroup.memberOf.includes(group.id)),
                        selected: editedGroup.children.includes(group.id),
                    };
                    this.childrenTable.push(row);
                }
                // add parents' table entry:
                if (!(group.baseGroup)) { // skip as parent if base group
                    row = {
                        ...group,
                        enabled: !(editedGroup.children.includes(group.id)),
                        selected: editedGroup.parents.includes(group.id),
                    };
                    this.parentsTable.push(row);
                }
            }
        });
    }

    cancel() {
        this.onCancel();
    }

    saveMembers() {
        let childIDs = this.childrenTable.filter(m => m.selected).map(m => m.id);
        let parentIDs = this.parentsTable.filter(m => m.selected).map(m => m.id);
        this.onSaveMembers({
            groupID: this.group.id,
            childIDs: childIDs,
            parentIDs: parentIDs,
        })
    }

    toggleOtherList(isChild, groupID) {
        if (!this.group.baseGroup) {
            // only toggle if we have both lists open:

            // (1) find index in other table to toggle
            let otherTable = this.childrenTable;
            if (isChild)
                otherTable = this.parentsTable;
            let index = otherTable.findIndex(g => g.id === groupID);

            // (2) toggle 'enabled' in other table (if entry found)
            if (index > -1) {
                otherTable[index].enabled = !otherTable[index].enabled;

                // (3) replace other table
                if (isChild)
                    this.parentsTable = otherTable;
                else
                    this.childrenTable = otherTable;
            }
        }
    }
}

GroupMemberSelection.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: GroupMemberSelection,
    bindings: {
        focusSection: '<',
        group: '<',
        fullMemberList: '<',
        onCancel: '&',
        onSaveMembers: '&',
    },
};

export default component;