import 'lodash';
import 'jquery';
import angular from 'angular';
import ngRedux from 'ng-redux';
import ngReduxRouter, {
    router,
} from 'redux-ui-router';
import {
    combineReducers,
} from 'redux';

import loginComponents from '../src/views/login';
import * as loginReducers from '../src/reducers/authentication';

import demoComponent from './demo';

import {
    prismsComponents,
    prismsReducers,
    PrismsServer,
    AuthServer,
} from '../src/index';

import './index.less';

function injectThunk(wizardManager) {
    return store => next => action =>
        typeof action === 'function' ?
        action({
            wizardManager,
        }, store.dispatch, store.getState) :
        next(action);
}

injectThunk.$inject = ['wizardManager'];

function reduxConfig($ngReduxProvider) {
    let reducers = combineReducers({
        prisms: combineReducers({
            ...prismsReducers,
            ...loginReducers,
        }),
        router,
    });

    $ngReduxProvider.createStoreWith(reducers, ['injectThunk', 'ngUiRouterMiddleware'], [
        window.devToolsExtension ? window.devToolsExtension() : f => f,
    ]);
}

reduxConfig.$inject = ['$ngReduxProvider'];


function RouterListener($transitions, ngUiStateChangeActions) {
    $transitions.onStart({}, ngUiStateChangeActions.onStateChangeStart);

    $transitions.onSuccess({}, function() {
        ngUiStateChangeActions.onStateChangeSuccess();
    });

    $transitions.onError({}, ngUiStateChangeActions.onStateChangeError);
}

RouterListener.$inject = ['$transitions', 'ngUiStateChangeActions'];

angular.module('app', [
        loginComponents,
        ngRedux,
        ngReduxRouter,
        prismsComponents,
    ])
    .config(reduxConfig)
    .config(() => {
        // PrismsServer.configureServer('http://localhost:9090/prisms/rest', 'http://localhost:9070/auth/rest');
        /* global PRISMS_CONFIG */
        PrismsServer.configureServer(PRISMS_CONFIG.serverUrl, PRISMS_CONFIG.authUrl);
        AuthServer.configure(PRISMS_CONFIG.authUrl);
    })
    .config(['$urlRouterProvider', '$stateProvider', ($urlRouterProvider, $stateProvider) => {
        $urlRouterProvider.otherwise('/login');

        let initialState = {
            name: 'loggedOut',
            url: '/login',
            component: 'login',
        };

        $stateProvider
            .state(initialState);
    }])
    .factory('injectThunk', injectThunk)
    .component('demo', demoComponent)
    .run(RouterListener);

// bootstrap the "ng-app" into the html document
angular.element(document).ready(() => {
    angular.bootstrap(document, ['app']);
});
