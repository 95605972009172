// libs
import angular from 'angular';

// components
import card from '../../components/card/card';

// views
import dashboard from './dashboard';

export default angular
    .module('brandeis.ui.prisms.view.dashboard', [])
    .component('card', card)
    .component('dashboard', dashboard)
    .run([
        '$templateCache',
        $templateCache => {
            $templateCache.put(
                'policiesTableCard',
                '<policy-manager display-size="dashSmall"></policy-manager>'
            );
        },
    ]).name;
