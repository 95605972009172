import templateUrl from './three-way-checkbox.html';
import { STATUS_ENUM } from '../../constants';

class ThreeWayCheckbox {
    constructor() {
        this.checkClass = {
            'three-way-checkbox': true,
            'neutral': true,
            'positive': false,
            'negative': false,
        };

        this.$onChanges = changesObj => {
            let checkState = changesObj.checkState;

            if (checkState) {
                if (checkState.currentValue == null) {
                    this.checkState = STATUS_ENUM.UNCHECKED;
                } else {
                    // if (this.atty.displayName === 'FFA') {
                    //     console.log(this.atty);
                    //     console.log(checkState)
                    // }
                    this.checkState = checkState.currentValue;
                }
                this.setClasses(this.checkState);
            }
        };
    }

    onClick() {
        switch (this.checkState) {
            case STATUS_ENUM.UNCHECKED:
                this.checkState = STATUS_ENUM.POSITIVE;
                break;
            case STATUS_ENUM.POSITIVE:
                this.checkState = STATUS_ENUM.NEGATIVE;
                break;
            case STATUS_ENUM.NEGATIVE:
                this.checkState = STATUS_ENUM.UNCHECKED;
                break;
        }

        this.setClasses(this.checkState);
        this.onChanged({ state: this.checkState });
    }

    setClasses(state) {
        this.checkClass.neutral = state === STATUS_ENUM.UNCHECKED;
        this.checkClass.positive = state === STATUS_ENUM.POSITIVE;
        this.checkClass.negative = state === STATUS_ENUM.NEGATIVE;
    }
}

const component = {
    bindings: {
        checkState: '<',
        atty: '<',
        onChanged: '&',
    },
    controller: ThreeWayCheckbox,
    templateUrl,
};

export default component;
