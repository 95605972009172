import templateUrl from './card.html';
import { WIDTHS, HEIGHTS } from './constants';

const baseClass = 'card';
const containerClass = 'card-container';

class Card {
    constructor($element, $scope) {
        this.$element = $element;
        this.$scope = $scope;

        this.$onInit = () => {
            this.showingSizingMenu = false;
            this.currentWidth = this.initialWidth || WIDTHS.small
            this.currentHeight = this.initialHeight || HEIGHTS.small;
            this.cardClass = [baseClass, this.currentHeight];
        };

        this.$postLink = () => {
            $element.addClass(containerClass).addClass(this.currentWidth).addClass(this.currentHeight);
        }
    }

    setHeight(height) {
        this.$element.removeClass(this.currentHeight);
        this.currentHeight = HEIGHTS[height];
        this.$element.addClass(this.currentHeight);
        this.cardClass = [baseClass, this.currentHeight];
        this.showingSizingMenu = false;

        this.onUpdateDimensions({ id: this.cardId, dimensions: { width: this.currentWidth, height: this.currentHeight }});
    }

    setWidth(width) {
        this.$element.removeClass(this.currentWidth);
        this.currentWidth = WIDTHS[width];
        this.$element.addClass(this.currentWidth);
        this.showingSizingMenu = false;

        this.onUpdateDimensions({ id: this.cardId, dimensions: { width: this.currentWidth, height: this.currentHeight }});
    }

    toggleSizingMenu() {
        this.showingSizingMenu = !this.showingSizingMenu;

        if (this.showingSizingMenu) {
            /* global jQuery */
            jQuery(document).on('click.sizing-menu', (evt) => {
                let target = jQuery(evt.target),
                    parent = target.parents('.sizing-menu, .card .actions');

                if (parent.length === 0) {
                    this.showingSizingMenu = false;
                    this.$scope.$apply();
                    jQuery(document).off('click.sizing-menu');
                }
            })
        } else {
            jQuery(document).off('click.sizing-menu');
        }
    }
}

Card.$inject = ['$element', '$scope'];

const component = {
    templateUrl,
    controller: Card,
    transclude: true,
    bindings: {
        cardId: '@',
        initialHeight: '<',
        initialWidth: '<',
        link: '<',
        onRemove: '&',
        onUpdateDimensions: '&',
        subTitle: '<',
        title: '<',
    },
};

export default component;
