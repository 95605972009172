import templateUrl from './tag-list.html';

// helper functions
import {
    constructTagString,
} from '../../functions';

class TagList {
    constructor($scope) {
        this.$scope = $scope;
    }

    displayTag(tag) {
        return constructTagString(tag);
    }

    remove(tagToRemove) {
        this.onTagRemoved({
            tag: tagToRemove,
        });
    }
}

TagList.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: TagList,
    bindings: {
        onTagRemoved: '&',
        tags: '<',
    },
};

export default component;
