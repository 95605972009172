import regionMap from './assets/images/regions.png';
    
export const BASE_STEPS = [
    {
        title: 'Basic Info',
        id: 'basic-info',
        icon: 'icon-document2',
        canMoveToNext: false,
        visited: false,
        state: {
            to: 'pa.policy-editor.basic-info',
            params: {
                id: 'new',
                focusSection: '',
            },
        },
    },
    {
        title: 'Data Requesters',
        id: 'select-dr',
        icon: 'icon-user-plus',
        canMoveToNext: false,
        visited: false,
        state: {
            to: 'pa.policy-editor.select-dr',
            params: {
                id: 'new',
                focusSection: '',
            },
        },
    },
    {
        title: 'Data and Constraints',
        id: 'data-and-constraints',
        icon: 'icon-select',
        canMoveToNext: false,
        visited: false,
        state: {
            to: 'pa.policy-editor.data-and-constraints',
            params: {
                id: 'new',
                focusSection: '',
            },
        },
    },
    {
        title: 'Set Precedence and Review',
        id: 'review',
        icon: 'icon-eye',
        canMoveToNext: false,
        visited: false,
        state: {
            to: 'pa.policy-editor.review',
            params: {
                id: 'new',
                focusSection: '',
            },
        },
    },
];

export const MAP_DEFAULTS = {
    center: { latitude: 9.8766525, longitude: 123.6894224 },
    zoom: 2,
    closeZoom: 8,
};

export const ALL_DATA_REQUESTERS_ATTRIBUTE = {
    type: 'po#isDR_type',
    argument: 'policy#DataRequester',
    negated: false,
};
export const ALL_DATA_REQUESTERS = [[[ ALL_DATA_REQUESTERS_ATTRIBUTE ]]];

export const ALL_DATA = [[{
    class_name: 'ALL',
    name: 'ALL',
    value: 'ALL',
}]];

export const POLICY_NONE = 'policy#NONE';
export const POLICY_NONE_DISPLAY = 'not specified';
export function sortArgument(a1_arg, a1_display, a2_arg, a2_display) {
    // make sure that policy#NONE always ends up last:
    if (a1_arg === POLICY_NONE) {
        if (a2_arg === POLICY_NONE)
            return 0;
        else
            return 1;
    } else if (a2_arg === POLICY_NONE)
        return -1;
    return a1_display.localeCompare(a2_display);
}

export const PDC_POLICY_DATA = [[{
    class_name: 'pdc#Product',
    name: 'pdc#product_ID',
    value: '\\long',
}]];
export const PDC_PRODUCT_SUBJECT_DATA = {
    class_name: 'pdc#Product',
    name: 'ID',
    value: 'ID',
};

export const MAX_NAME_LENGTH = 20; // if name is longer than this, use acronym for display

export const STATUS_ENUM = {
    UNCHECKED: 0,
    POSITIVE: 1,
    NEGATIVE: 2,
};

// very ugly hard-coding of regions:
// rectangular regions are given as list of (lat,lon) pairs of NW, SE corners
export const GEO_REGIONS = [
    {
        id: 'A',
        displayName: 'Coaster Islands',
        corners: [[23.70, -162.50], [18.50, -154.80]],
        checked: false,
        map_file: regionMap,
    },
    {
        id: 'B',
        displayName: 'Griffon - Coaster Islands',
        corners: [[22.50, -159.90], [20.90, -157.60]],
        checked: false,
        map_file: regionMap,
    },
    {
        id: 'C',
        displayName: 'Golleb, Griffon - Coaster Islands',
        corners: [[22.20, -159.10], [21.90, -158.50]],
        checked: false,
        map_file: regionMap,
    },
    {
        id: 'D',
        displayName: 'Oyster Bay, Griffon - Coaster Islands',
        corners: [[21.80, -159.50], [21.40, -159.00]],
        checked: false,
        map_file: regionMap,
    },
]
