import templateUrl from './policy-authority.html';

class PolicyAuthority {
    constructor($scope) {
        this.$scope = $scope;

        this.selectedAuthority = '';

        $scope.$watch(() => this.selectedAuthority, (newValue) => {
            if (newValue && newValue.hasOwnProperty('floraName')) {
                this.onFormChanged({pa: newValue.floraName, isValid: true});
            }
        });
        $scope.$watch(() => this.initAuthority, () => {
            this.initSelectedPA();
        });
        $scope.$watch(() => this.authorities, () => {
            this.initSelectedPA();
        });
    }

    initSelectedPA() {
        if (this.initAuthority && this.authorities) {
            this.selectedAuthority = this.findPA(this.initAuthority, this.authorities);
        }
    }

    findPA(fName, authorities) {
        let matchedPAs = authorities.filter(a => a.floraName === fName);
        if (matchedPAs.length > 0)
            return matchedPAs[0];
        return '';
    }
}

PolicyAuthority.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: PolicyAuthority,
    bindings: {
        onFormChanged: '&',
        initAuthority: '<',
        authorities: '<',
    },
};

export default component;
