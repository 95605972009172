import templateUrl from './policy-selector.html';

class PolicySelector {
    constructor() {
        this.$onInit = () => {
            this.testType = 'Combined';
            this.datePicker = {
                isOpened: false,
            };
            this.subSelectionGroupClass = {
                'col-lg-4': false,
                'col-lg-6': true,
                'col-sm-12': true,
            }
        };
    }

    selectTestType(type) {
        this.testType = type;
        this.onTestTypeSelected({ type });

        if (type === 'Combined') {
            this.subSelectionGroupClass['col-lg-4'] = false;
            this.subSelectionGroupClass['col-lg-6'] = true;
        } else {
            this.subSelectionGroupClass['col-lg-4'] = true;
            this.subSelectionGroupClass['col-lg-6'] = false;
        }
    }
}

const component = {
    templateUrl,
    controller: PolicySelector,
    bindings: {
        canEvaluate: '<',
        evaluationTime: '<',
        onDateSelected: '&',
        onPolicySelected: '&',
        // onPerspectiveSelected: '&',
        onTestClicked: '&',
        onTestTypeSelected: '&',
        // perspective: '<',
        // perspectives: '<',
        policies: '<',
        policy: '<',
        systemTime: '<',
    },
};

export default component;
