import templateUrl from './tag-selector.html';

class TagSelector {
    constructor($scope, $filter) {
        $scope.$watch(() => this.choices, () => {
            this.refreshChoices();
        });

        this.$filter = $filter('filter');
        this.searchValue = '';
    }

    addTag() {
        if (this.choices.find(t => typeof t === 'string' ? t === this.searchValue : t.name === this.searchValue) == null) {
            // this.tags.push(this.searchValue);
            this.onTagAdded({ tag: this.searchValue });
            this.searchValue = '';
        }
    }

    canShowAddButton() {
        return !this.choiceExists(this.searchValue) && this.hasAddBtn && this.searchValue !== ''
    }

    checkForEscape($event) {
        if ($event.keyCode === 27) {
            this.searchValue = '';
        }
    }

    choiceExists(choice) {
        return this.hasAddBtn && this.choices.find(c => typeof c === 'string' ? c === choice : c.name === choice);
    }

    clearSearch() {
        if (!this.isShowingChoices()) {
            this.searchValue = '';
        }
        this.showChoices = false;
    }

    displayTag(tag) {
        return typeof tag === 'string' ? tag : tag.displayName != null ? tag.displayName : tag.name;
    }

    isShowingChoices() {
        let filtered = this.$filter(this.visibleChoices, this.searchValue);
        return this.showChoices && (filtered.length > 0 || this.canShowAddButton() || this.choiceExists(this.searchValue));
    }

    refreshChoices() {
        this.visibleChoices = [...this.choices];

        // remove any tags from choices
        for (let tag of this.tags) {
            this.visibleChoices.splice(this.visibleChoices.findIndex(t => {
                let choiceStr = typeof t === 'string' ? t : t.displayName != null ? t.displayName : t.name,
                    tagStr = typeof tag === 'string' ? tag : tag.displayName != null ? tag.displayName : tag.name;

                return choiceStr === tagStr;
            }), 1);
        }
    }

    remove(tag) {
        this.onTagRemoved({ tag });
        this.refreshChoices();
    }

    select(tag) {
        this.searchValue = '';
        this.onTagSelected({ tag: tag });
        this.refreshChoices();
    }
}

TagSelector.$inject = ['$scope', '$filter'];

const component = {
    templateUrl,
    controller: TagSelector,
    bindings: {
        choices: '<',
        hasAddBtn: '<',
        placeholderText: '@',
        onTagAdded: '&',
        onTagRemoved: '&',
        onTagSelected: '&',
        tags: '<',
    },
};

export default component;
