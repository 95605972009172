import templateUrl from './access-matrix.html';
import {getRegionForCoordinates} from '../../functions';

class AccessMatrix {
    constructor() {
        this.accessContainerClasses = {
            'slider-visible': false,
            'access-matrix-container': true,
        };
        this.tableClasses = {
            'access-matrix-content': true,
            'container': true,
            'updating': false,
        };
        this.conflictDefault = 'UNDEFINED';
        this.legend = [
            {
                icon_classes: this.getIconClasses({constraints: [], geo_constraints: [], decision: 'ALLOWED'}),
                text: 'Unrestricted Allow',
            },
            {
                icon_classes: this.getIconClasses({constraints: [1], geo_constraints: [2], decision: 'ALLOWED'}),
                text: 'Restricted Allow',
            },
            {
                icon_classes: this.getIconClasses({constraints: [], geo_constraints: [], decision: 'DENY'}),
                text: 'Unrestricted Deny',
            },
            {
                icon_classes: this.getIconClasses({constraints: [1], geo_constraints: [2], decision: 'DENY'}),
                text: 'Restricted Deny',
            },
        ];

        this.$onChanges = changesObj => {
            if (changesObj.matrix) {
                let matrix = changesObj.matrix.currentValue;
                let status = matrix.status;
                this.conflictDefault = matrix.conflictDefault;
                console.log('matrix: ', changesObj.matrix.currentValue);

                this.tableClasses.updating = /fetching|updating/.test(status);
            }
        }
    }

    calcHalfSelected(cell) {
        let selectedIds = [];
        if (cell) {
            selectedIds = cell.reasons.map(r => r.policy_id);
        }
        if (this.matrix.table)
            this.matrix.table.forEach(row => {
                row.columnsList.forEach(cell => {
                    if (!cell.selected)
                        cell.halfSelected = cell.reasons
                            .map(r => r.policy_id)
                            .some(i => selectedIds.indexOf(i) >= 0);
                });
            });
    }

    closeSlider($event) {
        $event.preventDefault();
        this.accessContainerClasses['slider-visible'] = false;
        if (this.currentlySelected) {
            this.currentlySelected.selected = false;
            this.calcHalfSelected(null);
        }
    }

    showSlider(requesterName, col_idx, cell) {
        this.selectedRequester = requesterName;
        this.selectedOwner = this.matrix.columnHeaders[col_idx].displayName + ' - ' + this.matrix.columnHeaders[col_idx].tag;

        if (this.currentlySelected) {
            this.currentlySelected.selected = false;
        }
        this.currentlySelected = cell;
        this.currentlySelected.selected = true;
        this.currentlySelected.halfSelected = false;
        this.calcHalfSelected(cell);

        // construct the list of decisions and policies
        this.decisions = cell.reasons
            .map(reason => {
                return {
                    id: reason.policy_id,
                    displayName: reason.policy_displayName,
                    description: reason.policy_description,
                    decision: reason.decision,
                    constraints: reason.constraints.filter(c => c.value.predicate !== 'WithinRectangularRegion'),
                    geo_constraints: reason.constraints
                        .filter(c => c.value.predicate === 'WithinRectangularRegion')
                        .map(gc => {
                            return {
                                negated: gc.value.negated,
                                region: getRegionForCoordinates([
                                    {latitude: gc.value.nw_latitude, longitude: gc.value.nw_longitude},
                                    {latitude: gc.value.se_latitude, longitude: gc.value.se_longitude}]),
                            }
                        })
                        .sort((gc1, gc2) => {
                            // first compare by <negated> setting, than by region name
                            let result = gc1.negated - gc2.negated;  // show positives before negated
                            if (result !== 0)
                                return result;
                            return gc1.region.id.localeCompare(gc2.region.id);
                        }),
                }
            })
            // first sort by decision, then by length of geo_constraints, then by region id, and finally by policy id
            .sort((d1, d2) => {
                let result = d1.decision.localeCompare(d2.decision);  // ALLOWED before DISALLOWED
                if (result !== 0) return result;
                result = d1.geo_constraints.length - d2.geo_constraints.length;  // ASCENDING by length, see:
                                                                        // https://stackoverflow.com/a/10630852/3816489
                if (result !== 0) return result;
                result = d1.geo_constraints.map(gc => gc.region.id).join('')
                    .localeCompare(d2.geo_constraints.map(gc => gc.region.id).join(''));
                if (result !== 0) return result;
                return d1.displayName.localeCompare(d2.displayName);
            });
        this.policies = this.decisions
            .map(decision => {
                return {
                    id: decision.id,
                    displayName: decision.displayName,
                    description: decision.description,
                    constraints: decision.constraints,
                }
            })
            .sort((d1, d2) => d1.displayName.localeCompare(d2.displayName));
        this.accessContainerClasses['slider-visible'] = true;
    }

    getIconClasses(policy) {
        let iClasses = {
            'accessible-icon': true,
            // 'em-dark-bg': true,
            'glyphicon': true};
        // glyphicon-ok-sign - ALLOWED & no constraints
        // glyphicon-ok-circle - constrained ALLOWED
        // glyphicon-remove-sign - DISALLOWED & no constraints
        // glyphicon-remove-circle - constrained DISALLOWED
        let postfix = policy.constraints.length > 0 || policy.geo_constraints.length > 0 ? '-circle' : '-sign';
        let middle = policy.decision === 'ALLOWED' ? '-ok' : '-remove'
        iClasses['glyphicon' + middle + postfix] = true;
        iClasses['gray'] = policy.decision !== 'ALLOWED';
        return iClasses;
    }
}

const component = {
    templateUrl,
    controller: AccessMatrix,
    bindings: {
        matrix: '<',
    },
};

export default component;
