/*global jQuery*/

import {
    login,
} from '../../actions/authentication';
import {
    stateGo,
} from 'redux-ui-router';
import templateUrl from './login.html';

const baseClass = 'login-form';
const errorClass = 'has-error';
const filledClass = 'filled';

class Authentication {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                login,
                stateGo,
            })(this);

        this.$scope = $scope;
        this.classes = [baseClass];
        $scope.$on('$destroy', unsubscribe);

        $scope.$watch(() => this.user.error, () => {
            this.classes = [baseClass];

            if (this.user.error) {
                this.classes.push(errorClass);
            }
        });

        this.$onInit = () => {
            jQuery('.login-wrapper input').on('animationstart', ($event) => {
                switch ($event.originalEvent.animationName) {
                    case 'onAutoFillStart':
                        jQuery($event.target).parent().addClass(filledClass);
                        break;
                    case 'onAutoFillCancel':
                        jQuery($event.target).parent().removeClass(filledClass);
                        break;
                }
            });
        };
    }

    mapStateToThis(state) {
        const {
            isAuthenticated,
            user,
        } = state.prisms;

        return {
            isAuthenticated,
            user,
        };
    }

    authenticate() {
        this.login(this.credentials);
    }

    blur($event) {
        let elem = jQuery($event.target);

        if (elem.val().length === 0) {
            elem.parent().removeClass(filledClass);
        }
    }

    focus($event) {
        jQuery($event.target).parent().addClass(filledClass);
    }

    keypress($event) {
        jQuery($event.target).parent().addClass(filledClass);
    }
}

Authentication.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: Authentication,
};

export default component;
