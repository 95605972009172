// libs
import angular from 'angular';

// components
import constraintList from './constraints-form/constraint-list';
import constraintsForm from './constraints-form/constraints-form';
import constraintParameter from './constraints-form/constraint-parameter';
import loopForm from './loop-form/loop-form';
import node from './node/node';
import nodeProperty from './node/node-property';
import saveForm from './save-form/save-form';
import startNode from './node/start-node';
import treeSelector from './tree-selector/tree-selector';

export default angular.module('brandeis.ui.prisms.jds.components', [])
    .component('constraintList', constraintList)
    .component('constraintsForm', constraintsForm)
    .component('constraintParameter', constraintParameter)
    .component('loopForm', loopForm)
    .component('node', node)
    .component('nodeProperty', nodeProperty)
    .component('saveForm', saveForm)
    .component('startNode', startNode)
    .component('treeSelector', treeSelector)
    .name;
