import * as actionTypes from '../actions/action-types';
import {
    cleanupName, displayPA,
} from '../functions';

const MAX_NAME_LENGTH = 20; // if group name is longer than this, use acronym for display

export function accessMatrix(state = { status: 'empty' }, action) {
    switch (action.type) {
        case actionTypes.FAILED_GET_ACCESS_MATRIX:
            return {
                status: 'failed',
            };
        case actionTypes.FETCHING_ACCESS_MATRIX:
            let newStatus = state.status === 'empty' ? 'fetching' : 'updating';
            return {
                ...state,
                status: newStatus,
            };
        case actionTypes.LOGOUT:
            return {
                status: 'empty',
            };
        case actionTypes.RESET_TEST_RESULTS:
            return {
                status: 'empty',
            };
        case actionTypes.SUCCESS_GET_ACCESS_MATRIX:
            let newState = {
                status: 'success',
                conflictDefault: action.conflictDefault,
            };

            newState.groupMap = new Map();
            let table = [];
            let columnsSeen = new Map(); // owner_id => Set( tags )
            for (let row of action.accessMatrix.filter(r => r.requester_group_acronym !== 'ANY')) {
                let tableRow = {
                    id: row.requester_group_id,
                    name: row.requester_group_name,
                    displayName: row.requester_group_name + (row.attributes.length === 0 ? '' :
                        ' - ' + row.attributes.map(a => cleanupName(a.argument)).join(', ')),
                    displayGroup: (row.attributes.length > 0 ? '' : row.requester_group_name),
                    displayRoles: (row.attributes.length === 0 ? '' :
                        row.attributes.map(a => cleanupName(a.argument)).join(', ')),
                    description: row.requester_group_description,
                    roles: row.attributes
                        .map(function (attribute) {
                            return cleanupName(attribute.argument);
                        }),
                    columnsMap: new Map(),
                };

                // go through all "owners" and build up map of columnsMap:
                //  Map( key: "owner_id + tag" => value: Set( "results" )
                // also build up columnsSeen:
                //  Map( key: owner_id
                //    => value: { decisions: Set( "decision" [+ ':constrained' (if length > 0)] )
                //                reasons: Set( {policy_id, policy_descr, policy_display_name, etc.} } )
                for (let owner of row.owners) {
                    // update group map for matching and sorting group IDs to display names:
                    newState.groupMap.set(owner.owner_group_id, {
                        name: owner.owner_group_name,
                        acronym: owner.owner_group_acronym,
                        displayName: owner.owner_group_name.length > MAX_NAME_LENGTH ?
                            owner.owner_group_acronym : owner.owner_group_name,
                        description: owner.owner_group_description,
                    });

                    for (let data_tag of owner.data_tags) {
                        let tag = cleanupName(data_tag.tag_name);

                        // first, update columnsSeen = pair of owner id and tag, indexed by owner id
                        if (!columnsSeen.has(owner.owner_group_id))
                            columnsSeen.set(owner.owner_group_id, new Set());
                        columnsSeen.set(
                            owner.owner_group_id,
                            columnsSeen.get(owner.owner_group_id).add(tag));

                        // second, build cell values:
                        let decisions = new Set();
                        let reasons = new Set();
                        for (let result of data_tag.results) {
                            decisions.add(result.decision + (result.constraints.length > 0 ? ':CONSTRAINED' : ''));
                            reasons.add({
                                policy_id: result.policy_id,
                                policy_displayName: cleanupName(result.policy_id),
                                policy_description: result.description,
                                constraints: result.constraints,
                                decision: result.decision,
                            });
                        }

                        let key = '' + owner.owner_group_id + tag;
                        if (tableRow.columnsMap.has(key)) {
                            console.log('ERROR: multiple column definitions for requester/owner defined - skipping!', row, owner);
                            continue
                        }
                        tableRow.columnsMap.set(key, {
                            decisions: decisions,
                            reasons:reasons,
                        });
                    }
                }

                table.push(tableRow)
            }

            // turn columns seen into array structure:
            newState.columnHeaders = [];
            let sortedColumnsSeen = new Map([...columnsSeen].sort((a, b) =>
                newState.groupMap.get(a[0]).displayName.toUpperCase().localeCompare(
                    newState.groupMap.get(b[0]).displayName.toUpperCase())));
            for (let entry of sortedColumnsSeen.entries()) {
                Array.from(entry[1]).sort().forEach((item, index) => {
                    let header = {
                        ...newState.groupMap.get(entry[0]),
                        owner_id: entry[0],
                        tag: item,
                        colspan: index === 0 ? entry[1].size : 0,
                    };
                    newState.columnHeaders.push(header);
                });
            }

            // create list of columnsMap by indexing into columnHeaders:
            // classifying cell "decision" as this:
            // - case A. Filled check mark == some UNCONSTRAINED ALLOW results => "ALLOWED"
            // - case B. Hollow check mark == only CONSTRAINED results (ALLOW or DENY)
            // - case C. Filled cross mark == some UNCONSTRAINED DISALLOW results => "DISALLOWED"
            for (let row of table) {
                let columnsList = [];
                for (let header of newState.columnHeaders) {
                    let key = '' + header.owner_id + header.tag;
                    if (row.columnsMap.has(key)) {
                        let decision = '';
                        let cell_obj = row.columnsMap.get(key);
                        if (cell_obj.decisions.has('ALLOWED'))
                            decision = 'ALLOWED';
                        else if (cell_obj.decisions.has('DISALLOWED'))
                            decision = 'DISALLOWED';
                        else if (Array
                            .from(cell_obj.reasons)
                            .map(r => r.constraints.length)
                            .every(n => n > 0))
                            decision = 'ONLY:CONSTRAINED';
                        else {
                            // unexpected decision mix:
                            console.warn('Did not expect these decisions: ', key, ' => ', cell_obj);
                            columnsList.push({
                                decision: 'MULTIPLE',
                                reasons: [],
                                selected: false,
                                halfSelected: false,
                            });
                            continue
                        }
                        columnsList.push({
                            decision: decision,
                            reasons: Array.from(cell_obj.reasons),
                            selected: false,
                            halfSelected: false,
                        });
                    } else
                        columnsList.push({
                            decision: 'UNKNOWN',
                            reasons: [],
                            selected: false,
                            halfSelected: false,
                        });
                }
                row.columnsList = columnsList;
            }

            // TODO: maybe leave the sorting to the HTML, e.g., to pin the PA view into first rows for example...
            newState.table = table.sort((a, b) =>
                a.displayName.toUpperCase().localeCompare(b.displayName.toUpperCase()));

            newState.allRolesEmpty = table.every(row => row.roles.length === 0)
            return newState;
        default:
            return state;
    }
}

export function policyUpdated(state = '', action) {
    switch (action.type) {
        case actionTypes.RESET_POLICY_UPDATED:
            return '';
        case actionTypes.SUCCESS_DISABLE_POLICY:
        case actionTypes.SUCCESS_ENABLE_POLICY:
        case actionTypes.SUCCESS_SET_PRECEDENCE:
            return action.policyId;
        default:
            return state;
    }
}

function getNumberPAs(policies) {
    // calculate number of distinct PAs in policies:
    let PAs = new Set();
    policies.forEach((p) => {
        PAs.add(p.policyAuthority);
    });
    return PAs.size;
}

export function policies(state = {}, action) {
    let policies = [];
    let enabled = true;
    switch (action.type) {
        case actionTypes.FETCHING_POLICIES:
            return {
                ...state,
                status: 'fetching',
            };
        case actionTypes.LOGOUT:
            return {};
        case actionTypes.DISABLING_POLICY:
        case actionTypes.ENABLING_POLICY:
        case actionTypes.SETTING_PRECEDENCE:
            return {
                status: 'setting',
                numberPAs: getNumberPAs(state.policies),
                policies: state.policies.map(p => {
                    if (p.id === action.policyId) {
                        return {
                            ...p,
                            waitingForUpdate: true,
                        };
                    }

                    return {
                        ...p,
                    };
                }),
            };
        case actionTypes.SUCCESS_GET_POLICIES:
            policies = action.policies.map(policy => {
                const timeWindow = {
                    isOngoing: policy.endTimeString == null || policy.endTimeString === '',
                };

                timeWindow.startTime = policy.startTimeString ? new Date(policy.startTimeString) : null;
                timeWindow.endTime = policy.endTimeString ? new Date(policy.endTimeString) : null;

                return {
                    ...policy,
                    displayId: cleanupName(policy.id), //.replace(/\_/, ' '),
                    // .replace(/([a-z])([A-Z])/g, '$1 $2')
                    // .replace(/([A-Z])([a-z])/g, ' $1$2')
                    // .replace(/^./, (str) => str.toUpperCase()),
                    displayPriority: policy.priority,
                    displayPA: displayPA(policy.policyAuthority),
                    timeWindow,
                    waitingForUpdate: false,
                };
            });
            policies.sort((a, b) => a.displayId.localeCompare(b.displayId));

            return {
                status: 'success',
                numberPAs: getNumberPAs(policies),
                policies,
                ifConflictThenDisallow: action.ifConflictThenDisallow,
            };
        case actionTypes.SUCCESS_DISABLE_POLICY:
            enabled = false;
        case actionTypes.SUCCESS_ENABLE_POLICY:
            policies = state.policies.map(p => {
                if (p.id === action.policyId) {
                    return {
                        ...p,
                        enabled: enabled,
                        waitingForUpdate: false,
                    };
                }

                return {
                    ...p,
                };
            });

            return {
                status: 'success',
                numberPAs: getNumberPAs(policies),
                policies,
            };
        case actionTypes.SUCCESS_SET_PRECEDENCE:
            policies = state.policies.map(p => {
                if (p.id === action.policyId) {
                    return {
                        ...p,
                        priority: action.priority,
                        displayPriority: action.priority,
                        waitingForUpdate: false,
                    };
                }

                return {
                    ...p,
                };
            });

            return {
                status: 'success',
                numberPAs: getNumberPAs(policies),
                policies,
            };
        default:
            return state;
    }
}

export function selectedPolicy(state = null, action) {
    switch (action.type) {
        case actionTypes.SELECT_POLICY:
            return action.policy;
        default:
            return state;
    }
}
