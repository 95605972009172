// actions

// template
import templateUrl from './add-constraints.html';

class PolicyAddConstraints {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {})(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {};
    }

    mapStateToThis(state) {
        const {
            policies,
            policyUpdated,
            systemTime,
        } = state.prisms;

        return {
            policies,
            policyUpdated,
            systemTime,
        };
    }
}

PolicyAddConstraints.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicyAddConstraints,
};

export default component;