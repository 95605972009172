import templateUrl from './policies-table.html';

class PoliciesTable {
    constructor($scope) {
        this.$scope = $scope;

        this.tableClasses = {
            'data-content': true,
            'updating': false,
        };
        this.conflictDefault = null;

        this.$onInit = () => {
            this.precedenceOptions = this.precedenceOptions || {
                debounce: 1000,
            };
            this.filter = '';
            this.popoverTemplateUrl = './delete-popover.html';

            this.popups = {};
        };

        this.$onChanges = changesObj => {
            if (changesObj.policies) {
                let newState = changesObj.policies.currentValue;
                let status = newState.status;

                this.popups = {};
                this.numberPAs = newState.numberPAs;
                this.conflictDefault = newState.ifConflictThenDisallow ? 'DISALLOWED' : 'ALLOWED';
                if (newState.policies) {
                    for (let policy of newState.policies) {
                        this.popups[policy.id] = {
                            visible: false,
                        };
                    }
                }

                this.tableClasses.updating = /fetching|updating/.test(status);
            }
        };
    }

    changedPrecedence(policy, precedence) {
        // debounce
        this.onChangePrecedence({
            policy: policy,
            precedence: precedence,
        });
    }

    checkClass(policy) {
        return {
            'disabled': !policy.enabled,
            'waiting-for-update': policy.waitingForUpdate,
        };
    }

    showDeletePopup(policyId) {
        if (this.currentPopup) {
            this.currentPopup.visible = false;
        }
        this.currentPopup = this.popups[policyId];
        this.currentPopup.visible = true;

        /* global jQuery */
        jQuery(document).on('click.delete-popup', event => {
            let target = jQuery(event.target);

            if (target.parents('.delete-popup').length === 0 &&
                target.parents('.actions-menu').length === 0) {
                this.currentPopup.visible = false;
                jQuery(document).off('click.delete-popup');
                this.$scope.$apply();
            }
        });
    }
}

PoliciesTable.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: PoliciesTable,
    bindings: {
        isDashCard: '<',
        isReviewCard: '<',
        onAddPolicy: '&',
        onChangePrecedence: '&',
        onChangeStatus: '&',
        onCopyPolicy: '&',
        onDeletePolicy: '&',
        onEnableToggled: '&',
        onToggleConflictDefault: '&',
        precedenceOptions: '<',
        policies: '<',
        systemTime: '<',
        user: '<',
    },
};

export default component;
