import templateUrl from './constraints-form.html';
import {cleanupName} from '../../../containers/cdm/functions';
import {displayValue} from '../../../functions';
import PrismsClient from '@brandeis/prisms-client-js';

const BASE_ICON = 'glyphicon';
const DOWN_CARET_ICON = 'glyphicon-triangle-bottom';
const SIDE_CARET_ICON = 'glyphicon-triangle-right';

function determineConstraintValue(parameter, value) {
    let v;
    switch (parameter) {
        case 'AggregationValue':
            v = new PrismsClient.AggregationValue();
            console.log('AggregationValue: ', v)
            return {
                valueType: parameter,
                aggregationType: value,
            };
        case 'Geodetic2DValue':
            v = new PrismsClient.Geodetic2DValue();
            console.log('Geo2D: ', v)
            return {
                valueType: parameter,
                latitude: value.latitude,
                longitude: value.longitude,
            };
        case 'IndividualValue':
            return {
                valueType: parameter,
                name: value,
            };
        case 'NumberValue':
            return {
                valueType: parameter,
                'number': value,
            };
        case 'OffsetTimeValue':
            return {
                valueType: parameter,
                offset: value,
            };
        case 'PathValue':
            return {
                valueType: parameter,
                cdmPath: value.cdmPath,
                propertyList: value.propertyList,
            };
        case 'StringValue':
            return {
                valueType: parameter,
                'string': value,
            };
        case 'TimeValue':
            return {
                valueType: parameter,
                timeString: value,
            };
    }
}

class ConstraintsForm {
	constructor($filter) {
        this.dateFilter = $filter('date');
        this.$onInit = () => {
            this.constraintPredicates = Object.keys(this.constraintsMap);
            this.constraintValues = [];
        }
	}

    addConstraint() {
        let mappedValues = this.constraintValues.map((val, ndx) => {
                return determineConstraintValue(this.constraintParameters[ndx].selected, val);
            });
        this.selectedConstraint.constraints.push({
            displayName: `${this.selectedConstraintPredicate}: "${mappedValues.map(displayValue).join(', ')}"`,
            predicate: this.selectedConstraintPredicate,
            values: mappedValues,
        });

        this.selectedConstraint = null;
        this.selectedConstraintPredicate = '';
        this.constraintParameters = [];
        this.constraintValues = [];
        this.onConstraintsUpdated({ node: this.node });
    }

	cleanup(name) {
		return cleanupName(name);
	}

    close() {
        this.onClose();
    }

    changeParameters() {
        this.constraintParameters = this.constraintsMap[this.selectedConstraintPredicate].map(c => {
            return {
                selected: c[0],
                parameters: c,
            };
        });
    }

    constraintClass(constraint) {
        return {
            selected: constraint === this.selectedConstraint,
        };
    }

    filterConstraint(parameter, value) {
        switch(parameter) {
            case 'Geodetic2DValue':
                return `lat: ${value.latitude} long: ${value.longitude}`;
            case 'PathValue':
                return `CDM path: ${value.cdmPath} prop list: ${value.propertyList}`;
            case 'TimeValue':
                return this.dateFilter(value, 'd MMM yyyy', 'UTC');
            default:
                return value;
        }
    }

    getIcon(property) {
        return {
			[BASE_ICON]: property.constraints.length > 0 && true,
			[DOWN_CARET_ICON]: property.constraints.length > 0 && property.expanded,
			[SIDE_CARET_ICON]: property.constraints.length > 0 && !property.expanded,
		};
    }

    selectConstraint(constraint) {
        this.selectedConstraint = constraint;
        this.constraintParameters = [];
        this.selectedConstraintPredicate = '';
    }
}

ConstraintsForm.$inject = ['$filter'];

const component = {
    bindings: {
        constraintsMap: '<',
        node: '<',
        onClose: '&',
        onConstraintsUpdated: '&',
    },
    controller: ConstraintsForm,
    templateUrl,
};

export default component;
