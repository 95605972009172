// actions
import {
    getEvaluationTime,
    selectEvaluationTime,
} from '../../actions/evaluation-time';
import {
    getPolicies,
    resetResults,
    selectPolicy,
    testCombinedPolicies,
    testIndividualPolicy,
} from '../../actions/policies';
import {
    MAP_DEFAULTS,
} from '../../constants';

// template
import templateUrl from './policy-tester.html';


function createCDMProperty(obj) {
    const prop = {
        class_name: obj.class_name,
        name: obj.name,
    };

    if (obj.value) {
        prop.value = obj.value;
    }

    return prop;
}

function constructRequestFormula() {
    const requestFormula = [
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'prisms#name',
            }),
        ],
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'fish#ownerNation',
            }),
            createCDMProperty({
                'class_name': 'prime#Nation',
                'name': 'prime#name',
            }),
        ],
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'fish#ownerOrganization',
            }),
            createCDMProperty({
                'class_name': 'prisms#Organization',
                'name': 'prisms#name',
            }),
        ],
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'prisms#track',
            }),
            createCDMProperty({
                'class_name': 'prisms#Track',
                'name': 'prisms#location',
            }),
            createCDMProperty({
                'class_name': 'geo#Geodetic2DLocation',
                'name': 'geo#longitude',
            }),
        ],
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'prisms#track',
            }),
            createCDMProperty({
                'class_name': 'prisms#Track',
                'name': 'prisms#location',
            }),
            createCDMProperty({
                'class_name': 'geo#Geodetic2DLocation',
                'name': 'geo#latitude',
            }),
        ],
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'prisms#track',
            }),
            createCDMProperty({
                'class_name': 'prisms#Track',
                'name': 'prisms#course',
            }),
        ],
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'prisms#track',
            }),
            createCDMProperty({
                'class_name': 'prisms#Track',
                'name': 'prisms#speed',
            }),
        ],
        [
            createCDMProperty({
                'class_name': 'prisms#Ship',
                'name': 'prisms#track',
            }),
            createCDMProperty({
                'class_name': 'prisms#Track',
                'name': 'prisms#time',
            }),
        ],
    ];

    return requestFormula;
}

class PolicyTester {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                getEvaluationTime,
                getPolicies,
                resetResults,
                selectEvaluationTime,
                selectPolicy,
                testCombinedPolicies,
                testIndividualPolicy,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            this.mapOptions = {
                ...MAP_DEFAULTS,
                options: {
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                },
            };

            this.selectPolicy(null);
            this.checkReset();

            if (this.evaluationTime == null) {
                this.getEvaluationTime();
            }

            if (this.policies.length === 0) {
                this.getPolicies();
            }
        }
    }

    mapStateToThis(state) {
        const {
            accessMatrix,
            decisions,
            decisionsTitle,
            evaluationTime,
            isPDC,
            policies,
            policyMapStatus,
            selectedEvaluationTime,
            selectedPolicy,
            systemTime,
        } = state.prisms;

        let formula = [];
        let policiesSubset = policies.filter(p => !/Default/.test(p.displayId))

        if (isPDC) {
            formula = [
                [{
                    class_name: 'pdc#Product',
                    name: 'pdc#product_ID',
                }],
            ];
        } else {
            formula = constructRequestFormula();
        }

        return {
            accessMatrix,
            date: evaluationTime,
            decisions,
            decisionsTitle,
            evaluationTime,
            formula,
            isPDC,
            policies: policiesSubset,
            policyMapStatus,
            selectedEvaluationTime,
            selectedPolicy,
            systemTime,
        };
    }

    canEvaluate() {
        return this.testType === 'Individual' ? this.selectedPolicy && this.selectedEvaluationTime :
            this.selectedEvaluationTime;
    }

    async checkReset() {
        if (this.decisions.length > 0) {
            this.resetResults();
        }
    }

    onSelectEvaluationTime(date) {
        this.selectEvaluationTime(date);
        this.checkReset();
    }

    onSelectPolicy(policy) {
        this.selectPolicy(policy);
        this.checkReset();
    }

    submitTest() {
        if (this.testType === 'Individual') {
            this.testIndividualPolicy(this.selectedPolicy, this.selectedEvaluationTime);
        } else {
            this.testCombinedPolicies(this.selectedEvaluationTime, this.formula);
        }
    }
}

PolicyTester.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicyTester,
};

export default component;
