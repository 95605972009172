// libs
import angular from 'angular';

// components
import drFilterAttrs from '../../../components/policy-editor/select-dr/dr-filter-attrs';
import drFiltersComponent from '../../../components/policy-editor/select-dr/dr-filters';
import drSharedList from '../../../components/policy-editor/select-dr/dr-shared-list'

// container
import selectDR from './select-dr';

export default angular.module('brandeis.ui.prisms.containers.policyEditor.selectDR', [])
    .config(['$stateProvider', ($stateProvider) => {
        let selectDRState = {
            name: 'pa.policy-editor.select-dr',
            url: '/{id}/select-data-requester/{focusSection}',
            views: {
                policyEditorTabContent: 'selectDr',
            },
            data: {
                isWizardStep: true,
            },
        };

        $stateProvider
            .state(selectDRState);
    }])
    .component('drFilterAttrs', drFilterAttrs)
    .component('drFiltersComponent', drFiltersComponent)
    .component('drSharedList', drSharedList)
    .component('selectDr', selectDR)
    .name;
