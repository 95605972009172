import templateUrl from './group-table.html';

class GroupTable {
    constructor($scope) {
        this.$scope = $scope;

        this.tableClasses = {
            'data-content': true,
            'updating': false,
        };

        this.userCanEdit = false;

        this.$onInit = () => {
            this.filter = '';

            this.popoverTemplateUrl = './delete-popover.html';

            this.popups = {};
        };

        this.$onChanges = changesObj => {
            if (changesObj.groups) {
                this.popups = {};

                let newState = changesObj.groups.currentValue;
                let status = newState.status;
                this.userCanEdit = newState.userCanEdit;
                if (newState.groups) {
                    for (let group of newState.groups) {
                        this.popups[group.id] = {
                            visible: false,
                        };
                    }
                }

                this.tableClasses.updating = /fetching|deleting/.test(status);
            } else {
                this.userCanEdit = false;
            }
        }
    }

    filterBy(name) {
        this.filter = name;
    }

    showDeletePopup(group) {
        if (this.currentPopup) {
            this.currentPopup.visible = false;
        }
        this.currentPopup = this.popups[group.id];
        this.currentPopup.visible = true;

        /* global jQuery */
        jQuery(document).on('click.delete-popup', event => {
            let target = jQuery(event.target);

            if (target.parents('.delete-popup').length === 0 &&
                target.parents('.actions-menu').length === 0) {
                this.currentPopup.visible = false;
                jQuery(document).off('click.delete-popup');
                this.$scope.$apply();
            }
        });
    }
}

GroupTable.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: GroupTable,
    bindings: {
        groups: '<',
        onDeleteGroup: '&',
    },
};

export default component;