import templateUrl from './policy-details.html';

const component = {
    templateUrl,
    bindings: {
        policy: '<',
        systemTime: '<',
    },
};

export default component;
