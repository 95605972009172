import * as actionTypes from '../actions/action-types';

export function decisions(state = [], action) {
    switch (action.type) {
        case actionTypes.FAILED_GET_COMBINED_POLICY_TEST:
        case actionTypes.FAILED_GET_INDIVIDUAL_POLICY_TEST:
            return ['failed'];
        case actionTypes.FETCHING_INDIVIDUAL_POLICY_TEST:
        case actionTypes.FETCHING_COMBINED_POLICY_TEST:
            return ['fetching'];
        case actionTypes.SUCCESS_GET_COMBINED_POLICY_TEST:
        case actionTypes.SUCCESS_GET_INDIVIDUAL_POLICY_TEST:
            const val = action.results//.filter(d => d.policyId !== 'DoNotShareByDefault')
                .map(d => {
                    let result = {
                        answer: 'No decision',
                        reason: d.policyId.replace(/\_/, ' '),
                    };

                    if (/^allowed/.test(d.decision)) {
                        result.answer = 'Sharing data';
                    } else if (/^disallowed/.test(d.decision)) {
                        if (d.policyId === 'DoNotShareByDefault') {
                            result.answer = 'Not sharing data';
                            result.reason = 'Default policy';
                        } else {
                            result.answer = 'Not sharing data';
                        }
                    }

                    return result;
                });

            return val;
        case actionTypes.RESET_TEST_RESULTS:
            return [];
        default:
            return state;
    }
}

export function decisionsTitle(state = 'Results', action) {
    switch (action.type) {
        case actionTypes.FETCHING_COMBINED_POLICY_TEST:
        case actionTypes.SUCCESS_GET_COMBINED_POLICY_TEST:
            return 'Combined Policy Results';
        case actionTypes.FETCHING_INDIVIDUAL_POLICY_TEST:
        case actionTypes.SUCCESS_GET_INDIVIDUAL_POLICY_TEST:
            return 'Individual Policy Results';
        default:
            return state;
    }
}
