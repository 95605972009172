import * as actionTypes from '../actions/action-types';
import {displayPA} from '../functions';

export function policyAuthorities(state = [], action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_AUTHORITIES:
            // create and then sort by display names
            return action.policyAuthorities
                .map(pa => {
                    return {
                        displayName: displayPA(pa),
                        floraName: pa,
                    }
                })
                .sort((pa1, pa2) => pa1.displayName.localeCompare(pa2.displayName));
        default:
            return state;
    }
}
