// actions
import {
    initDRFilters,
    updateDRFilters,
    getPreviewRequesters,
} from '../../../actions/data-requesters';
import { updatePolicyLocal } from '../../../actions/policy';

// template
import templateUrl from './select-dr.html';

// constants & helpers
import { buildRequestersFromDRFilters, sortPills } from '../../../functions';

class PolicySelectDataRequester {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(this.mapStateToThis, {
            initDRFilters,
            updateDRFilters,
            updatePolicyLocal,
            getPreviewRequesters,
        })(this);

        $scope.$on('$destroy', unsubscribe);

        this.currentPills = [];
        this.pillsMap = new Map();
        this.highlightIndex = -1;
        this.awaitingUpdate = false;

        this.$onInit = () => {
            if (
                this.policy &&
                this.requesterAttributeInfos &&
                this.requesterAttributeInfos.status === 'success'
            ) {
                this.initDRFilters(
                    this.policy.requesters,
                    this.requesterAttributeInfos.requesterAttributeInfos
                );
            }
        };
        $scope.$watch(() => this.drFilters, newValue => {
            if (newValue) {
                if (this.awaitingUpdate) {
                    this.awaitingUpdate = false;
                    this.policy.requesters = buildRequestersFromDRFilters(newValue);
                    this.updatePolicyLocal(this.tabId, this.policy, this.policy.requesters.length > 0);
                } else if (newValue.length === 0) {
                    this.policy.requesters = buildRequestersFromDRFilters([]);
                    this.updatePolicyLocal(this.tabId, this.policy, true);
                    // get preview for ALL DRs since the other components are not going to be updated from their
                    // initial state and thus won't trigger the preview refresh properly:
                    this.getPreviewRequesters(this.policy.requesters);
                }
            }
        });
    }

    mapStateToThis(state) {
        const {
            drFilters,
            previewRequesters,
            groups,
            policy,
            requesterAttributeInfos,
        } = state.prisms;

        const { router } = state;

        let tabId = router.currentState.name.split('.').pop();

        return {
            drFilters,
            previewRequesters,
            groups,
            policy,
            requesterAttributeInfos,
            tabId,
        };
    }

    // maintain mapping for current pills and other helpers:

    pillsToMap(pills) {
        this.pillsMap.clear();
        let negCategoriesSeen = []; // collect negative categories
        pills
            // first, filter any All pill:
            .filter(p => p.checkList.length > 0)
            // second, add remaining pills to map:
            .forEach(p => {
                let key = this.createKey(p.type, p.negated);
                if (!this.pillsMap.has(key)) this.pillsMap.set(key, []);
                this.pillsMap.get(key).push(p);
                if (p.negated) negCategoriesSeen.push(p.type);
            });
        // if for any negative category, no positive category exists,
        // then add an All pill:
        negCategoriesSeen.forEach(category => {
            let key = this.createKey(category, false);
            if (!this.pillsMap.has(key))
                // check that we don't have any positive pills set
                this.addAllPill(category);
        });
        return this.mapToPills();
    }

    mapToPills() {
        let pills = [];
        for (let value of this.pillsMap.values()) pills = pills.concat(value);
        return sortPills(pills);
    }

    // -- propagate to global state of DR filters and editing in filters component:

    createRow() {
        this.currentPills = this.pillsToMap([]);
    }

    editRow(filters, editingIndex) {
        if (editingIndex >= 0 && editingIndex < filters.length) {
            // highlight editing row if more than 1 row AND editing first row OR e non-empty row:
            if (
                filters.length > 1 &&
                (editingIndex === 0 || filters[editingIndex].pills.length > 0)
            )
                this.highlightIndex = editingIndex;
            else this.highlightIndex = -1;
            this.currentPills = this.pillsToMap(filters[editingIndex].pills);
        } else {
            // editingIndex points outside of filters -> reset current pills
            this.highlightIndex = -1;
            this.currentPills = this.pillsToMap([]);
        }
    }

    remoteUpdate(filters, editingIndex) {
        this.editRow(filters, editingIndex);
        // update local policy:
        this.awaitingUpdate = true;
        this.updateDRFilters(filters);
        // update preview of requesters:
        this.getPreviewRequesters(buildRequestersFromDRFilters(filters));
    }

    // --- editing functions in the accordion:

    createKey(category, negated) {
        return category + ',' + negated;
    }

    addAllPill(category) {
        this.pillsMap.set(this.createKey(category, false), [
            {
                checkList: [], // this is for the All pill!
                type: category,
                negated: false,
            },
        ]);
    }

    addNegativePill(category, pill) {
        let key = this.createKey(category, true);
        if (!this.pillsMap.has(key)) this.pillsMap.set(key, []);
        this.pillsMap.get(key).push(pill);
        // check whether we have any positive pills:
        if (!this.pillsMap.has(this.createKey(category, false)))
            this.addAllPill(category);
        this.currentPills = this.mapToPills();
    }

    removeNegativePill(category, actual) {
        let key = this.createKey(category, true);
        if (!this.pillsMap.has(key)) {
            console.error('cannot remove non-existing negative pill');
            return;
        }
        let value = this.pillsMap
            .get(key)
            .filter(p => p.checkList[0].actual !== actual);
        if (value.length === 0) {
            this.pillsMap.delete(key);
            // if we had an All pill, remove it:
            let posKey = this.createKey(category, false);
            if (
                this.pillsMap.has(posKey) &&
                this.pillsMap.get(posKey)[0].checkList.length === 0
            )
                this.pillsMap.delete(posKey);
        } else
            this.pillsMap.set(key, value);
        this.currentPills = this.mapToPills();
    }

    removePositivePills(category) {
        let negKey = this.createKey(category, true);
        if (this.pillsMap.has(negKey))
            this.addAllPill(category);
        // no negative pills: don't display All pill
        else
            this.pillsMap.delete(this.createKey(category, false));
        this.currentPills = this.mapToPills();
    }

    updatePositivePill(category, checkedList, attribute) {
        let key = this.createKey(category, false);
        let negKey = this.createKey(category, true);
        if (checkedList.length === 0 && !this.pillsMap.has(negKey))
            this.pillsMap.delete(key);  // delete if no corresponding negative keys left
        else
            this.pillsMap.set(key, [
                {
                    attribute: attribute,
                    checkList: checkedList, // if empty, will result in an All pill!
                    type: category,
                    negated: false,
                },
            ]);
        this.currentPills = this.mapToPills();
    }
}

PolicySelectDataRequester.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: PolicySelectDataRequester,
};

export default component;
