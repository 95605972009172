// template
import templateUrl from './group-editor.html';

// actions
import {
    editGroup,
    newGroup,
} from '../../actions/groups';

const SIZES = {
    normal: '',
    dashLarge: 'dash-large',
    dashSmall: 'dash-small',
};

const baseClass = 'group-editor';

class GroupEditor {
    constructor($ngRedux, $scope) {
        const unsubscribe = $ngRedux.connect(
            this.mapStateToThis, {
                editGroup,
                newGroup,
            })(this);

        $scope.$on('$destroy', unsubscribe);

        this.$onInit = () => {
            let displayClass = SIZES[this.displaySize] || SIZES.normal;
            this.containerClasses = [baseClass, displayClass, 'view'];

            this.tabContentName = 'policyEditorTabContent';
            if (this.currentParams.id === 'new') {
                this.newGroup();
            } else {
                this.editGroup(this.currentParams.id);
            }
        };
    }

    mapStateToThis(state) {
        const {
            group,
        } = state.prisms;

        const {
            router,
        } = state;

        let currentState = router.currentState.name;
        let params = {
            ...router.currentParams,
        };

        return {
            group,
            currentParams: params,
            currentState,
        };
    }
}

GroupEditor.$inject = ['$ngRedux', '$scope'];

const component = {
    templateUrl,
    controller: GroupEditor,
    bindings: {
        displaySize: '@',
    },
};

export default component;