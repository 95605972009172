import templateUrl from './cdm-graph-dialog.html';

class CDMGraphDialog {
    constructor() {
    }

    cancel() {
        this.dismiss({ $value: 'cancel' });
    }

    dismiss() {
        this.close({ $value: 'ok' });
    }
}

const component = {
    templateUrl,
    controller: CDMGraphDialog,
    bindings: {
        dismiss: '&',
        close: '&',
        resolve: '<',
    },
};

export default component;
