// libs
import angular from 'angular';

// components
import groupsTable from '../../components/group-manager/group-table';

// view
import groupManager from './group-manager';

export default angular.module('brandeis.ui.prisms.views.groupManager', [])
    .config(['$stateProvider', ($stateProvider) => {
        let managerState = {
            name: 'pa.group-manager',
            url: '/group/list/{forceUpdate}',
            views: {
                prismsMain: 'groupManager',
            },
        };

        $stateProvider
            .state(managerState);
    }])
    .component('groupsTable', groupsTable)
    .component('groupManager', groupManager)
    .name;