import templateUrl from './start-node.html';

const BASE_ICON = 'glyphicon';
const DOWN_CARET_ICON = 'glyphicon-triangle-bottom';
const SIDE_CARET_ICON = 'glyphicon-triangle-right';

class StartNode {
	constructor() {
		this.selectActions = [{
				name: 'add constraint',
				callback: ($event, node) => {
					this.selectNode({ node, isShared: false });
				},
			}, {
				name: 'add path',
				callback: ($event, node) => {
					this.selectNode({ node, isShared: true });
				},
			},
		];
	}

    getIcon(name) {
        let caret = name + 'Caret';

        if (this[caret] == null) {
            this[caret] = DOWN_CARET_ICON;
        }

        return [BASE_ICON, this[caret]].join(' ');
    }

    getShowVar(name) {
        let flag = 'show' + name.charAt(0).toUpperCase() + name.slice(1);

        if (this[flag] == null) {
            this[flag] = true;
        }

        return this[flag];
    }

    toggleCollapse(name) {
        let flag = 'show' + name.charAt(0).toUpperCase() + name.slice(1);

        this[flag] = !this[flag];
        this[name + 'Caret'] = this[flag] ? DOWN_CARET_ICON : SIDE_CARET_ICON;
    }
}

const component = {
    bindings: {
        data: '<',
		selectNode: '&',
    },
    controller: StartNode,
    templateUrl,
};

export default component;
