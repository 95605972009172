import * as actionTypes from '../actions/action-types';
import {dashify, sortPolicyData} from '../functions';

export function dataTags(state = [], action) {
    switch (action.type) {
        case actionTypes.SUCCESS_GET_TAG_SUPPORTED:
            return action.dataTags;
        default:
            return state;
    }
}

export function predicateNames(state = [], action) {
    if (action.type === actionTypes.LOGIN_SUCCESS) {
        return {
            filterPredicates: action.filterPredicates,
            actionPredicates: action.actionPredicates,
        };
    } else {
        return state;
    }
}

function parseClassTree(classTree) {
    let tree = classTree.map(node => {
        let newNode = {
            ...node,
            children: parseClassTree(node.children),
        };

        if (newNode.children) {
            newNode.children = newNode.children.filter(child => !child.checkbox);
        }

        return newNode;
    });

    return tree;
}

function parseNodes(graphNodes) {
    let nodes = [];

    for (let node of graphNodes) {
        nodes.push({
            ...node,
            id: dashify(node.name.replace('#', '-')),
        });
    }

    return nodes;
}

export function jdsClassTree(state = [], action) {
    switch (action.type) {
        case actionTypes.FETCHING_JDS_DATA:
            return [];
        case actionTypes.SUCCESS_GET_JDS_DATA:
            return parseClassTree(action.classTree);
        default:
            return state;
    }
}

export function jdsConstraintsMap(state = {}, action) {
    switch (action.type) {
        case actionTypes.FETCHING_JDS_DATA:
            return {};
        case actionTypes.SUCCESS_GET_JDS_DATA:
            return action.constraintsMap;
        default:
            return state;
    }
}

export function jdsEdges(state = [], action) {
    switch (action.type) {
        case actionTypes.FETCHING_JDS_DATA:
            return [];
        case actionTypes.SUCCESS_GET_JDS_DATA:
            return action.edges;
        default:
            return state;
    }
}

export function jdsNodes(state = [], action) {
    switch (action.type) {
        case actionTypes.FETCHING_JDS_DATA:
            return [];
        case actionTypes.SUCCESS_GET_JDS_DATA:
            return parseNodes(action.nodes);
        default:
            return state;
    }
}

export function jdsSensitivityLevel(state = 5, action) {
    switch (action.type) {
        case actionTypes.SUCCESS_ADD_POLICY:
            return 5;
        case actionTypes.UPDATED_SENSITIVITY_LEVEL:
            return action.value;
        default:
            return state;
    }
}

export function allData(state = false, action) {
    switch (action.type) {
        case actionTypes.INIT_JDS_DATA:
        case actionTypes.LOCAL_UPDATE_POLICY:
            return action.allData;
    }
    return state;
}

export function jdsData(state = {}, action) {
    switch (action.type) {
        case actionTypes.UPDATED_JDS_DATA:
        case actionTypes.INIT_JDS_DATA:
            return {
                policyData: sortPolicyData(action.policyData),
                filterConstraints: action.filterConstraints,
                actionConstraints: action.actionConstraints,
            };
        case actionTypes.REMOVED_JDS_DATA:
        case actionTypes.RESETTING_POLICY:
        case actionTypes.SUCCESS_ADD_POLICY:
            return {};
        default:
            return state;
    }
}

export function tagSelection(state = null, action) {
    switch (action.type) {
        case actionTypes.INIT_JDS_DATA:
        case actionTypes.UPDATE_TAG_SELECTION:
            if (action.tagSelection)
                return {
                    tagsNotNegated: action.tagSelection.tagsNotNegated,
                    // sort tag list alphabetically by name:
                    tags: action.tagSelection.tags
                        .sort((t1, t2) => t1.displayName.localeCompare(t2.displayName)),
                    // rebuilt flag whether no tags selected (== ALL DATA)
                    isEmpty: action.tagSelection.tags
                        .every(t => !t.checked),
                };
            else
                return null;
    }
    return state;
}

export function geoSelection(state = null, action) {
    switch (action.type) {
        case actionTypes.INIT_JDS_DATA:
        case actionTypes.UPDATE_GEO_SELECTION:
            if (action.geoSelection)
                return action.geoSelection;
            else
                return null;
    }
    return state;
}
