import {constraintFormulaToList, displayValue} from '../../functions';


function buildConstraintsJson(constraintNode, constraint, node) {
    // get to the parent
    let parent = node;
    let constraintsJson = {
        objectPathList: [],
        predicate: constraint.predicate,
        values: constraint.values,
        negated: false,
    };

    // bottom
    if (constraintNode.dataProperties == null) {
        constraintsJson.objectPathList.push({
            class_name: parent.name,
            name: constraintNode.name,
            value: constraintNode.value || 'ID',
        });
    }
    // if (!node.isShared) {
    //     constraintsJson.subjectPathLists.push([{
    //         class_name: 'ALL',
    //         name: 'ALL',
    //         value: 'ALL',
    //     }]);
    // } else {
    //     constraintsJson.subjectPathLists.push([{
    //         class_name: node.name,
    //         name: 'ID',
    //         value: 'ID',
    //     }]);
    // }

    while (parent != null) {
        // reached end
        if (parent.edge == null) {
            parent = null;
        } else {
            constraintsJson.objectPathList.unshift({
                class_name: parent.edge.vizsource.name,
                name: parent.edge.name,
                value: parent.name,
            });
            parent = parent.edge.vizsource;
        }
    }

    return constraintsJson;
}

function getNode(node, nodeToFind) {
    let found = node.name === nodeToFind ? node : null;
    for (let i = 0; i < node.selectedChildren.length && found == null; i++) {
        let child = node.selectedChildren[i];
        if (child.name === nodeToFind) {
            found = child;
        } else {
            found = getNode(child, nodeToFind);
        }
    }

    return found;
}

function parseConstraintData(topNode, constraintData, nodeList, edgeList, classTree, ndx = 0) {
    let constraintList = constraintFormulaToList(constraintData, topNode.name);
    for (let constraint of constraintList) {
		// follow the path
		let objectPath = [...constraint.objectPathList];
		// we already know the topNode
		let currentObject = objectPath.shift();
		let currentNode = topNode;

		for (let object of objectPath) {
			let node = getNode(currentNode, object.class_name);

			// node doesn't exist, so create it
			if (node == null) {
				node = {
	                ...nodeList.find(n => n.name === object.class_name),
	                classTree: [traverseClassTree(classTree, object.class_name, 1)],
					constraints: [],
					displayName: cleanupName(object.class_name),
	                selected: true,
					isShared: false, // since it wasn't created in the parsePolicyData stage, it must be a hidden constraint
	                selectedChildren: [],
	            };
	            node.trackId = `${node.id}-${ndx++}`;
				extendProperties(node);

				// attach the edge
				node.edge = {
					...edgeList.find(e => e.source === currentNode.name && (e.target === node.name || e.name === currentObject.name)),
					selected: true,
					vizsource: currentNode,
					viztarget: node,
				};

				// attach to tree
				currentNode.selectedChildren.push(node);
			}

			currentNode = node;
			currentObject = object;
		}

		// last node is the one with the constraints
		// if the currentObject's name points to an edge and not a data property go to that path's node
        let dataProperty = currentNode.dataProperties.find(p => p.name === currentObject.name)
		if (dataProperty == null) {
			currentNode = currentNode.selectedChildren.find(c => c.edge.name === currentObject.name);
            if (currentNode == null) {
                console.warn('Could not find node for constraint - skipping: ', constraint, currentObject);
                continue
            }
            dataProperty = currentNode.dataProperties.find(p => p.name === currentObject.name)
		}
		// TODO: move this test to constraintData...:
        // if (constraint.subjectPathLists[0][0].class_name === 'ALL') {
        //     currentNode.isShared = false;
        // }
        let parsedConstraint = {
            displayName: `${constraint.predicate}: "${constraint.values.map(displayValue).join(', ')}"`,
            predicate: constraint.predicate,
            values: [...constraint.values],
        }
        if (dataProperty != null) {
            dataProperty.constraints.push(parsedConstraint)
        } else {
            currentNode.constraints.push(parsedConstraint)
        }
	}
}

function parsePolicyData(policyData, nodeList, edgeList, classTree, ndx = 0) {
    let topNode,
        node,
        currentNode,
        currentElem;

    for (let pathArr of policyData) {
        let elem = pathArr[0];

        if (topNode == null) {
            topNode = {
                ...nodeList.find(n => n.name === elem.class_name),
                classTree: [traverseClassTree(classTree, elem.class_name, 1)],
				constraints: [],
				displayName: cleanupName(elem.class_name),
                selected: true,
                isShared: true,
                selectedChildren: [],
                isCurrentlySelected: true,
            };
            topNode.trackId = `${topNode.id}-${ndx++}`;
			extendProperties(topNode);
        }

        if (pathArr.length === 1) { // top level node and its properties
            // TODO: if isClass(value) then add <value, ID, ID> to path...
            if (nodeList.filter(n => n.name === elem.value).length !== 0) {
                pathArr.push({
                    class_name: elem.value,
                    name: 'ID',
                    value: 'ID',
                });
            } else
                topNode.dataProperties.find(p => p.name === elem.name).selected = true;
        }
        if (pathArr.length > 1) {  // check length again since we may have pushed a missing element
            // first element is always the topNode
            currentNode = topNode;
            currentElem = elem;
			let traversed = [elem];

            for (let i = 1; i < pathArr.length; i++) {
                elem = pathArr[i];
                // each class name is a node
                // each name is an edge

				let existingNode = getNode(topNode, elem.class_name);
                if (existingNode == null) {
					node = {
	                    ...nodeList.find(n => n.name === elem.class_name),
	                    classTree: [traverseClassTree(classTree, elem.class_name, 1)],
						constraints: [],
						displayName: cleanupName(elem.class_name),
						isLoop: traversed.find(e => e.class_name === elem.class_name) != null,
                        isShared: true,
	                    selected: true,
	                    selectedChildren: [],
	                };
					extendProperties(node);
				} else {
					node = existingNode;
				}

                // last element should always be a data property or ID; if not, add the ID element
                if (i === pathArr.length-1 && elem.name !== 'ID') {
                    // if isClass(elem.value) then add <value, ID, ID> to path
                    if (nodeList.filter(n => n.name === elem.value).length !== 0) {
                        pathArr.push({
                            class_name: elem.value,
                            name: 'ID',
                            value: 'ID',
                        });
                    } else
                        node.dataProperties.find(p => p.name === elem.name).selected = true;
                }
                node.edge = {
                    ...edgeList.find(e => e.source === currentNode.name && (e.target === node.name || e.name === currentElem.name)),
                    selected: true,
                    vizsource: currentNode,
                    viztarget: node,
                };
                node.trackId = `${node.id}-${ndx++}`;
				if (node !== existingNode) {
                	currentNode.selectedChildren.push(node);
				}
                currentNode = node;
				currentElem = elem;
				traversed.push(elem);
            }
        }
    }

    topNode.edge = null;  // overwrite existing .edge property as the top-node should not have an incoming one
    return topNode;
}

export function cleanupName(name) {
	return splitCamelCase(name.split('#').pop());
}

export function convertPathToConstraints(treeNode, arr) {
    if (treeNode === null)
        return;
    for (let constraint of treeNode.constraints) {
        arr.push(buildConstraintsJson(treeNode, constraint, treeNode));
    }

    if (treeNode.dataProperties) {
        for (let prop of treeNode.dataProperties) {
            for (let constraint of prop.constraints) {
                arr.push(buildConstraintsJson(prop, constraint, treeNode))
            }
        }
    }

    for (let child of treeNode.selectedChildren) {
        convertPathToConstraints(child, arr);
    }
}

export function convertPathToPolicyData(treeNode, arr, path) {
    if (treeNode === null)
        return;
    let checkedProperties = treeNode.dataProperties.filter(n => n.selected);
    let traversalPath = [...path];

    if (treeNode.edge) {
        traversalPath.push({
            class_name: treeNode.edge.vizsource.name,
            name: treeNode.edge.name,
            value: treeNode.edge.target,
        });

        if (checkedProperties.length === 0 && treeNode.selectedChildren.length === 0) {
            let tempPath = [
				...traversalPath,
				{
	                class_name: treeNode.name,
	                name: 'ID',
                    value: 'ID',
	            },
			];

            arr.push(tempPath);
        }
    }

    // first get the properties
    for (let prop of checkedProperties) {
        let tempPath = [
            ...traversalPath,
        ];
        tempPath.push({
            class_name: prop.class_name,
            name: prop.name,
            value: prop.value,
        });

        arr.push(tempPath);
    }
    // now traverse
    for (let child of treeNode.selectedChildren) {
        convertPathToPolicyData(child, arr, traversalPath);
    }
}

export function convertFromJds(policyData, constraintData, nodeList, edgeList, classTree) {
    let topNode,
		ndx = 0;

	topNode = parsePolicyData(policyData, nodeList, edgeList, classTree, ndx);
	parseConstraintData(topNode, constraintData, nodeList, edgeList, classTree, ndx);

    return topNode;
}

export function dashify(string) {
    return string
	    .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace('#', '-')
        .toLowerCase();
}

export function existsAsAncestor(currentLeafNode, nodeToFind) {
    let found = currentLeafNode.name === nodeToFind;

    if (!found && currentLeafNode.edge != null) {
        found = existsAsAncestor(currentLeafNode.edge.vizsource, nodeToFind);
    }

    return found;
}

export function extendProperties(node) {
    for (let prop of node.dataProperties) {
        prop.displayName = cleanupName(prop.name);
        prop.constraints = [];
    }
}

export function findNode(node, nodeToFind) {
    let found = node.name === nodeToFind;
    for (let i = 0; i < node.selectedChildren.length && !found; i++) {
        let child = node.selectedChildren[i];
        if (child.name === nodeToFind) {
            found = true;
        } else {
            found = findNode(child, nodeToFind);
        }
    }

    return found;
}

export function isChild(parentNode, nodeToFind) {
    let found = false;
    for (let i = 0; i < parentNode.selectedChildren.length && !found; i++) {
        let child = parentNode.selectedChildren[i];
        if (child.name === nodeToFind) {
            found = true;
        }
    }

    return found;
}

export function splitCamelCase(string) {
    return string
        .replace(/^([A-Z]+)([A-Z])([a-z])/g, '$1 $2$3')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, (str) => str.toUpperCase());
}

export function traverseClassTree(classTree, nodeName, level) {
    for (let item of classTree) {
        if (item.title === nodeName) {
            // always return a copy, not the actual
            return { ...item };
        } else if (item.children.length > 0) {
            let retVal = traverseClassTree(item.children, nodeName, level+1);
            if (retVal != null) {
                return retVal;
            }
        }
    }

    return null;
}
