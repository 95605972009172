const WHITE_SPACE_REGEXP = /\s/;

export default function whiteSpaceValidator() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$validators.whitespace = function(modelValue, viewValue) {
                if (WHITE_SPACE_REGEXP.test(viewValue)) {
                    // it is invalid
                    return false;
                }

                // it is valid
                return true;
            };
        },
    };
};
