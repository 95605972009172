import templateUrl from './jds-selector.html';
import {buildConstraints, displayPath, isAllData} from '../../../functions';
import {ALL_DATA} from '../../../constants';

class JdsSelector {
    constructor() {

        this.selectAllBtnClass = {
            'btn': true,
            'btn-success': true,
        };
        this.useJDSBtnClass = {
            'btn': true,
            'btn-success': false,
        };
        this.localAllData = true; // TODO: change to allData and updateJDSdata etc.
        this.policyData = [];
        this.constraints = buildConstraints([], []);

        this.$onChanges = changesObj => {
            if (changesObj.jdsData && changesObj.jdsData.currentValue) {
                // console.log('JDS selector: (changes): ', changesObj);
                // update stuff... TODO: decide if to move to watch above?
                //                       Check back when editing enabled (i.e., returning from CDM explorer)
                let newValue = changesObj.jdsData.currentValue;
                // 1) calculate whether ALL DATA or not:
                this.localAllData = isAllData(newValue);
                this.btnUpdate();
                // 2) only fill local data if not ALL DATA:
                if (!this.localAllData) {
                    this.policyData = newValue.policyData;
                    this.constraints = buildConstraints(newValue.filterConstraints, newValue.actionConstraints);
                }
            }
        }
    }

    onSelectAllData() {
        this.localAllData = true;
        this.btnUpdate();
        this.onUpdatedJds({ data: {
                policyData: ALL_DATA,
                filterConstraints: [],
                actionConstraints: [],
            }});
    }

    onUseJDS() {
        this.localAllData = false;
        this.btnUpdate();
        this.onUpdatedJds({ data: {
                policyData: this.policyData,
                filterConstraints: this.constraints.filters.constraints,
                actionConstraints: this.constraints.actions.constraints,
            }});
    }

    btnUpdate() {
        this.selectAllBtnClass['btn-success'] = this.localAllData;
        this.useJDSBtnClass['btn-success'] = !this.localAllData;
    }

    renderPath(path) {
        return displayPath(path)
    }
}

JdsSelector.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: JdsSelector,
    bindings: {
        jdsData: '<',
        onUpdatedJds: '&',
    },
};

export default component;
