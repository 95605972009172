const namespace = 'sri.brandeis.policy';

// authentication
export const AUTHENTICATING = `${namespace}.AUTHENTICATING`;
export const LOGIN_SUCCESS = `${namespace}.LOGIN`;
export const LOGIN_ERROR = `${namespace}.LOGIN_ERROR`;
export const LOGOUT = `${namespace}.LOGOUT`;
export const IS_AUTHENTICATED = `${namespace}.IS_AUTHENTICATED`;

// policy editing
// -- policy
export const ADDING_POLICY = `${namespace}.ADDING_POLICY`;
export const SUCCESS_ADD_POLICY = `${namespace}.SUCCESS_ADD_POLICY`;
export const FAILED_ADD_POLICY = `${namespace}.FAILED_ADD_POLICY`;
export const UPDATING_POLICY = `${namespace}.UPDATING_POLICY`;
export const SUCCESS_UPDATE_POLICY = `${namespace}.SUCCESS_UPDATE_POLICY`;
export const FAILED_UPDATE_POLICY = `${namespace}.FAILED_UPDATE_POLICY`;
export const DELETING_POLICY = `${namespace}.DELETING_POLICY`;
export const SUCCESS_DELETE_POLICY = `${namespace}.SUCCESS_DELETE_POLICY`;
export const FAILED_DELETE_POLICY = `${namespace}.FAILED_DELETE_POLICY`;
export const EDIT_POLICY = `${namespace}.EDIT_POLICY`;
export const COPY_POLICY = `${namespace}.COPY_POLICY`;
export const NEW_POLICY = `${namespace}.NEW_POLICY`;
export const RESETTING_POLICY = `${namespace}.RESETTING_POLICY`;
export const SETUP_WIZARD = `${namespace}.SETUP_WIZARD`;
export const LOCAL_UPDATE_POLICY = `${namespace}.LOCAL_UPDATE_POLICY`;
export const LOCAL_UPDATE_POLICY_STEP = `${namespace}.LOCAL_UPDATE_POLICY_STEP`;
export const SETTING_DOC_ID = `${namespace}.SETTING_DOC_ID`;

// policies and testing
export const FAILED_GET_POLICIES = `${namespace}.FAILED_GET_POLICIES`;
export const FETCHING_POLICIES = `${namespace}.FETCHING_POLICIES`;
export const SUCCESS_GET_POLICIES = `${namespace}.SUCCESS_GET_POLICIES`;
export const RESET_POLICY_UPDATED = `${namespace}.RESET_POLICY_UPDATED`;
export const RESET_TEST_RESULTS = `${namespace}.RESET_TEST_RESULTS`;
export const SELECT_POLICY = `${namespace}.SELECT_POLICY`;
// -- get combined
export const FAILED_GET_COMBINED_POLICY_TEST = `${namespace}.FAILED_GET_COMBINED_POLICY_TEST`;
export const FETCHING_COMBINED_POLICY_TEST = `${namespace}.FETCHING_COMBINED_POLICY_TEST`;
export const SUCCESS_GET_COMBINED_POLICY_TEST = `${namespace}.SUCCESS_GET_COMBINED_POLICY_TEST`;
// -- get individual
export const FAILED_GET_INDIVIDUAL_POLICY_TEST = `${namespace}.FAILED_GET_INDIVIDUAL_POLICY_TEST`;
export const FETCHING_INDIVIDUAL_POLICY_TEST = `${namespace}.FETCHING_INDIVIDUAL_POLICY_TEST`;
export const SUCCESS_GET_INDIVIDUAL_POLICY_TEST = `${namespace}.SUCCESS_GET_INDIVIDUAL_POLICY_TEST`;
// -- get access matrix
export const FAILED_GET_ACCESS_MATRIX = `${namespace}.FAILED_GET_ACCESS_MATRIX`;
export const FETCHING_ACCESS_MATRIX = `${namespace}.FETCHING_ACCESS_MATRIX`;
export const SUCCESS_GET_ACCESS_MATRIX = `${namespace}.SUCCESS_GET_ACCESS_MATRIX`;
// -- enable
export const ENABLING_POLICY = `${namespace}.ENABLING_POLICY`;
export const FAILED_ENABLE_POLICY = `${namespace}.FAILED_ENABLE_POLICY`;
export const SUCCESS_ENABLE_POLICY = `${namespace}.SUCCESS_ENABLE_POLICY`;
// -- disable
export const DISABLING_POLICY = `${namespace}.DISABLING_POLICY`;
export const FAILED_DISABLE_POLICY = `${namespace}.FAILED_DISABLE_POLICY`;
export const SUCCESS_DISABLE_POLICY = `${namespace}.SUCCESS_DISABLE_POLICY`;
// -- precedence
export const FAILED_SET_PRECEDENCE = `${namespace}.FAILED_SET_PRECEDENCE`;
export const SETTING_PRECEDENCE = `${namespace}.SETTING_PRECEDENCE`;
export const SUCCESS_SET_PRECEDENCE = `${namespace}.SUCCESS_SET_PRECEDENCE`;
// -- conflict default
export const FAILED_SET_CONFLICT_DEFAULT = `${namespace}.FAILED_SET_CONFLICT_DEFAULT`;
export const SETTING_CONFLICT_DEFAULT = `${namespace}.SETTING_CONFLICT_DEFAULT`;
// -- setting review pane buttons
export const SET_REVIEW_BUTTONS = `${namespace}.SET_REVIEW_BUTTONS`;

// data requesters
export const FAILED_GET_REQUESTER_ATTRIBUTES = `${namespace}.FAILED_GET_REQUESTER_ATTRIBUTES`;
export const FETCHING_REQUESTER_ATTRIBUTES = `${namespace}.FETCHING_REQUESTER_ATTRIBUTES`;
export const SUCCESS_GET_REQUESTER_ATTRIBUTES = `${namespace}.SUCCESS_GET_REQUESTER_ATTRIBUTES`;
export const FAILED_GET_PREVIEW_REQUESTERS = `${namespace}.FAILED_GET_PREVIEW_REQUESTERS`;
export const FETCHING_PREVIEW_REQUESTERS = `${namespace}.FETCHING_PREVIEW_REQUESTERS`;
export const SUCCESS_GET_PREVIEW_REQUESTERS = `${namespace}.SUCCESS_GET_PREVIEW_REQUESTERS`;
export const FAILED_GET_INDIVIDUALS = `${namespace}.FAILED_GET_INDIVIDUALS`;
export const FETCHING_INDIVIDUALS = `${namespace}.FETCHING_INDIVIDUALS`;
export const SUCCESS_GET_INDIVIDUALS = `${namespace}.SUCCESS_GET_INDIVIDUALS`;

// -- filters
export const ADDED_DR_FILTER = `${namespace}.ADDED_DR_FILTER`;
export const UPDATED_DR_FILTERS = `${namespace}.UPDATED_DR_FILTERS`;
export const INIT_DR_FILTERS = `${namespace}.INIT_DR_FILTERS`;

// policy authorities
export const FAILED_GET_AUTHORITIES = `${namespace}.FAILED_GET_AUTHORITIES`;
export const FETCHING_AUTHORITIES = `${namespace}.FETCHING_AUTHORITIES`;
export const SUCCESS_GET_AUTHORITIES = `${namespace}.SUCCESS_GET_AUTHORITIES`;

// evaluation time
export const FAILED_GET_EVALUATION_TIME = `${namespace}.FAILED_GET_EVALUATION_TIME`;
export const FETCHING_EVALUATION_TIME = `${namespace}.FETCHING_EVALUATION_TIME`;
export const SUCCESS_GET_EVALUATION_TIME = `${namespace}.SUCCESS_GET_EVALUATION_TIME`;
export const SELECT_EVALUATION_TIME = `${namespace}.SELECT_EVALUATION_TIME`;
// system time
export const FAILED_GET_SYSTEM_TIME = `${namespace}.FAILED_GET_SYSTEM_TIME`;
export const FETCHING_SYSTEM_TIME = `${namespace}.FETCHING_SYSTEM_TIME`;
export const SUCCESS_GET_SYSTEM_TIME = `${namespace}.SUCCESS_GET_SYSTEM_TIME`;
// map
export const FAILED_COP = `${namespace}.FAILED_COP`;
export const FETCHING_COP = `${namespace}.FETCHING_COP`;
export const SUCCESS_GET_COP = `${namespace}.SUCCESS_GET_COP`;

// jds
export const FAILED_GET_JDS_DATA = `${namespace}.FAILED_GET_JDS_DATA`;
export const FETCHING_JDS_DATA = `${namespace}.FETCHING_JDS_DATA`;
export const SUCCESS_GET_JDS_DATA = `${namespace}.SUCCESS_GET_JDS_DATA`;
export const EXPLORING_CDM = `${namespace}.EXPLORING_CDM`;
export const UPDATED_SENSITIVITY_LEVEL = `${namespace}.UPDATED_SENSITIVITY_LEVEL`;
// PDC use cases:
export const FAILED_GET_TAG_SUPPORTED = `${namespace}.FAILED_GET_TAG_SUPPORTED`;
export const FETCHING_TAG_SUPPORTED = `${namespace}.FETCHING_TAG_SUPPORTED`;
export const SUCCESS_GET_TAG_SUPPORTED = `${namespace}.SUCCESS_GET_TAG_SUPPORTED`;
export const UPDATE_TAG_SELECTION = `${namespace}.UPDATE_TAG_SELECTION`;
export const UPDATE_GEO_SELECTION = `${namespace}.UPDATE_GEO_SELECTION`;
// JDS data
export const UPDATED_JDS_DATA = `${namespace}.UPDATED_JDS_DATA`;
export const INIT_JDS_DATA = `${namespace}.INIT_JDS_DATA`;
export const REMOVED_JDS_DATA = `${namespace}.REMOVED_JDS_DATA`;

// groups
export const FAILED_GET_GROUPS = `${namespace}.FAILED_GET_GROUPS`;
export const FETCHING_GROUPS = `${namespace}.FETCHING_GROUPS`;
export const SUCCESS_GET_GROUPS = `${namespace}.SUCCESS_GET_GROUPS`;

export const FAILED_GET_GROUPS_SUPPORTED = `${namespace}.FAILED_GET_GROUPS_SUPPORTED`;
export const FETCHING_GROUPS_SUPPORTED = `${namespace}.FETCHING_GROUPS_SUPPORTED`;
export const SUCCESS_GET_GROUPS_SUPPORTED = `${namespace}.SUCCESS_GET_GROUPS_SUPPORTED`;

export const CLEAR_CURRENT_GROUP = `${namespace}.CLEAR_CURRENT_GROUP`;
export const CREATING_GROUP = `${namespace}.CREATING_GROUP`;
export const EDIT_GROUP = `${namespace}.EDIT_GROUP`;
export const NEW_GROUP = `${namespace}.NEW_GROUP`;
export const SUCCESS_CREATE_GROUP = `${namespace}.SUCCESS_CREATE_GROUP`;
export const FAILED_CREATE_GROUP = `${namespace}.FAILED_CREATE_GROUP`;

export const UPDATING_GROUP = `${namespace}.UPDATING_GROUP`;
export const SUCCESS_UPDATE_GROUP = `${namespace}.SUCCESS_UPDATE_GROUP`;
export const FAILED_UPDATE_GROUP = `${namespace}.FAILED_UPDATE_GROUP`;

export const DELETING_GROUP = `${namespace}.DELETING_GROUP`;
export const SUCCESS_DELETE_GROUP = `${namespace}.SUCCESS_DELETE_GROUP`;
export const FAILED_DELETE_GROUP = `${namespace}.FAILED_DELETE_GROUP`;
