// libs
import angular from 'angular';
import toggleSwitch from '@brandeis/toggle-switch-ng';

// components
import dot from '../../components/precedence-picker/dot';
import policiesTable from '../../components/policy-manager/policies-table';
import precedencePicker from '../../components/precedence-picker/precedence-picker';
import timeWindow from '../../components/time-window/time-window';

// view
import policyManager from './policy-manager';

export default angular.module('brandeis.ui.prisms.views.policyManager', [
		toggleSwitch,
	])
	.config(['$stateProvider', ($stateProvider) => {
        let managerState = {
                name: 'pa.policy-manager',
                url: '/policy/list',
                views: {
                    prismsMain: 'policyManager',
                },
            };

        $stateProvider
            .state(managerState);
	}])
	.component('dot', dot)
    .component('policiesTable', policiesTable)
    .component('policyManager', policyManager)
	.component('precedencePicker', precedencePicker)
    .component('timeWindow', timeWindow)
    .name;
