import templateUrl from './basic-details.html';

class BasicDetails {
    constructor($scope) {
        this.$scope = $scope;

        this.isEditing = false;

        this.$onInit = () => {
            this.modelOptions = {
                debounce: 200,
            };

            // need to wait for next digest cycle
            setTimeout(() => {
                this.onFormChanged({ form: this.policy, isValid: this.form.$valid });
            }, 0);
        };

        this.$onChanges = changesObj => {
            if (changesObj.policy && changesObj.policy.currentValue) {
                this.isEditing = ! changesObj.policy.currentValue.isNew;
            } else {
                this.isEditing = false;
            }
        }
    }

    announceChange() {
        this.onFormChanged({ form: this.policy, isValid: this.form.$valid });
    }
}

BasicDetails.$inject = ['$scope'];

const component = {
    templateUrl,
    controller: BasicDetails,
    bindings: {
        focusSection: '<',
        form: '<',
        onFormChanged: '&',
        policy: '<',
    },
};

export default component;
