import templateUrl from './review-data-requesters.html';

// helper functions
import { constructTagString } from '../../../functions';

import { BASE_STEPS } from '../../../constants';

class ReviewDataRequesters {
    constructor() {
    }

    checkVisited() {
        return this.wizardManager.isTabVisited(BASE_STEPS[1].state.to);
    }

    displayPill(tag) {
        return constructTagString(tag, ' | ');
    }
}

const component = {
    templateUrl,
    controller: ReviewDataRequesters,
    bindings: {
        editId: '<',
        isSummary: '<',
        filters: '<',
        policy: '<',
        wizardManager: '<',
    },
};

export default component;
