/* globals angular */

export default function alreadyExists() {
    return {
        restrict: 'A',
        require: 'form',
        link: (scope, element, attrs, formController) => {
            const resetValidity = fieldController => {
                const storedFieldController = fieldController;
                return () => {
                    storedFieldController.$setValidity('alreadyExists', true);
                    formController.$setValidity('alreadyExists', true);
                    return true;
                };
            };
            scope.$watchCollection(() => formController, updatedFormController => {
                Object.getOwnPropertyNames(updatedFormController).forEach(field => {
                    // Search for form controls with ng-model controllers
                    // Which do not have attached server error resetter validator
                    if (angular.isObject(updatedFormController[field]) &&
                        updatedFormController[field].hasOwnProperty('$modelValue') &&
                        angular.isObject(updatedFormController[field].$validators) &&
                        !updatedFormController[field].$validators.hasOwnProperty('alreadyExistsResetter')) {
                        updatedFormController[field].$validators.alreadyExistsResetter = resetValidity(updatedFormController[field]);
                    }
                });
            });
        },
    };
}