import templateUrl from './dot.html';

class Dot {
    constructor() {
        this.$onInit = () => {
            let box = (this.radius * 2 + 4) + 'px';

            this.center = {
                x: this.radius + 2,
                y: this.radius + 2,
            };

            this.style = {
                width: box,
                height: box,
            };

            this.emptyStyle = {
                stroke: '#666',
                strokeWidth: 1,
                fill: 'none',
            };

            this.filledStyle = {
                stroke: '#666',
                strokeWidth: 1,
                fill: '#aaa',
            };
        }
    }
}

const component = {
    templateUrl,
    controller: Dot,
    bindings: {
        dotId: '<',
        onClicked: '&',
        onMouseLeave: '&',
        onMouseOver: '&',
        radius: '<',
        value: '<',
    },
};

export default component;
